/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { pathConstant } from '_constants';
import { getLocalSession, getCompanyInfo } from '_services';

class MyPageLnbView extends Component {
  constructor(props) {
    super(props);

    this._handleMenuClick = this._handleMenuClick.bind(this);
  }
  componentDidMount() {
    this._loadData();
  }
  _loadData() {
    this.props.getCompanyInfo();
  }
  _handleMenuClick(e, pathname) {
    e.preventDefault();

    if (pathname === pathConstant.PATH_MYPAGE_COMPANYINFOMGMT) {
      this.props.history.push(pathname);
    } else {
      const session = getLocalSession();
      // status
      // 0: 회원가입 완료 = 회사정보 입력 대기
      // 2: 회사정보 입력 완료 = 서비스 신청 대기
      // 3: 결재 완료 = 서비스 신청 완료
      if (session && session.status !== '0') {
        this.props.history.push(pathname);
      }
    }
  }
  render() {
    const { pathname } = this.props.location;
    const session = getLocalSession();
    let joined = false;
    if (session && session.status !== '0') {
      joined = true;
    }
    document.title = '마이페이지 | 아이브웍스';
    return (
      <ul className="lnb">
        <li className={pathname === pathConstant.PATH_MYPAGE_MYPAGE ? 'active' : ''}>
          <a
            href=""
            className={joined ? '' : 'disabled'}
            onClick={(e) => {
              this._handleMenuClick(e, pathConstant.PATH_MYPAGE_MYPAGE);
            }}
          >
            <span>마이페이지</span>
          </a>
        </li>
        <li className={pathname === pathConstant.PATH_MYPAGE_PAYMENTHISTORY ? 'active' : ''}>
          <a
            href=""
            className={joined ? '' : 'disabled'}
            onClick={(e) => {
              this._handleMenuClick(e, pathConstant.PATH_MYPAGE_PAYMENTHISTORY);
            }}
          >
            <span>결제 내역</span>
          </a>
        </li>
        <li className={pathname === pathConstant.PATH_MYPAGE_COMPANYINFOMGMT ? 'active' : ''}>
          <a
            href=""
            onClick={(e) => {
              this._handleMenuClick(e, pathConstant.PATH_MYPAGE_COMPANYINFOMGMT);
            }}
          >
            <span>회사정보관리</span>
          </a>
        </li>
        <li className={pathname === pathConstant.PATH_MYPAGE_SERVICELEAVE ? 'active' : ''}>
          <a
            href=""
            className={joined ? '' : 'disabled'}
            onClick={(e) => {
              this._handleMenuClick(e, pathConstant.PATH_MYPAGE_SERVICELEAVE);
            }}
          >
            <span>회원 탈퇴</span>
          </a>
        </li>
      </ul>
    );
  }
}
function mapStateToProps(state) {
  const { companyInfo } = state;
  return {
    companyInfoData: companyInfo.data,
  };
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCompanyInfo,
      dispatch,
    },
    dispatch
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(MyPageLnbView);
export { connected as MyPageLnbView };
