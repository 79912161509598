import {
  GET_COMPANYINFO_REQUEST,
  GET_COMPANYINFO_SUCCESS,
  GET_COMPANYINFO_ERROR,
  SET_COMPANYINFO_REQUEST,
  SET_COMPANYINFO_SUCCESS,
  SET_COMPANYINFO_ERROR,
  JOIN_COMPANYINFO_REQUEST,
  JOIN_COMPANYINFO_SUCCESS,
  JOIN_COMPANYINFO_ERROR,
  UPLOAD_COMPANYINFO_REGFILE_REQUEST,
  UPLOAD_COMPANYINFO_REGFILE_SUCCESS,
  UPLOAD_COMPANYINFO_REGFILE_ERROR,
} from '_actions';

const initialState = {
  pending: false,
  data: null,
  error: null,
};

// 테넌트 정보
export function companyInfo(state = initialState, action) {
  switch (action.type) {
    case GET_COMPANYINFO_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_COMPANYINFO_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case GET_COMPANYINFO_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case SET_COMPANYINFO_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case SET_COMPANYINFO_SUCCESS:
      return {
        ...state,
        pending: false,
        data: Object.assign({}, state.data, {
          ...state.data,
          adminnm: action.data.adminnm,
          phone: action.data.phone,
          tel: action.data.tel,
          zipcode: action.data.zipcode,
          address: action.data.address,
          status: action.data.status,
          customid1: action.data.customid1,
          brlicense_download_link: action.data.brlicense_download_link,
        }),
      };
    case SET_COMPANYINFO_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case JOIN_COMPANYINFO_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case JOIN_COMPANYINFO_SUCCESS:
      return {
        ...state,
        pending: false,
        data: Object.assign({}, state.data, {
          ...state.data,
          adminnm: action.data.adminnm,
          phone: action.data.phone,
          tel: action.data.tel,
          zipcode: action.data.zipcode,
          address: action.data.address,
          customid1: action.data.customid1,
          brlicense_download_link: action.data.brlicense_download_link,
        }),
      };
    case JOIN_COMPANYINFO_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case UPLOAD_COMPANYINFO_REGFILE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPLOAD_COMPANYINFO_REGFILE_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case UPLOAD_COMPANYINFO_REGFILE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}
