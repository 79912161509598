import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { commonUtils } from '_helpers';
import { pathConstant, commonConstant } from '_constants';
import { UserCountRangeSlider } from '_components';
import { openAlertDialog } from '_actions';
import { addSubscription, getCompanyInfo } from '_services';

class ServiceApplication extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userCount: 100,
      unitPrice: 1990,
      joinDate: moment().format('YYYY년-MM월-DD일'),
    };

    this._handleRangeChange = this._handleRangeChange.bind(this);
    this._handleAddSubscription = this._handleAddSubscription.bind(this);
  }
  _handleRangeChange(data) {
    this.setState({
      ...this.state,
      userCount: data.from,
    });
  }
  _handleAddSubscription() {
    const { userCount, unitPrice, joinDate } = this.state;
    const param = {
      servicename: 'AIBWorks BASIC',
      usercount: userCount,
      usagefee: userCount * unitPrice,
      joindate: moment(joinDate, 'YYYY년-MM월-DD일').format('YYYY-MM-DD'),
      trialenddate: moment(joinDate, 'YYYY년-MM월-DD일').add(commonConstant.ADDITIONAL_TRIALDAYS, 'days').format('YYYY-MM-DD'),
    };
    this.props.addSubscription(param, (error, data) => {
      if (!error) {
        this.props.dispatch(
          openAlertDialog('신청되었습니다.', false, null, null, () => {
            this.props.getCompanyInfo({}, () => {
              this.props.history.replace({
                pathname: pathConstant.PATH_MYPAGE_MYPAGE,
                query: { showGoAdmin: true },
              });
            });
          })
        );
      }
    });
  }
  render() {
    const { userCount, unitPrice, joinDate } = this.state;
    const trialEnDate = moment(joinDate, 'YYYY년-MM월-DD일').add(commonConstant.ADDITIONAL_TRIALDAYS, 'days').format('YYYY년 M월 D일');
    const paymentDate = moment(joinDate, 'YYYY년-MM월-DD일').add(90, 'days').endOf('month').format('YYYY년 M월 D일');
    return (
      <div className="content">
        <div className="inner">
          <div className="main_cont">
            <div className="login_steps">
              <div className="title">서비스 신청</div>
              <div className="service_name">
                <div className="title">
                  <p>근태관리 Basic</p>
                  <p>모든 기능을 14일 무료체험</p>
                </div>
                <div className="desc">
                  <p>월별, 1인당</p>
                  <p>1,990원</p>
                </div>
              </div>
              <div className="service_calc">
                <UserCountRangeSlider userCount={userCount} unitPrice={unitPrice} asterrisk={true} onChange={this._handleRangeChange} />
              </div>
              <div className="service_summary">
                <div className="title">요약 정보</div>
                <ul className="dash_list">
                  <li className="promotion">
                    무료체험 기간 : 14일 (<span className="period">{joinDate + ' ~ ' + trialEnDate}</span>)
                  </li>
                  <li>
                    사용인원 : <span className="people_num">{userCount}명</span>
                  </li>
                  <li>
                    결제금액 : 매월 <span className="monthly_price">{commonUtils.numberWithCommas(unitPrice * userCount)} 원</span> (부가세 별도)
                  </li>
                  <li>
                    결제방식 : 월 말일 자동결제 (결제 시작일 : <span className="start_date">{paymentDate}</span>)
                  </li>
                </ul>
              </div>
              <div className="btn_area">
                <button type="button" className="btn btn_wide btn_y_lg btn_cyan" onClick={this._handleAddSubscription}>
                  서비스 시작
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addSubscription,
      getCompanyInfo,
      dispatch,
    },
    dispatch
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(ServiceApplication);
export { connected as ServiceApplication };
