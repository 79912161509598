/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { getLocalSession, logout, goAdmin } from '_services';
import { pathConstant } from '_constants';
import { firebaseSignOut } from '_services';
import logoImg from '_asset/images/logo.svg';

export class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleBugerMenu: false,
    };

    this._handleBurgerMenu = this._handleBurgerMenu.bind(this);
    this._handleMenuClick = this._handleMenuClick.bind(this);
    this._handleMyPage = this._handleMyPage.bind(this);
    this._handleLogout = this._handleLogout.bind(this);
  }
  _handleBurgerMenu() {
    this.setState({
      ...this.state,
      toggleBugerMenu: !this.state.toggleBugerMenu,
    });
  }
  _handleMenuClick(pathname, e) {
    if (e) {
      e.preventDefault();
    }

    this._closeBurgerMenu();
    this.props.history.push(pathname);
  }
  _handleMyPage() {
    this._closeBurgerMenu();
    const session = getLocalSession();
    // status
    // 0: 회원가입 완료 = 회사정보 입력 대기
    // 2: 회사정보 입력 완료 = 서비스 신청 대기
    // 3: 결재 완료 = 서비스 신청 완료
    if (session) {
      if (session.status === '0') {
        this.props.history.push(pathConstant.PATH_AUTH_COMPANYINFOMGMT);
      } else if (session.status === '2') {
        this.props.history.push(pathConstant.PATH_AUTH_SERVICEAPPLICATION);
      } else {
        this.props.history.push(pathConstant.PATH_MYPAGE_MYPAGE);
      }
    }
  }
  _handleLogout() {
    this._closeBurgerMenu();
    firebaseSignOut()
      .then(() => {
        this._logout();
      })
      .catch((error) => {
        this._logout();
      });
  }
  _closeBurgerMenu() {
    const { toggleBugerMenu } = this.state;
    if (toggleBugerMenu) {
      this.setState({
        ...this.state,
        toggleBugerMenu: !this.state.toggleBugerMenu,
      });
    }
  }
  _logout() {
    logout();
    this.props.history.push(pathConstant.PATH_HOME);
  }
  render() {
    const { toggleBugerMenu } = this.state;
    const { pathname } = this.props.location;
    const session = getLocalSession();
    const logined = session && session.tenantid ? true : false;
    const joinStep = session ? session.status : '';
    return (
      <>
        <header className={'header ' + (toggleBugerMenu ? 'visible' : '')}>
          <div className="inner">
            <h1 className="logo">
              <a
                href=""
                onClick={(e) => {
                  this._handleMenuClick(pathConstant.PATH_HOME, e);
                }}
              >
                <img src={logoImg} alt="아이브웍스 로고" />
              </a>
            </h1>
            <button className="ico_btn navbar_toggle" onClick={this._handleBurgerMenu}>
              <span className="menu_btn"></span>
            </button>
            <div className="navbar">
              <ul className="nav" id="gnb">
                <li className={pathname === pathConstant.PATH_GUIDE_MAINFUNCTION ? 'active' : ''}>
                  <a
                    href=""
                    onClick={(e) => {
                      this._handleMenuClick(pathConstant.PATH_GUIDE_MAINFUNCTION, e);
                    }}
                  >
                    주요기능
                  </a>
                </li>
                <li className={pathname === pathConstant.PATH_GUIDE_COSTGUIDE ? 'active' : ''}>
                  <a
                    href=""
                    onClick={(e) => {
                      this._handleMenuClick(pathConstant.PATH_GUIDE_COSTGUIDE, e);
                    }}
                  >
                    비용안내
                  </a>
                </li>
                <li
                  className={
                    pathname === pathConstant.PATH_CUSTOMERSERVICE_NOTICE ||
                    pathname === pathConstant.PATH_CUSTOMERSERVICE_NOTICE_DETAIL ||
                    pathname === pathConstant.PATH_CUSTOMERSERVICE_QNA ||
                    pathname === pathConstant.PATH_CUSTOMERSERVICE_QNA_DETAIL ||
                    pathname === pathConstant.PATH_CUSTOMERSERVICE_FAQ ||
                    pathname === pathConstant.PATH_CUSTOMERSERVICE_FAQ_DETAIL
                      ? 'active'
                      : ''
                  }
                >
                  <a
                    href=""
                    onClick={(e) => {
                      this._handleMenuClick(pathConstant.PATH_CUSTOMERSERVICE_NOTICE, e);
                    }}
                  >
                    고객지원
                  </a>
                </li>
                {/* <li>
                            <a href="#">비대면 바우처</a>
                        </li> */}
              </ul>

              <div className="btn_area">
                {!logined && (
                  <button
                    className="btn btn_y_md"
                    onClick={() => {
                      this._handleMenuClick(pathConstant.PATH_AUTH_LOGIN);
                    }}
                  >
                    로그인
                  </button>
                )}
                {!logined && (
                  <button
                    className="btn btn_y_md btn_cyan"
                    onClick={() => {
                      this._handleMenuClick(pathConstant.PATH_AUTH_SIGNUP);
                    }}
                  >
                    회원가입
                  </button>
                )}
                {logined && (
                  <div className="to_service">
                    <button
                      type="button"
                      className="btn btn_cyan"
                      disabled={joinStep !== '3' ? true : false}
                      onClick={() => {
                        goAdmin();
                      }}
                    >
                      서비스 바로 가기
                    </button>
                  </div>
                )}
                {logined && (
                  <div className="btn_group">
                    <button className="btn btn_pure" onClick={this._handleMyPage}>
                      마이페이지
                    </button>
                    <button className="btn btn_y_md" onClick={this._handleLogout}>
                      로그아웃
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </header>
        {toggleBugerMenu && <div className="navbar_mask"></div>}
      </>
    );
  }
}
