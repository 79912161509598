export const pathConstant = {
  PATH_HOME: `/`,
  PATH_HOME_SUPPORTMAIL: `/home/support-mail`,
  PATH_HOME_TERMSOFUSE: `/home/terms-of-use`,
  PATH_HOME_PRIVACYPOLISH: `/home/privacy-polish`,
  PATH_AUTH_LOGIN: `/auth/login`,
  PATH_AUTH_SIGNUP: `/auth/sign-up`,
  PATH_AUTH_EMAILVERIFICATION: `/auth/email-verification`,
  PATH_AUTH_COMPANYINFOMGMT: `/auth/company-info-mgmt`,
  PATH_AUTH_SERVICEAPPLICATION: `/auth/service-application`,
  PATH_MYPAGE_MYPAGE: `/mypage/my-page`,
  PATH_MYPAGE_PAYMENTHISTORY: `/mypage/payment-history`,
  PATH_MYPAGE_COMPANYINFOMGMT: `/mypage/company-info-mgmt`,
  PATH_MYPAGE_SERVICELEAVE: `/mypage/service-leave`,
  PATH_CUSTOMERSERVICE_NOTICE: `/customerservice/notice`,
  PATH_CUSTOMERSERVICE_NOTICE_DETAIL: `/customerservice/notice/detail`,
  PATH_CUSTOMERSERVICE_QNA: `/customerservice/qna`,
  PATH_CUSTOMERSERVICE_QNA_DETAIL: `/customerservice/qna/detail`,
  PATH_CUSTOMERSERVICE_FAQ: `/customerservice/faq`,
  PATH_CUSTOMERSERVICE_FAQ_DETAIL: `/customerservice/faq/detail`,
  PATH_GUIDE_MAINFUNCTION: `/guide/main-function`,
  PATH_GUIDE_COSTGUIDE: `/guide/cost-guide`,
};
