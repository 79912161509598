export const commonUtils = {
  isValidNumber,
  isValidEmail,
  numberWithCommas,
  hasWhiteSpace,
};

function isValidNumber(value) {
  return window.$.isNumeric(value);
}

function isValidEmail(value) {
  var reg = new RegExp('^[\\w\\-]+(\\.[\\w\\-_]+)*@[\\w\\-]+(\\.[\\w\\-]+)*(\\.[a-zA-Z]{2,3})$', 'gi');
  if (reg.test(value)) {
    return true;
  }
  return false;
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function hasWhiteSpace(s) {
  return /\s/g.test(s);
}
