import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { CustomerServiceLnbView, QnAQuestionModal } from '_containers';
import { pathConstant } from '_constants';
import { openAlertDialog } from '_actions';
import { getLocalSession, setBulletinRead, removeBulletin } from '_services';

class QnADetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questionOpen: false,
    };

    this._handleQuestion = this._handleQuestion.bind(this);
    this._handleQuestionClose = this._handleQuestionClose.bind(this);
    this._handleRemove = this._handleRemove.bind(this);
  }
  componentDidMount() {
    const { data } = this.props.location.state;
    const params = { bltId: data.bltid };
    this.props.setBulletinRead(params);
  }
  _handleQuestion() {
    const session = getLocalSession();
    if (!session) {
      this.props.history.replace(pathConstant.PATH_AUTH_LOGIN);
      return;
    }

    this.setState({
      ...this.state,
      questionOpen: true,
    });
  }
  _handleQuestionClose() {
    this.setState({
      ...this.state,
      questionOpen: false,
    });
  }
  _handleRemove() {
    this.props.dispatch(
      openAlertDialog('삭제하시겠습니까?', true, '삭제', '취소', () => {
        if (this._checkLogined()) {
          const { data } = this.props.location.state;
          const params = { bltId: data.bltid };
          this.props.removeBulletin(params, (error, data) => {
            if (!error) {
              this.props.dispatch(
                openAlertDialog('삭제되었습니다.', false, null, null, () => {
                  this.props.history.goBack();
                })
              );
            }
          });
        }
      })
    );
  }
  _checkLogined() {
    const session = getLocalSession();
    if (!session) {
      this.props.history.replace(pathConstant.PATH_AUTH_LOGIN);
      return;
    }
    return true;
  }
  render() {
    const { data } = this.props.location.state;
    const { questionOpen } = this.state;
    const session = getLocalSession();
    return (
      <div className="content">
        <div className="inner">
          <CustomerServiceLnbView {...this.props} />

          <div className="main_cont">
            <div className="cont_header">
              <h3 className="title">Q&A</h3>
            </div>
            <div className="cont_body">
              <section className="view_sect">
                <div className="view_header">
                  <span className={'badge ' + (data.replies.length > 0 ? 'done' : '')}>{data.replies.length > 0 ? '답변완료' : '처리중'}</span>
                  <h4 className="title">{data.title}</h4>
                </div>
                <div className="view_body">
                  <div className="question">
                    <div className="title">
                      <p>
                        <span className="date">{moment(data.createdtime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')}</span> | 작성자 :{' '}
                        <span className="name">{data.compnm}</span>
                      </p>
                    </div>
                    <div className="desc" dangerouslySetInnerHTML={{ __html: data.contents }}></div>
                  </div>
                  {data.replies.length > 0 && (
                    <div className="reply">
                      <div className="title">
                        <span className="main">상담사 답변입니다.</span>
                        <span className="date">{moment(data.replies[0].createdtime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')}</span>
                      </div>
                      <div className="desc" dangerouslySetInnerHTML={{ __html: data.replies[0].contents }}></div>
                    </div>
                  )}
                </div>
                <div className="btn_area">
                  <div className="area_item">
                    <button
                      type="button"
                      className="btn"
                      onClick={() => {
                        this.props.history.goBack();
                      }}
                    >
                      목록
                    </button>
                  </div>
                  {data.replies.length === 0 && session && session.tenantid === data.tenantid && (
                    <div className="area_item">
                      <button type="button" className="btn" onClick={this._handleQuestion}>
                        수정
                      </button>
                      <button type="button" className="btn" onClick={this._handleRemove}>
                        삭제
                      </button>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </div>
        </div>
        <QnAQuestionModal {...this.props} open={questionOpen} data={data} onClose={this._handleQuestionClose} />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setBulletinRead,
      removeBulletin,
      dispatch,
    },
    dispatch
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(QnADetail);
export { connected as QnADetail };
