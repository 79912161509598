import React, { Component } from 'react';
import { connect } from 'react-redux';

class Loading extends Component {
  constructor(props) {
    super(props);

    window.fetch.dispatch = this.props.dispatch;
  }
  render() {
    return this.props.loading ? (
      <div className="loading">
        <div className="main_ring">
          <i className="material-icons">account_circle</i>
          <i className="material-icons">home</i>
          <i className="material-icons">work</i>
          <div className="rotate_ring"></div>
        </div>
      </div>
    ) : null;
  }
}

function mapStateToProps(state) {
  return {
    loading: state.loading.isShow,
  };
}
const connected = connect(mapStateToProps)(Loading);
export { connected as Loading };
