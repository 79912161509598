import React, { Component } from 'react';

export class PrivacyPolishHtml extends Component {
  render() {
    return (
      <>
        <div className="desc">
          <div className="terms_item">
            <h3 className="item_title">제1조(총칙)</h3>
            <div className="item_desc">
              <p>
                ㈜아이브랩(이하 ‘회사’라 함)의 아이브웍스(AIBworks) 서비스(이하 ‘서비스’라 함)는 회원의 개인정보 보호를 소중하게 생각하고, 회원의 개인정보를
                보호하기 위하여 항상 최선을 다해 노력하고 있습니다.
              </p>
              <p>
                회사는 개인정보보호와 관련하여 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「개인정보보호법」 기타 법령의 의거한, 「개인정보처리방침」을
                제정하여 준수하고 있으며, 이를 (주)아이브랩 홈페이지(
                <a href="www.aiblab.co.kr">www.aiblab.co.kr</a>) 또는 아이브웍스 홈페이지(
                <a href="www.aibworks.com">www.aibworks.com</a>) 첫 화면 하단에 공개하므로, 회원이 원하는 경우 언제든지 용이하게 열람할 수 있습니다. 근태기록 및
                그로 인해 파생된 콘텐츠는 제 3 조(개인정보의 수집범위 및 수집방법)의 항목에 속하지 않는 콘텐츠로 스마트 근태관리, 스마트 화장실의 ‘유료서비스
                이용 및 결제’의 약관에 동의하거나 회사의 결정에 의해 제한된 기간 동안 혹은 제한된 회원에게 무료로 제공하기 위해 회사와 계약한 회원 데이터의 모든
                소유권이 회사에 있으며, 근태기록 및 그로 인해 파생된 콘텐츠의 조회, 열람, 수정, 삭제의 권한 또한 회사에게 부여됩니다. 또한 서비스를 이용하여
                생성된 출근 및 퇴근에 관련된 근태기록(이하 ‘근태기록’이라 함)과 서비스 이용 중 부가적으로 생성되는 데이터 및 그로 인해 파생된 콘텐츠는 관리자의
                별도 요구 혹은 영구적인 삭제를 위한 서비스 내의 자체적인 작업 수행이 없는 경우 3년간 보존됩니다. 그럼에도 불구하고 제 8 조(개인정보의 보관기간
                및 이용기간)에 의거하여 근태기록 및 그로 인해 파생된 콘텐츠 또한 30일간의 유예기간을 거쳐 완전히 삭제됩니다.이하에서, 아이브웍스 서비스를 통해
                제공된 개인정보의 수집, 이용, 파기까지의 전반적인 사항에 대하여 전달드리며, 아이브웍스 서비스의 「개인정보처리방침」의 내용은 관련 법률, 회사
                운영정책의 변경에 따라 변동될 수 있으며, 변경된 사항은 회사 홈페이지를 통해 공지됩니다.
              </p>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제2조(개인정보의 수집 및 이용에 대한 동의)</h3>
            <div className="item_desc">
              <p>
                회사는 적법하고 공정한 방법에 의하여 서비스 이용계약의 성립 및 이행에 필요한 최소한의 개인정보를 수집하며, 이용자 개인식별이 가능한 정보를
                수집하기 위해 회원가입시 개인정보수집∙이용 동의에 대한 내용을 제공하고, 회원이 ‘동의’ 버튼을 클릭 혹은 체크 후 다음 상태로 진행시 개인정보
                수집·이용에 대해 동의한 것으로 간주합니다. 회원은 개인정보 수집 및 이용에 대한 동의를 거부할 수 있으나, 해당 정보는 서비스 제공을 위해 필요한
                최소한의 개인정보이므로 회원의 동의가 동반되어야 서비스를 이용할 수 있습니다. 또한 회사는 홈페이지를 통하여 회원에게 다른 회사의 웹사이트 또는
                자료를 링크할 수 있습니다. 이 경우 링크된 타 기업의 웹사이트 기타 자료는 회사의 ‘개인정보처리방침’과 무관하므로 새로 방문한 사이트의
                ‘개인정보처리방침’을 개별적으로 확인하시기 바랍니다.
              </p>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제3조(개인정보의 수집범위 및 수집방법)</h3>
            <div className="item_desc">
              <p>
                회사는 회원가입, 상담, 서비스 신청 등 서비스 제공 및 계약이행을 위해 아래와 같이 개인정보를 수집할 수 있습니다. 스마트 근태관리 서비스 무료
                회원을 대상으로 수집하는 개인정보의 범위는 다음과 같습니다.
              </p>
              <ol className="dash_list">
                <li>
                  필수: 아이디(이메일 주소 혹은 휴대폰 번호), 비밀번호, 프로필 사진, 점유인증 정보(이메일 주소 혹은 휴대전화번호를 통한 확인), 사용자 국가정보
                </li>
              </ol>
              <p>
                회사는 회원이 유/무료 서비스를 이용하고자 하는 경우 결제 혹은 무료 서비스 이용신청 시점에 아래와 같이 결제에 필요한 정보를 수집할 수 있습니다.
              </p>
              <ol className="dash_list">
                <li>유료서비스: 결제수단 소유자 정보(이름), 신용카드정보, 휴대전화번호, 유선전화번호, CMS 등록 정보, 사업자등록증, 통장 사본</li>
                <li>무료서비스: 사업자등록증, 사업자등록번호, 업태/종목, 이름, 생년월일, 성별, 계좌번호</li>
              </ol>
              <p>회사는 회원이 이벤트, 프로모션에 참여하는 경우 아래의 정보를 수집할 수 있습니다.</p>
              <ol className="dash_list">
                <li>이름, 전자우편, 주소, 휴대전화번호</li>
              </ol>
              <p>연령확인 또는 관련 법률준수를 위해 본인인증이 필요한 경우 아래의 정보를 수집할 수 있습니다.</p>
              <ol className="dash_list">
                <li>이름, 생년월일, 성별, 내/외국인 여부, 아이핀 번호(아이핀 사용자의 경우), 휴대전화번호, 연계정보(CI), 중복확인정보(DI)</li>
                <li>다만 14 세 미만 미성년자(이하 “아동”)의 경우 해당 서비스의 회원가입, 상담, 서비스 신청이 불가합니다.</li>
              </ol>
              <p>서비스 이용과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.</p>
              <ol className="dash_list">
                <li>PC : 브라우저 정보, 기타 서비스 이용시 사용되는 프로그램 버전 정보</li>
                <li>휴대전화(스마트폰) & 스마트OS 탑재 모바일 기기(Tablet PC 등)</li>
                <li>기타 정보 : 서비스 이용(정지) 기록, 접속 로그, 쿠키(cookie), 접속 IP 정보</li>
              </ol>
              <p>회사는 다음과 같은 방식으로 개인정보를 수집될 수 있습니다.</p>
              <ol className="dash_list">
                <li>홈페이지 이용 및 서면양식, 경품 행사 응모, 배송요청</li>
                <li>제휴사로부터의 제공</li>
                <li>생성정보 수집 툴을 통한 수집</li>
              </ol>
              <p>
                기본적 인권침해 우려가 있는 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는 요구하지
                않으며, 위의 항목 이외에 다른 어떠한 목적으로도 수집, 사용하지 않음을 알려드립니다.
              </p>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제4조(개인정보의 수집목적 및 이용목적)</h3>
            <div className="item_desc">
              <p>회사는 수집한 개인정보를 다음의 목적으로 활용합니다.</p>
              <p>서비스 제공에 관한 계약 이행 유/무료 서비스 제공에 따른 요금정산</p>
              <p>
                회사는 여러분이 서비스에 처음 가입할 때 또는 앱(App)이나 웹(Web)서비스를 이용하는 과정에서 홈페이지 또는 서비스 내 개별 앱(App)이나 프로그램
                등을 통하여 회원의 휴대전화번호, 가입자의 성과 이름, 스마트폰 등 단말기 정보(OS 버전 및 기기정보), 서비스 사용시 이메일, 비밀번호가 필수로
                수집됩니다. 이러한 과정에서 휴대폰 본인인증 시에는 동일인 식별정보(CI), 서비스 동일인 식별번호(DI)를 이용해 휴대폰 전화번호를 직접 입력을 통해
                수집하며, 이메일 인증 시에는 직접 입력한 이메일 정보만을 수집하고 있습니다. 또한 사진(메타정보 포함), 등록한 사업장의 위치정보가 선택적으로
                수집됩니다. 추가적으로 유료 서비스 이용시 서비스 이용과정이나 사업처리 과정에서, 위치정보(비콘을 이용한 블루투스 신호 영역 내 해당 단말기의 유무
                여부) 혹은 쿠키 정보가 자동으로 생성되어 수집될 수 있습니다. <br />그 외에도 유료 서비스 이용시 배송을 위해 배송정보(이름, 휴대폰번호, 주소,
                사업장명, 사업장주소)를 수집하며, 여러분이 유료 서비스를 이용하는 과정에서 결제 등을 위하여 불가피하게 필요한 때에는 신용카드 정보, CMS(Cash
                Management System) 등록을 위한 사용자 정보, 통신사 정보, 세금계산서 발행 등을 위한 고유번호 및 계정정보, 사업장 인증을 위한 사업자등록증 정보
                등과 같이 결제에 필요한 정보가 수집될 수 있습니다. 서비스 이용 과정이나 사업처리 과정에서 서비스 이용기록, 접속로그, 접속 IP 정보, 제재 및
                이용정지 기록, 장애 관련 정보 등이 생성되어 수집될 수 있습니다. 이러한 개인정보는 서비스 프로그램의 실행 또는 사용 과정에서 수집됩니다.
              </p>
              <p>
                회사는 인터넷 서비스를 통하여 회원의 정보를 저장하고 수시로 찾아내는 쿠키(cookie)를 설치, 운용 할 수 있습니다. 쿠키란 웹 서버가 웹 브라우저에
                보내어 저장했다가 서버의 부가적인 요청이 있을 때 다시 서버로 보내주는 문자열 정보(텍스트 파일)로 회원의 컴퓨터 하드디스크에 저장되며 쿠키에는
                사용한 웹사이트의 정보 및 이용자의 개인정보가 담길 수 있습니다. 회원이 웹사이트에 접속을 하면 회원의 브라우저에 있는 쿠키의 내용을 읽고,
                추가정보를 찾아 접속에 따른 성명 등의 추가 입력없이 서비스를 제공할 수 있습니다.
              </p>
              <p>
                회원은 쿠키 설치에 대한 선택권을 가지고 있으며 회원은 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 또는 쿠키가 저장될 때마다
                확인을 거치거나, 혹은 모든 쿠키의 저장을 거부할 수도 있습니다. 다만, 회원님께서 쿠키 설치를 거부하셨을 경우 서비스 제공에 어려움이 발생할 수도
                있습니다.
              </p>
              <p>쿠키는 브라우저의 종료 시나 로그아웃 시 만료됩니다.</p>
              <p>
                회사가 쿠키를 통해 수집하는 정보는 회원의 고유번호(User ID)에 한하며, 그 외의 다른 정보는 수집하지 않습니다. 회사가 쿠키(cookie)를 통해 수집한
                회원의 고유번호는 다음의 목적을 위해 사용될 수 있습니다.
              </p>
              <ul>
                <li>(1) 개인의 관심 분야에 따라 차별화된 정보를 제공</li>
                <li>(2) 서비스 이용 내역을 추적하여 분석을 통한 추후 개인 맞춤 서비스를 제공 및 서비스 개편 등의 척도로 활용</li>
                <li>(3) 유료서비스 이용시 이용기간 안내 등</li>
              </ul>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제5조(개인정보의 자동 수집 장치의 설치 및 운영에 관한 사항)</h3>
            <div className="item_desc">
              <p>서비스 제공에 관한 계약 이행 유/무료 서비스 제공에 따른 요금정산</p>
              <ol className="dash_list">
                <li>
                  유/무료 서비스 제공, 유료 서비스 이용에 대한 과금, 구매 및 요금 결제, 본인인증, 기기 혹은 통신망 제공을 위한 발송, 청구서 발송, 요금 추심 등
                </li>
              </ol>
              <p>회원관리</p>
              <ol className="dash_list">
                <li>
                  회원제 서비스 이용에 따른 본인확인, 개인식별, 불량회원의 부정 이용 방지와 비인가 사용 방지, 중복가입확인, 가입의사 확인, 연령확인, 사업자
                  확인, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달
                </li>
              </ol>
              <p>마케팅 및 광고에 활용</p>
              <ol className="dash_list">
                <li>
                  신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 효율적인 파트너사와의 연계 서비스 제공, 유효성 확인, 접속
                  빈도 파악, 회원의 서비스 이용에 대한 통계, 이벤트 및 광고성 정보와 참여기회 제공
                </li>
              </ol>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제6조(수집한 개인정보의 처리 위탁)</h3>
            <div className="item_desc">
              <p>
                회사는 회원에게 원활한 서비스 제공을 위해 외부 전문업체에 개인정보를 위탁하여 운영하고 있으며, 상기 개인정보처리방침에 동의함과 동시에 수집한
                개인정보 처리 위탁에 동의한 것으로 간주합니다.
              </p>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제7조(개인정보의 공유 및 제공)</h3>
            <div className="item_desc">
              <p>
                회사는 회원의 개인정보를 본 개인정보처리방침에서 명시된 범위를 초과하여 이용하거나 제 3 자(타인 또는 타기업, 타기관)에 제공하지 않습니다. 다만,
                회원의 동의가 있거나 다음 각호의 어느 하나에 해당하는 경우에는 예외로 합니다.
              </p>
              <ol className="dash_list">
                <li>회원이 사전에 동의한 경우</li>
                <li>서비스 제공에 따른 요금 정산을 위하여 필요한 경우</li>
                <li>관계 법령에 근거하여 수사, 재판 또는 행정상의 목적으로 관계기관으로부터 요구가 있을 경우</li>
                <li>통계작성, 학술연구나 시장조사를 위하여 특정 개인을 식별할 수 없는 형태로 가공하여 제공하는 경우</li>
                <li>
                  「금융실명거래 및 비밀보장에 관한 법률」, 「신용정보의 이용 및 보호에 관한 법률」, 「전기통신기본법」, 「전기통신사업법」, 「지방세법」,
                  「한국은행법」, 「형사소송법」 등 기타 관련 법령에서 정한 절차에 따른 요청이 있는 경우
                </li>
              </ol>
              <p>
                회원에게 보다 나은 서비스를 제공하기 위한 목적으로 영업의 전부 또는 일부를 양도하거나, 합병/상속 등으로 서비스제공자의 권리, 의무를 이전
                승계하는 경우 개인정보보호 관련 회원의 권리를 보장하기 위하여 반드시 그 사실을 회원에게 고지합니다.
              </p>
              <p>
                보다 나은 서비스 제공을 위하여 회사가 회원의 개인정보를 타기업 등 제3 자에게 제공하는 것이 필요한 경우에는 회사는 사전에 제휴사가 누구인지, 제공
                또는 공유되어야 하는 개인정보항목이 무엇인지, 제공 또는 공유되는 개인정보의 이용목적, 그리고 언제까지 공유되며 어떻게 보호 관리되는 지에 대하여
                회원에게 고지하여 동의를 구하는 절차를 거칠 것이며, 동의하지 않는 정보는 제3 자에 제공하거나 제3 자와 공유하지 않습니다. 이 경우 고지 및
                동의방법은 홈페이지 내의 공지사항을 통해 최소 7 일 전부터 고지함과 동시에 전자우편 및 앱(App)/웹(Web)의 공지사항 화면 등을 이용하여 1 회 이상
                개별적으로 고지하거나 홈페이지, 서면, 전화 또는 전자우편 등의 방법을 통하여 동의를 받습니다. 단 시간상 사전 공지가 불가능한 예외적인 경우는 제공
                및 공유가 공지와 동시에 이루어질 수 있습니다. 회사는 개인정보처리방침에서 정한 본래의 수집목적 및 이용목적에 반하여 무분별하게 회원의 개인정보가
                제공되지 않도록 최대한 노력하겠습니다.
              </p>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제8조(개인정보의 보관기간 및 이용기간)</h3>
            <div className="item_desc">
              <p>
                이용자의 개인정보는 개인정보 수집목적 또는 제공받은 목적이 달성되면 파기됩니다. 회원이 회원탈퇴를 하거나 개인정보 허위기재로 인해 회원 ID 삭제
                처분을 받은 경우 수집된 개인정보는 완전히 삭제되며 어떠한 용도로도 이용할 수 없도록 처리됩니다. 다만, 도용으로 인한 원치 않는 회원탈퇴 등의
                피해가 발생할 우려가 있으므로 탈퇴 신청 후 30 일의 유예기간 동안 개인정보를 임시 보관하게 되며, 이는 이용 해지 시 이용자에게 고지됩니다. 30 일
                후에는 회사의 회원정보 데이터베이스(DB)에서 완전히 삭제됩니다.
              </p>
              <p>명의도용 등 분쟁 발생시 본인확인을 위해 제출 받은 신분증 사본은 본인확인 후 즉시 파기합니다.</p>
              <p>
                이용자의 개인정보는 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기되나, 아래 각 항목에 해당하는 경우에는 명시한 기간 동안 보관할 수
                있으며, 그 외 다른 목적으로는 사용하지 않습니다. 관계법령의 규정에 의하여 보관할 필요가 있는 경우 회사는 수집 및 이용 목적 달성 후에도
                관계법령에서 정한 일정 기간 동안 회원의 개인정보를 보관할 수 있습니다.
              </p>
              <p>가. 아이브웍스 웹사이트(Web site) 방문기록 (www.aibworks.com)</p>
              <ol className="dash_list">
                <li>보존 이유 : 통신비밀보호법</li>
                <li>보존 기간 : 3 개월</li>
              </ol>
              <p>나. 소비자의 불만 또는 분쟁처리에 관한 기록</p>
              <ol className="dash_list">
                <li>보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                <li>보존 기간 : 3 년</li>
              </ol>
              <p>다. 계약 또는 청약철회 등에 관한 기록</p>
              <ol className="dash_list">
                <li>보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                <li>보존 기간 : 5 년</li>
              </ol>
              <p>라. 대금결제 및 재화 등의 공급에 관한 기록</p>
              <ol className="dash_list">
                <li>보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                <li>보존 기간 : 5 년</li>
              </ol>
              <p>마. 표시, 광고에 관한 기록</p>
              <ol className="dash_list">
                <li>보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                <li>보존 기간 : 6 개월</li>
              </ol>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제9조(개인정보의 열람, 정정 파기절차 및 방법)</h3>
            <div className="item_desc">
              <p>
                회원가입시 개인정보수집·이용 동의에 따른 개인정보와 유/무료 서비스 이용 및 결제에 따른 개인정보는 해당 정보를 제공한 회원에 의해 열람 및 정정의
                권리가 있으며 회사는 수집된 개인정보를 열람하고, 정정할 수 있는 수단을 서비스 내에 제공하고 있습니다. 회사는 수집한 개인정보의 이용목적이 달성된
                후에는 보관기간 및 이용기간에 따라 해당 정보를 지체없이 파기합니다. 파기대상, 파기절차 및 방법은 다음과 같습니다.
              </p>
              <p>파기대상</p>
              <ul>
                <li>(1) 회원가입 정보</li>
                <li>(2) 환불 등에 필요한 대금 입금의 목적으로 수집된 회원가입정보 이외의 카드, 계좌정보 등</li>
              </ul>
              <p>파기절차</p>
              <p>
                회원이 서비스 가입 등을 위해 기재한 개인정보는 서비스 해지 등 이용목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라
                일정기간(개인정보 보관기간 및 이용기간 참조) 동안 저장 보관된 후 삭제되거나 파기합니다. 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나
                처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스로 옮기거나 안전하게
                보관합니다.
              </p>
              <p>파기방법</p>
              <p>
                신분증 사본 등 서면양식에 기재하거나, 종이에 출력된 개인정보는 분쇄기로 분쇄하여 파기하고, 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수
                없는 기술적 방법을 사용하여 삭제합니다.
              </p>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제10조(고지의 의무)</h3>
            <div className="item_desc">
              <p>
                현 「개인정보처리방침」 내용의 추가, 삭제 및 수정이 있을 시에는 개정 최소 7 일 전부터 홈페이지의 ‘공지사항’란을 통해 고지하거나 개정 내용이
                이용자에게 불리할 경우에는 30 일간 고지할 것입니다. 변경 관련 문의는 개인정보 관리 책임자 및 담당자에게 직접 문의하거나 회사 고객센터를 통해 할
                수 있습니다.
              </p>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제11조(개인정보보호를 위한 대책)</h3>
            <div className="item_desc">
              <p>
                기술적 대책회사는 회원의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 아래와 같은 기술적
                대책을 적용하고 있습니다.
              </p>
              <ul>
                <li>(1) 회원의 개인정보는 외부망에서 접근 및 침입이 불가능한 내부망을 활용하여 관리되고 있습니다.</li>
                <li>
                  (2) 회원의 개인정보는 오직 본인만이 알 수 있는 비밀번호에 의해 보호되고 있고 개인정보 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만
                  가능합니다. 또한 고객센터를 통한 개인정보 변경의 경우에는 본인임을 확인하는 방법을 거치게 되어 있습니다.
                </li>
                <li>
                  (3) 회사는 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 주기적으로 업데이트되며,
                  갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.
                </li>
                <li>
                  (4) 회사는 해킹 등 외부침입에 대비하여 회원의 개인정보가 유출되는 것을 막기 위해 현재 외부로부터 침입을 차단하는 장치를 이용하여 외부로부터의
                  공격, 해킹 등을 막고 있습니다.
                </li>
              </ul>
              <p>관리적 대책</p>
              <ul>
                <li>(1)회사는 회원의 개인정보에 대한 접근 및 관리에 필요한 절차 등을 마련하여 소속 직원으로 하여금 이를 숙지하고 준수하도록 하고 있습니다.</li>
                <li>
                  (2) 회사는 회원의 개인정보를 처리할 수 있는 자를 최소한으로 제한하고 있습니다. 회원의 개인정보를 처리할 수 있는 자는 아래와 같습니다.
                  <ul>
                    <li>가. 회원을 직/간접적으로 상대로 하여 마케팅 또하는 상담 업무를 수행하는 자</li>
                    <li>나. 개인정보보호책임자 및 개인정보보호담당자 등 보호업무를 수행하는 자</li>
                    <li>다. 기타 업무상 개인정보의 처리가 불가피한 자</li>
                  </ul>
                </li>
                <li>
                  (3) 개인정보를 처리하는 직원을 대상으로 새로운 보안기술 습득 및 개인정보보호 의무 등에 관해 정기적인 사내 교육 및 외부 위탁교육을 실시하고
                  있습니다.
                </li>
                <li>
                  (4) 개인정보를 처리하는 직원은 정보보호 서약서에 서명함으로 직원에 의한 정보유출을 사전에 방지하고 개인정보처리방침에 대한 이행사항 및 직원의
                  준수여부를 감사하기 위한 내부절차를 마련하여 지속적으로 시행하고 있습니다. 해당 정보보호 서약서는 회사가 각각의 직원에게 날인 절차를 거쳐
                  보관하고 있습니다.
                </li>
                <li>
                  (5) 개인정보 처리자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며, 입사 및 퇴사 후 개인정보 침해사고에 대한 책임을
                  명확하게 규정하고 있습니다. 또한 직원 퇴직시 회사에 근무하면서 직접 또는 간접적으로 알게 된 회사의 모든 업무관련 사항에 대해 퇴직 후 제3
                  자에게 또는 외부에 누설하거나 공개하지 않겠다는 내용에 서명하여 회원의 개인정보를 처리하였던 자가 직무상 알게 된 개인정보를 훼손/침해 또는
                  누설하지 않도록 하고 있습니다.
                </li>
                <li>
                  (6) 유료 서비스 이용계약 체결시 회원의 신용카드번호, 은행결제계좌 등 대금결제에 관한 정보를 수집하거나 또는 유료 서비스를 회원에게 제공하는
                  경우 당해 회원이 본인임을 확인하기 위하여 필요한 조치를 취하고 있습니다.
                </li>
                <li>
                  (7) 회사는 회원 개인의 실수 혹은 인터넷의 본질적인 위험성으로 인하여 야기되는 개인정보유출에 대해 책임을 지지 않습니다. 회원은 본인의
                  개인정보를 보호하기 위해서 자신의 아이디와 비밀번호를 적절하게 관리하고, 그에 대한 책임을 져야 합니다.
                </li>
              </ul>
            </div>
          </div>
          
          <div className="terms_item">
            <h3 className="item_title">제12조 (8세 이하의 아동 등의 보호의무자의 권리･의무 및 행사방법)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>
                  <p>
                    회사는 아래의 경우에 해당하는 자(이하 “8세 이하의 아동” 등이라 한다)의 보호의무자가 8세 이하의 아동 등의 생명 또는 신체보호를 위하여
                    개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의 동의가 있는 것으로 봅니다.
                  </p>
                  <ol>
                    <li>① 8세 이하의 아동</li>
                    <li>② 피성년후견인</li>
                    <li>
                      ③ 「장애인복지법」제2조제2항제2호의 규정에 의한 정신적 장애를 가진 자로서 「장애인고용촉진 및 직업재활법」 제2조제2호의 규정에 의한
                      중증장애인에 해당하는 자(「장애인복지법」 제32조의 규정에 의하여 장애인등록을 한 자에 한한다)
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    제1항에 따른 8세 이하의 아동 등의 보호의무자는 8세 이하의 아동 등을 사실상 보호하는 자로서 다음 각 호의 어느 하나에 해당하는 자를 말합니다.
                  </p>
                  <ol>
                    <li>8세 이하의 아동의 법정대리인 또는 「보호시설에 있는 미성년자의 후견 직무에 관한 법률」 제3조에 따른 후견인</li>
                    <li>피성년후견인의 법정대리인</li>
                    <li>
                      제1항제3호의 자의 법정대리인 또는 「장애인복지법」 제58조제1항제1호에 따른 장애인 거주시설(국가 또는 지방자치단체가 설치ㆍ운영하는 시설로
                      한정한다)의 장, 「정신건강증진 및 정신질환자 복지서비스 지원에 관한 법률」 제22조에 따른 정신요양시설의 장 및 같은 법 제26조에 따른
                      정신재활시설(국가 또는 지방자치단체가 설치ㆍ운영하는 시설로 한정한다)의 장
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    제1항에 따른 동의를 하고자 하는 8세 이하 아동 등의 보호의무자는 각 호의 사항을 기재하여 기명날인 또는 서명한 서면동의서에 보호의무자임을
                    증명하는 서면을 첨부하여 부칙 3항의 서비스 운영팀에게 제출하여, 회사가 접수하도록 합니다.
                  </p>
                  <ol>
                    <li>8세 이하의 아동 등의 성명, 주소 및 생년월일</li>
                    <li>보호의무자의 성명, 주소 및 연락처</li>
                    <li>개인위치정보 이용 또는 제공의 목적이 8세 이하의 아동 등의 생명 또는 신체의 보호에 한정된다는 사실</li>
                    <li>동의의 연월일</li>
                  </ol>
                </li>
                <li>보호의무자는 8세 이하의 아동 등의 개인위치정보 이용 또는 제공에 동의하는 경우 제12조에 의한 이용자의 권리 전부를 행사할 수 있습니다.</li>
                <li>
                  8세 이하의 아동 등이 서비스 이용신청을 할 때 보호의무자의 연락 전화번호를 제공하여야 합니다. 연락 전화번호를 제공하지 않을 때 회사는
                  이용신청을 승인하지 않습니다. 위 ③항을 확인한 후에 회사는 이용신청을 승인합니다.
                </li>
              </ol>
            </div>
          </div>

          <div className="terms_item">
            <h3 className="item_title">제 13조 (용어의 정의, 서비스의 내용 등)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>
                  <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
                  <ol>
                    <li>① 이용자 : 서비스에 접속하여 이 약관에 동의하고 가입하여 사용자 아이디를 보유하고, 회사가 제공하는 서비스를 받는 사람</li>
                    <li>② 위치정보 : 이동성이 있는 물건 또는 개인이 특정한 시간에 존재하거나 존재하였던 장소에 관한 정보 </li>
                    <li>③ 개인위치정보 : 특정 개인의 위치정보</li>
                    <li>④ 개인위치정보주체 : 개인위치정보에 의하여 식별되는 사람(이용자)</li>
                    <li>⑤ 이용해지 : 회사 또는 이용자가 서비스 이용 이후, 그 이용계약을 종료시키는 의사 표시</li>
                  </ol>
                </li>
                <li>
                  <p>회사는 위치정보사업자로부터 위치정보를 전달받아 아래와 같은 위치기반서비스를 제공합니다.</p>
                  <ol>
                    <li>
                      ① 아이브웍스 근태관리 서비스는 서비스 가입회사의 근로자 근태 데이터를 기반으로 사용자(근로자)의 근로 기록을 체계적으로 관리 및 운영하는
                      서비스를 제공합니다.
                    </li>
                    <li>
                      ② 근로자들의 출근과 퇴근 정보 수집을 위해 스마트폰 앱의 위치정보와 사업장의 위치정보를 비교하여 근무자가 근무하는 위치에 있는지를 확인하고
                      확인된 정보를 기반으로 출·퇴근 정보를 기록·관리합니다.
                    </li>
                    <li>
                      ③ 타사의 출입관리시스템과 연동시(고객이 요청시) 관련 시스템으로부터 출근 및 퇴근 상태와 시간 정보를 제공받아 출퇴근 정보를
                      기록·관리합니다.
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    회사는 「위치정보의 보호 및 이용 등에 관한 법률」 제16조제2항에 따라 위치정보 이용·제공사실 확인자료를 자동 기록·보존하며, 해당 자료는 6개월 이상 보관합니다.
                  </p>
                </li>
              </ol>
            </div>
          </div>

          <div className="terms_item">
            <h3 className="item_title">제14조(개인정보보호책임자)</h3>
            <div className="item_desc">
              <p>
                회사는 회원의 개인정보보호를 가장 중요시하며, 회원의 개인정보가 훼손, 침해 또는 누설되지 않도록 최선을 다하고 있습니다. 서비스를 이용하시면서
                발생하는 모든 개인정보보호 관련 민원을 개인정보보호 책임자 혹은 담당자에게 신고하시면 신속하게 답변해드리도록 하겠습니다.
              </p>
              <p className="strong">[개인정보보호 책임자]</p>
              <ol className="dash_list">
                <li>성 명 : 심화진</li>
                <li>직 위 : 기획팀장</li>
                <li>전자우편 : admin@aiblab.co.kr</li>
                <li>전화번호 : 070-8892-7160</li>
              </ol>
              <p>(시행일) 본 약관은 2021 년 1 월1 일부터 시행합니다.</p>
            </div>
          </div>
        </div>
        <div className="sub_desc">
          <h4 className="desc_title">부칙</h4>
          <div className="terms_item">
            <h3 className="item_title">제1조(개인위치정보의 보유목적 및 이용기간)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>회사는 위치기반서비스를 제공하기 위해 필요한 최소한의 기간 동안 개인위치정보를 보유 및 이용합니다.</li>
                <li>회사는 위치기반서비스에서 개인위치정보를 일회성 또는 임시로 이용 후 지체없이 파기합니다.</li>
              </ol>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제2조(개인정보의 위탁)</h3>
            <div className="item_desc">
              <p>
                회사는 수집된 개인정보의 처리 및 관리 업무 등(이하 “업무”)을 스스로 수행함을 원칙으로 하나, 필요한 경우 업무의 일부 또는 전부를 외부업체에
                위탁할 수 있습니다.
              </p>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제3조(개인위치정보의 이용 또는 제공)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>회사는 개인위치정보를 이용하여 서비스를 제공하고자 하는 경우에는 미리 이용약관에 명시한 후 이용자의 동의를 얻어야 합니다.</li>
                <li>
                  회사는 개인위치정보를 이용자가 지정하는 제3자에게 제공하는 경우에는 제공받는 자 및 제공목적을 사전에 이용자에게 고지하고 동의를 받습니다.
                </li>
                <li>
                  <p>
                    제2항에 따라 개인위치정보를 이용자가 지정하는 제3자에게 제공하는 경우에는 개인위치정보를 수집한 해당 통신 단말장치 또는 전자우편주소 등으로
                    매회 이용자에게 제공받는 자, 제공일시 및 제공목적(이하 “정보제공내역”이라 합니다)을 즉시 통보합니다.
                  </p>
                  <ol>
                    <li>① 통보 방법은 전자적 형태(전자우편, SMS, 서비스 알림, 세부 내역 조회 등)로 제공할 수 있습니다.</li>
                    <li>② 통보 내용이 많은 경우에 총괄 내용을 즉시 통보하고, 전체 세부 내역을 조회하는 방식으로 제공할 수 있습니다.</li>
                  </ol>
                </li>
                <li>
                  제3항에도 불구하고 이용자는 위치정보법 시행령 제24조에 따라 정보제공내역을 30일마다 모아서 통보받는 방법을 선택할 수 있으며, 이용자가 회사의
                  절차에 따라 요청할 경우 전항에 따른 즉시 통보 방법으로 변경할 수 있습니다.
                </li>
                <li>
                  이용자는 제1항·제2항·제4항에 따른 동의를 하는 경우 이용·제공목적, 제공받는 자의 범위 및 위치기반서비스 이용약관의 내용 중 일부와 이용자의
                  개인위치정보에 대한 제3자 제공의 경우 통보방법에 대하여 동의를 유보할 수 있습니다.
                </li>
              </ol>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제4조(법정대리인의 권리 및 행사방법)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>
                  <p>
                    회사는 14세 미만의 이용자의 경우, 개인위치정보를 이용한 위치기반서비스 제공 및 개인위치정보의 제3자 제공에 대하여 해당 이용자와 그
                    법정대리인 모두의 동의를 받아야 합니다. 이 경우 법정대리인은 약관의 제12조에 의한 이용자의 권리를 모두 가집니다.
                  </p>
                  <ol>
                    <li>
                      ① 14세 미만의 이용자가 서비스 이용신청을 할 때 법정대리인의 연락 전화번호를 제공하여야 합니다. 연락 전화번호를 제공하지 않을 때 회사는
                      이용신청을 승인하지 않습니다.
                    </li>
                    <li>② 회사는 14세 미만의 이용자가 서비스 가입 과정에 제공하는 법정대리인 정보에 대해 검증의 책임을 부담하지 않고 정당하다고 간주합니다.</li>
                  </ol>
                </li>
                <li>
                  <p>회사는 다음 각 호의 어느 하나에 해당하는 방법으로 법정대리인이 동의했는지를 확인합니다.</p>
                  <ol>
                    <li>
                      ① 동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를 표시하도록 하고 위치정보사업자 등이 그 동의 표시를 확인했음을 법정대리인의
                      휴대전화 문자메시지로 알리는 방법
                    </li>
                    <li>
                      ② 동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를 표시하도록 하고 법정대리인의 신용카드ㆍ직불카드 등의 카드정보를 제공받는
                      방법
                    </li>
                    <li>
                      ③ 동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를 표시하도록 하고 법정대리인의 휴대전화 본인인증 등을 통해 본인 여부를
                      확인하는 방법
                    </li>
                    <li>
                      ④ 동의 내용이 적힌 서면을 법정대리인에게 직접 발급하거나, 우편 또는 팩스를 통하여 전달하고 법정대리인이 동의 내용에 대하여 서명날인 후
                      제출하도록 하는 방법
                    </li>
                    <li>⑤ 동의 내용이 적힌 전자우편을 발송하여 법정대리인으로부터 동의의 의사표시가 적힌 전자우편을 전송받는 방법</li>
                    <li>
                      ⑥ 전화를 통하여 동의 내용을 법정대리인에게 알리고 동의를 얻거나, 인터넷주소 등 동의 내용을 확인할 수 있는 방법을 안내하고 재차 전화 통화를
                      통하여 동의를 얻는 방법
                    </li>
                    <li>
                      ⑦ 그 밖에 제1호부터 제6호까지의 규정에 따른 방법에 준하는 방법으로 법정대리인에게 동의 내용을 알리고 동의의 의사표시를 확인하는 방법
                    </li>
                    <li>⑧ 회사는 14세 미만의 이용자가 서비스 가입 과정에 제공하는 법정대리인 정보에 대해 검증의 책임을 부담하지 않고 정당하다고 간주합니다.</li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제5조(사업자 정보 및 위치정보관리책임자 등)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>
                  <p>회사의 상호 및 주소 등은 다음과 같습니다.</p>
                  <ol>
                    <li>① 상 호 : (주)아이브랩</li>
                    <li>② 대 표 자 : 서영주, 김용우</li>
                    <li>③ 주 소 : 경기도 성남시 수정구 창업로 54, 판교기업성장센터 525호 (우)13449</li>
                    <li>④ 대표전화 : 070-8892-7160</li>
                  </ol>
                </li>
                <li>회사의 대표자(김용우)는 위치정보관리책임자를 겸하고 있습니다.</li>
                <li>
                  <p>
                    회사는 서비스 이용 과정에서 발생하는 민원사항 처리를 비롯하여 이용자 권리 보호를 위해 다음과 같이 위치정보관리책임자가 위치정보관리자를
                    지정하여 운영합니다.
                  </p>
                  <ol>
                    <li>① 위치정보관리자 : 심화진 이사</li>
                    <li>② 소속 : (주)아이브랩 융합기술연구소 기획팀</li>
                    <li>③ 연락메일 : aaron.shim@aiblab.co.kr</li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제6조(서비스 운영팀)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>
                  <p>서비스의 원활한 운영과 이용자들의 제반 민원사항 처리를 위해 서비스 운영 및 지원팀을 구성하여 이용자 서비스를 제공합니다.</p>
                  <ol>
                    <li>① 위치정보취급자 : 김윤호 (연구위원), 정명훈(수석연구위원)</li>
                    <li>② 소속 : (주)아이브랩 융합기술연구소 개발팀</li>
                    <li>③ 연락메일 : 김윤호 연구위원(solgen001@aiblab.co.kr), 정명훈 수석연구위원(mhjung@aiblab.co.kr)</li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </>
    );
  }
}
