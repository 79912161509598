export const EXPORT_ORGANIZATION_REQUEST = 'EXPORT_ORGANIZATION_REQUEST';
export const EXPORT_ORGANIZATION_SUCCESS = 'EXPORT_ORGANIZATION_SUCCESS';
export const EXPORT_ORGANIZATION_ERROR = 'EXPORT_ORGANIZATION_ERROR';
export const EXPORT_ATTENDANCES_REQUEST = 'EXPORT_ATTENDANCES_REQUEST';
export const EXPORT_ATTENDANCES_SUCCESS = 'EXPORT_ATTENDANCES_SUCCESS';
export const EXPORT_ATTENDANCES_ERROR = 'EXPORT_ATTENDANCES_ERROR';
export const REQUEST_WITHDRAWAL_REQUEST = 'REQUEST_WITHDRAWAL_REQUEST';
export const REQUEST_WITHDRAWAL_SUCCESS = 'REQUEST_WITHDRAWAL_SUCCESS';
export const REQUEST_WITHDRAWAL_ERROR = 'REQUEST_WITHDRAWAL_ERROR';

export function exportOrganizationRequest() {
  return { type: EXPORT_ORGANIZATION_REQUEST };
}
export function exportOrganizationSuccess(data) {
  return { type: EXPORT_ORGANIZATION_SUCCESS, data };
}
export function exportOrganizationError(error) {
  return { type: EXPORT_ORGANIZATION_ERROR, error };
}
export function exportAttendancesRequest() {
  return { type: EXPORT_ATTENDANCES_REQUEST };
}
export function exportAttendancesSuccess(data) {
  return { type: EXPORT_ATTENDANCES_SUCCESS, data };
}
export function exportAttendancesError(error) {
  return { type: EXPORT_ATTENDANCES_ERROR, error };
}
export function requestWithdrawalRequest() {
  return { type: REQUEST_WITHDRAWAL_REQUEST };
}
export function requestWithdrawalSuccess(data) {
  return { type: REQUEST_WITHDRAWAL_SUCCESS, data };
}
export function requestWithdrawalError(error) {
  return { type: REQUEST_WITHDRAWAL_ERROR, error };
}
