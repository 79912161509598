/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { ServiceHistoryDetailModal } from '_containers';
import { codeConstant } from '_constants';
import { Pagination } from '_components';

export class ServiceHistoryView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      rowCount: 10,
      detailOpen: false,
      detailData: null,
    };

    this._handlePageMove = this._handlePageMove.bind(this);
    this._handleDetailOpen = this._handleDetailOpen.bind(this);
    this._handleDetailClose = this._handleDetailClose.bind(this);
  }
  _handlePageMove(page) {
    this.setState({
      ...this.state,
      page,
    });
  }
  _handleDetailOpen(e, item) {
    e.preventDefault();

    this.setState({
      ...this.state,
      detailOpen: true,
      detailData: item,
    });
  }
  _handleDetailClose() {
    this.setState({
      ...this.state,
      detailOpen: false,
      detailData: null,
    });
  }
  render() {
    const { page, rowCount, detailOpen, detailData } = this.state;
    const { data, serviceName } = this.props;

    let pageDataList = [];
    if (data) {
      let maxRow = page * rowCount;
      const minRow = maxRow - (rowCount - 1);
      if (maxRow > data.length) {
        maxRow = data.length;
      }
      for (let i = minRow; i <= maxRow; i++) {
        pageDataList.push(data[i - 1]);
      }
    }

    return (
      <div className="table_area">
        <div className="table service">
          <div className="table_row heading">
            <div className="row_item date">변경일시</div>
            <div className="row_item name">서비스 명</div>
            <div className="row_item title">내용</div>
          </div>
          {data &&
            pageDataList.map((item, index) => (
              <div className="table_row" key={index}>
                <a href="" className="row_link" onClick={(e) => this._handleDetailOpen(e, item)}>
                  <div className="row_item date">{item.createdtime}</div>
                  <div className="row_item name">{serviceName}</div>
                  <div className="row_item title">{codeConstant.CODE_SERVICE_TYPE[item.type]}</div>
                </a>
              </div>
            ))}
        </div>

        <div className="table_noti">
          <p>※ 내용을 클릭하시면 상세 정보를 확인할 수 있습니다.</p>
          <p>※ 기타 궁금한 사항은 고객센터를 이용해 주세요. 070-8892-7160 | help@aiblab.co.kr</p>
        </div>
        <Pagination totalCount={data ? data.length : 0} rowCount={rowCount} page={page} onPageMove={this._handlePageMove} />
        <ServiceHistoryDetailModal open={detailOpen} data={detailData} serviceName={serviceName} onClose={this._handleDetailClose} />
      </div>
    );
  }
}
