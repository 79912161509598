/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import { getLocalSession, exportOrganization, exportAttendances } from '_services';
class ServiceLeaveBackupModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      organiztionDone: false,
      attendancesDone: false,
    };
    this._handleShow = this._handleShow.bind(this);
    this._handleOrganization = this._handleOrganization.bind(this);
    this._handleAttendances = this._handleAttendances.bind(this);
  }
  _handleShow() {
    this.setState({
      ...this.state,
      organiztionDone: false,
      attendancesDone: false,
    });
  }
  _handleOrganization(e) {
    e.preventDefault();

    const session = getLocalSession();
    const param = { tenantid: session ? session.tenantid : '' };
    this.props.exportOrganization(param, (error, data) => {
      if (!error) {
        this.setState({
          ...this.state,
          organiztionDone: true,
        });
      }
    });
  }
  _handleAttendances(e) {
    e.preventDefault();

    const session = getLocalSession();
    const param = { tenantid: session ? session.tenantid : '' };
    this.props.exportAttendances(param, (error, data) => {
      if (!error) {
        this.setState({
          ...this.state,
          attendancesDone: true,
        });
      }
    });
  }
  render() {
    const { organiztionDone, attendancesDone } = this.state;
    return (
      <Modal onShow={this._handleShow} show={this.props.open} backdrop="static" dialogClassName="modal-dialog-centered">
        <Modal.Header>
          <h5 className="modal-title">회원 탈퇴 - 데이터 백업</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              this.props.onClose(false);
            }}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <div className="withdrawal">
            <h4 className="text-danger">다운로드 후 반드시 파일을 열어서 확인해 주시기 바랍니다.</h4>
            <div className="flex_area">
              <div className={'flex_item org_data ' + (organiztionDone ? 'done' : '')}>
                <a href="" className="item-link" onClick={this._handleOrganization}>
                  <p className="title">조직도 백업</p>
                  <p className="desc">
                    <span>회사의 조직도 정보를 백업 할 수 있습니다.</span>
                    <span>재가입 시 조직도 데이터를 복구 할 수 있습니다.</span>
                  </p>
                  <p className="ico_btn download">Download</p>
                </a>
                <p className="text">다운로드 완료</p>
              </div>
              <div className={'flex_item work_data ' + (attendancesDone ? 'done' : '')}>
                <a href="" className="item-link" onClick={this._handleAttendances}>
                  <p className="title">근태관리 데이터 백업</p>
                  <p className="desc">
                    <span>회사의 근태관리 데이터를 백업 할 수 있습니다.</span>
                    <span>근태관리 기록 자료로 활용 가능합니다.</span>
                  </p>
                  <p className="ico_btn download">Download</p>
                </a>
                <p className="text">다운로드 완료</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn_area">
            <button
              type="button"
              className="btn"
              disabled={!organiztionDone || !attendancesDone}
              onClick={() => {
                this.props.onClose(true);
              }}
            >
              다음
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      exportOrganization,
      exportAttendances,
      dispatch,
    },
    dispatch
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(ServiceLeaveBackupModal);
export { connected as ServiceLeaveBackupModal };
