import React, { Component } from 'react';
import { GuideFooterView } from '_containers';
import functionTopText1Img from '_asset/images/function_top_text1.png';
import functionTopText2Img from '_asset/images/function_top_text2.png';
import mobile02Img from '_asset/images/mobile02.png';
import mobile03Img from '_asset/images/mobile03.png';
import mobile04Img from '_asset/images/mobile04.png';
import mobileBeacon01Img from '_asset/images/mobile_beacon01.png';
import mobileSetting01Img from '_asset/images/mobile_setting01.png';
import web02Img from '_asset/images/web02.png';
import web03Img from '_asset/images/web03.png';
import web04Img from '_asset/images/web04.png';
import web05Img from '_asset/images/web05.png';
import auto02Img from '_asset/images/auto02.png';

export class MainFunction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIndex: this.props.location.state ? this.props.location.state.tabIndex : 1,
    };

    this._handleTab = this._handleTab.bind(this);
  }

  _handleTab(tabIndex) {
    this.setState({
      tabIndex,
    });
  }

  render() {
    const { tabIndex } = this.state;
    document.title = '주요기능 | 아이브웍스';

    return (
      <div className="content function_page">
        <div className="banner">
          <div className="inner">
            <img src={functionTopText1Img} className="text1" alt="52시간 근태관리가 필요하신가요?" />
            <img src={functionTopText2Img} className="text2" alt="아이브웍스와 함께하세요!" />
          </div>
        </div>
        <div className="tab_bar">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                type="button"
                className={'nav-link ' + (tabIndex === 1 ? 'active' : '')}
                // id="mobile-tab"
                // data-bs-toggle="pill"
                // data-bs-target="#mobile"
                onClick={() => this._handleTab(1)}
              >
                모바일앱
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                type="button"
                className={'nav-link ' + (tabIndex === 2 ? 'active' : '')}
                // id="web-tab"
                // data-bs-toggle="pill"
                // data-bs-target="#web"
                onClick={() => this._handleTab(2)}
              >
                웹브라우저
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                type="button"
                className={'nav-link ' + (tabIndex === 3 ? 'active' : '')}
                // id="autoManagement-tab"
                // data-bs-toggle="pill"
                // data-bs-target="#autoManagement"
                onClick={() => this._handleTab(3)}
              >
                자동근태관리
              </button>
            </li>
          </ul>
        </div>
        <div className="tab-content" id="pills-tabContent">
          <div className={'mobile_tab tab-pane fade ' + (tabIndex === 1 ? 'show active' : '')} id="mobile" aria-labelledby="mobile-tab">
            <section className="summary_sect">
              <div className="inner">
                <div className="desc_area">
                  <p className="title">
                    <span>쉽고 간편한 </span>
                    <span>근태관리 기능을 모바일에서</span>
                  </p>
                  <div className="desc">
                    <p>
                      <span>아이브웍스 모바일앱은 쉽고 간편한 </span>
                      <span>근태관리 기능을 지원하며</span>
                    </p>
                    <p>
                      <span>브라우저 환경에서 지원하는 기능 중 핵심기능을 </span>
                      <span>쉽고 간편하게 사용할 수 있도록 지원합니다.</span>
                    </p>
                  </div>
                </div>
                <div className="visual_area">
                  <div className="item">
                    <div className="item_ico"></div>
                    <div className="item_group">
                      <p className="item_title">간편 출근/퇴근 체크</p>
                      <p className="item_desc">
                        <span>모바일 앱으로 </span>
                        <span>출근지에서 간편하게 출근과 퇴근을 </span>
                        <span>체크합니다.</span>
                      </p>
                    </div>
                  </div>
                  <div className="item new">
                    <div className="item_ico"></div>
                    <div className="item_group">
                      <p className="item_title">비콘 출퇴근 관리</p>
                      <p className="item_desc">
                        <span>사업장에 설치된 비콘 기기의</span>
                        <span>비콘 신호를 활용하여</span>
                        <span>모바일 앱으로 출퇴근을</span>
                        <span>손쉽게 관리할 수 있습니다.</span>
                      </p>
                    </div>
                  </div>
                  <div className="item">
                    <div className="item_ico"></div>
                    <div className="item_group">
                      <p className="item_title">근무현황 확인</p>
                      <p className="item_desc">
                        <span>사용자의 근무현황을 확인 할 수 있고 </span>
                        <span>부서원의 일간, 주간 근무현황을 </span>
                        <span>모니터링 할 수 있습니다.</span>
                      </p>
                    </div>
                  </div>
                  <div className="item">
                    <div className="item_ico"></div>
                    <div className="item_group">
                      <p className="item_title">승인요청 관리</p>
                      <p className="item_desc">
                        <span>휴가신청, 외근/출장 신청서 등을 </span>
                        <span>작성하여 부서장에게 승인요청을 하고, </span>
                        <span>부서장은 부서원의 승인요청건을 </span>
                        <span>확인 및 처리할 수 있습니다.</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="simple_sect">
              <div className="inner">
                <div className="desc_area">
                  <p className="title">간편 출근/퇴근 체크</p>
                  <div className="desc">
                    <p>모바일 앱으로 출근지에서 간편하게 출근과 퇴근을 체크합니다.</p>
                  </div>
                </div>
                <div className="visual_area">
                  <img src={mobile02Img} alt="목업 이미지" />
                </div>
              </div>
            </section>
            <section className="beacon_sect">
              <div className="inner">
                <div className="desc_area">
                  <p className="title">비콘 출퇴근 관리</p>
                  <div className="desc">
                    <p>비콘 단말기와 모바일 블루투스 기술을 이용하여 보다 쉽게 출퇴근 서비스를 지원합니다.</p>
                  </div>
                </div>
                <div className="visual_area">
                  <p className="text01">
                    출퇴근 시에만 <strong>잠깐 블루투스</strong>를 켜
                    <br />
                    사용하므로 배터리 수명에 영향을 미치지 않아요!
                  </p>
                  <img src={mobileBeacon01Img} alt="목업 이미지" />
                  <small className="text02">*비콘 최대 인식 거리 : 50M 이내</small>
                  <strong className="text03">
                    비콘의 블루투스 신호를 활용하여
                    <br />
                    스마트폰 앱에서 출퇴근 관리를 합니다.
                  </strong>
                </div>
              </div>
            </section>
            <section className="setting_sect">
              <div className="inner">
                <div className="desc_area">
                  <p className="title">IP/GPS 기반 근무지 관리</p>
                  <div className="desc">
                    <p>근무지를 IP와 위치 기반(GPS) 반경 설정으로 등록된 범위 내에 출퇴근을 체크할 수 있도록 설정할 수 있습니다.</p>
                  </div>
                </div>
                <div className="visual_area">
                  <img src={mobileSetting01Img} alt="목업 이미지" />
                </div>
              </div>
            </section>
            <section className="status_sect">
              <div className="inner">
                <div className="desc_area">
                  <p className="title">근무현황 확인</p>
                  <div className="desc">
                    <span>사용자의 근무현황을 확인 할 수 있고 </span>
                    <span>부서원의 일간, 주간 근무현황을 모니터링 할 수 있습니다.</span>
                  </div>
                </div>
                <div className="visual_area">
                  <img src={mobile03Img} alt="목업 이미지" />
                </div>
              </div>
            </section>
            <section className="approval_sect">
              <div className="inner">
                <div className="desc_area">
                  <p className="title">승인요청 관리</p>
                  <div className="desc">
                    <p>
                      <span>휴가신청, 외근/출장 신청서 등을 작성하여 </span>
                      <span>부서장에게 승인요청을 하고,</span>
                    </p>
                    <p>
                      <span>부서장은 부서원의 승인요청건을 </span>
                      <span>확인 및 처리할 수 있습니다.</span>
                    </p>
                  </div>
                </div>
                <div className="visual_area">
                  <img src={mobile04Img} alt="목업 이미지" />
                </div>
              </div>
            </section>
            <GuideFooterView {...this.props} />
          </div>
          <div className={'web_tab tab-pane fade ' + (tabIndex === 2 ? 'show active' : '')} id="web" aria-labelledby="web-tab">
            <section className="summary_sect">
              <div className="inner">
                <div className="desc_area">
                  <p className="title">
                    <span>근무제도 템플릿 적용과 </span>
                    <span>인사관리는 웹브라우저에서</span>
                  </p>
                  <div className="desc">
                    <p>아이브웍스는 다양한 회사의 근무 형태에 맞게</p>
                    <p>
                      <span>
                        <strong>맞춤형 근무제도</strong>와 <strong>근로일정 관리</strong>, <strong>급여 정산</strong> 등{' '}
                      </span>
                      <span>편리한 기능 지원을 통해</span>
                    </p>
                    <p>
                      <strong>스마트하게 인사 관리</strong>를 도와드립니다.
                    </p>
                  </div>
                </div>
                <div className="visual_area">
                  <div className="item">
                    <div className="item_ico"></div>
                    <div className="item_group">
                      <p className="item_title">다양한 근무제도 적용</p>
                      <p className="item_desc">
                        <span>회사의 정책과 부서 근무형태에 맞게 </span>
                        <span>다양한 근무제도의 근로 템플릿을 </span>
                        <span>생성하고 관리할 수 있습니다.</span>
                      </p>
                    </div>
                  </div>
                  <div className="item">
                    <div className="item_ico"></div>
                    <div className="item_group">
                      <p className="item_title">근로일정 관리</p>
                      <p className="item_desc">
                        <span>사용자별 대시보드를 지원하여 </span>
                        <span>근로일정을 관리하고 모니터링 할 수 </span>
                        <span>있습니다.</span>
                      </p>
                    </div>
                  </div>
                  <div className="item">
                    <div className="item_ico"></div>
                    <div className="item_group">
                      <p className="item_title">효율적 인사관리</p>
                      <p className="item_desc">
                        <span>인사담당자가에게 필요한 근로기록을 </span>
                        <span>관리하며 유용하게 활용할 수 있도록 </span>
                        <span>엑셀 다운로드 기능을 제공합니다.</span>
                      </p>
                    </div>
                  </div>
                  <div className="item new">
                    <div className="item_ico"></div>
                    <div className="item_group">
                      <p className="item_title">간편한 급여정산</p>
                      <p className="item_desc">
                        <span>구성원의 근로 실적을 기반으로 </span>
                        <span>급여 정산 결과를 미리 확인할 수 있고 </span>
                        <span>안전하고 쉽게 급여 대장을 발송할 수 있습니다.</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="work_type_sect">
              <div className="inner">
                <div className="desc_area">
                  <p className="title">근로 템플릿 설정 및 적용</p>
                  <div className="desc">
                    <p>
                      <span>주 52시간제를 대비하여 회사 정책과 부서 근무형태에 맞게 </span>
                      <span>다양한 근무제도를 적용하고</span>
                    </p>
                    <p>생성된 템플릿은 부서별, 사원별 적용이 가능합니다.</p>
                  </div>
                </div>
                <div className="visual_area">
                  <img src={web02Img} alt="근로 템플릿 설정 및 적용 스크린샷" />
                </div>
              </div>
            </section>
            <section className="monitoring_sect">
              <div className="inner">
                <div className="desc_area">
                  <p className="title">대시보드 모니터링</p>
                  <div className="desc">
                    <p>권한에 따라 개인 대시보드, 관리자 대시보드 2가지 화면을 지원하고 </p>
                    <p>부서원의 실시간 근태 현황을 모니터링 할 수 있습니다.</p>
                  </div>
                </div>
                <div className="visual_area">
                  <img src={web03Img} alt="대시보드 모니터링 스크린샷" />
                </div>
              </div>
            </section>
            <section className="schedule_sect">
              <div className="inner">
                <div className="desc_area">
                  <p className="title">근로일정 관리</p>
                  <div className="desc">
                    <p>
                      <span>근로일정 관리 화면에서 </span>
                      <span>월별 근로일정을 확인 할 수 있고 </span>
                    </p>
                    <p>
                      <span>선택적근로시간제의 사용자의 경우 </span>
                      <span>근로 스케줄 설정을 할 수 있습니다.</span>
                    </p>
                  </div>
                </div>
                <div className="visual_area">
                  <img src={web04Img} alt="근로일정 관리 스크린샷" />
                </div>
              </div>
            </section>
            <section className="payslip_sect">
              <div className="inner">
                <div className="desc_area">
                  <p className="title">급여정산 관리</p>
                  <div className="desc">
                    <p>급여명세서 관리 화면에서 구성원의 근무기록을 바탕으로</p>
                    <p>급여 대장과 정산 결과를 간편하게 전송할 수 있습니다.</p>
                  </div>
                </div>
                <div className="visual_area">
                  <img src={web05Img} alt="근로일정 관리 스크린샷" />
                </div>
              </div>
            </section>
            <GuideFooterView {...this.props} />
          </div>
          <div className={'auto_tab tab-pane fade ' + (tabIndex === 3 ? 'show active' : '')} id="autoManagement" aria-labelledby="autoManagement-tab">
            <section className="auto_record_sect">
              <div className="inner">
                <div className="desc_area">
                  <p className="title">
                    <span>비콘과 IoT 기술을 활용한 </span>
                    <span>자동근태관리</span>
                  </p>
                  <div className="desc">
                    <p>출퇴근시 비콘 사원증을 소지하기만 하면 </p>
                    <p>
                      <span>게이트웨이에 신호를 자동으로 보내 </span>
                      <span>직원들의 출퇴근 정보를 기록합니다.</span>
                    </p>
                  </div>
                </div>
                <div className="visual_area">
                  <img src={auto02Img} alt="자동근태관리 스크린샷" />
                </div>
                <div className="extra_area">
                  <p>
                    <span>자동근태관리 도입문의는 </span>
                    <span>아래 연락처로 문의해 주시기 바랍니다.</span>
                  </p>
                  <p>070-8892-7160 / sales@aiblab.co.kr</p>
                </div>
              </div>
            </section>
            <GuideFooterView {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}
