import React, { Component } from 'react';
import { TermsOfUseHtml, PrivacyPolishHtml } from '_containers';
import { pathConstant } from '_constants';

export class Terms extends Component {
  render() {
    const { pathname } = this.props.location;
    document.title = pathname === pathConstant.PATH_HOME_TERMSOFUSE ? '이용약관 | 아이브웍스' : '개인정보처리방침 | 아이브웍스';
    return (
      <div className="content">
        <div className="inner">
          <div className="main_cont">
            <div className="flex_fix terms">
              <div className="title">
                <span className="main">{pathname === pathConstant.PATH_HOME_TERMSOFUSE ? '이용약관' : '개인정보처리방침'}</span>
              </div>
              {pathname === pathConstant.PATH_HOME_TERMSOFUSE ? <TermsOfUseHtml /> : <PrivacyPolishHtml />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
