import { requestUrl, requestHeaders, handleResponse, requestBody } from '_helpers';
import { sendSupportMailRequest, sendSupportMailSuccess, sendSupportMailError } from '_actions';

// 도입문의메일 발송
export function sendSupportMail(params = {}, callback = (error, result) => {}) {
  return (dispatch) => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/service-home/support/mail`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'POST',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(sendSupportMailRequest());
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        callback(null, response);
        dispatch(sendSupportMailSuccess(response));
      })
      .catch((error) => {
        callback(error);
        dispatch(sendSupportMailError(error));
      });
  };
}
