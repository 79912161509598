import React, { Component } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route } from 'react-router-dom';
import { history } from '_helpers';
import { Alert, Loading } from '_components';
import { AppLayout } from '_containers';
import { withCookies } from 'react-cookie';
import '_asset/css/main.css';
import '_asset/css/media_query.css';

class App extends Component {
  render() {
    return (
      <ConnectedRouter history={history}>
        <Loading />
        <Alert />
        <Switch>
          <Route path="/" name="AppLayout" render={(props) => <AppLayout {...props} cookies={this.props.cookies} />} />
        </Switch>
      </ConnectedRouter>
    );
  }
}

export default withCookies(App);
