import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { codeConstant } from '_constants';

export class ServiceHistoryDetailModal extends Component {
  render() {
    const { data, serviceName } = this.props;
    return (
      <Modal show={this.props.open} onHide={this.props.onClose} dialogClassName="modal-dialog-centered">
        <Modal.Header>
          <h5 className="modal-title">상세 정보</h5>
          <button type="button" className="btn-close" onClick={this.props.onClose}></button>
        </Modal.Header>
        <Modal.Body>
          <table className="table th_row">
            <tbody>
              <tr>
                <th scope="row">서비스 명</th>
                <td>{data && serviceName}</td>
              </tr>
              <tr>
                <th scope="row">변경일시</th>
                <td>{data && moment(data.createdtime, 'YYYY-MM-DD HH:mm:ss').format('YYYY년 M월 D일 HH:mm')}</td>
              </tr>
              <tr>
                <th scope="row">내용</th>
                <td>{data && codeConstant.CODE_SERVICE_TYPE[data.type]}</td>
              </tr>
              <tr>
                <th scope="row">상세 정보</th>
                <td>{data && data.description}</td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn_area">
            <button type="button" className="btn" onClick={this.props.onClose}>
              확인
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
