import { requestUrl, requestHeaders, requestParam, handleResponse, requestBody } from '_helpers';
import {
  fetchBulletinListRequest,
  fetchBulletinListSuccess,
  fetchBulletinListError,
  addBulletinRequest,
  addBulletinSuccess,
  addBulletinError,
  modifyBulletinRequest,
  modifyBulletinSuccess,
  modifyBulletinError,
  removeBulletinRequest,
  removeBulletinSuccess,
  removeBulletinError,
  setBulletinReadRequest,
  setBulletinReadSuccess,
  setBulletinReadError,
} from '_actions';

// 게시글 목록 조회
export function fetchBulletinList(params = {}, callback = (error, result) => {}) {
  return (dispatch) => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/service-home/bulletins?${requestParam(params)}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchBulletinListRequest());
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        callback(null, response);
        dispatch(fetchBulletinListSuccess(response));
      })
      .catch((error) => {
        callback(error);
        dispatch(fetchBulletinListError(error));
      });
  };
}

// 게시글 추가
export function addBulletin(params = {}, callback = (error, result) => {}) {
  return (dispatch) => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/service-home/bulletins`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'POST',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(addBulletinRequest());
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        callback(null, response);
        dispatch(addBulletinSuccess(response));
      })
      .catch((error) => {
        callback(error);
        dispatch(addBulletinError(error));
      });
  };
}

// 게시글 수정
export function modifyBulletin(params = {}, callback = (error, result) => {}) {
  return (dispatch) => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/service-home/bulletins/${params.bltId}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'PUT',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(modifyBulletinRequest());
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        callback(null, response);
        dispatch(modifyBulletinSuccess(response));
      })
      .catch((error) => {
        callback(error);
        dispatch(modifyBulletinError(error));
      });
  };
}

// 게시글 삭제
export function removeBulletin(params = {}, callback = (error, result) => {}) {
  return (dispatch) => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/service-home/bulletins/${params.bltId}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'DELETE',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(removeBulletinRequest());
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        callback(null, response);
        dispatch(removeBulletinSuccess(response));
      })
      .catch((error) => {
        callback(error);
        dispatch(removeBulletinError(error));
      });
  };
}

// 글읽음 카우트 추가
export function setBulletinRead(params = {}, callback = (error, result) => {}) {
  return (dispatch) => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/service-home/bulletins/${params.bltId}/read`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'PUT',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(setBulletinReadRequest());
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        callback(null, response);
        dispatch(setBulletinReadSuccess(response));
      })
      .catch((error) => {
        callback(error);
        dispatch(setBulletinReadError(error));
      });
  };
}
