import { requestUrl, requestPayletterHeaders, requestParam, handlePayletterResponse, requestBody } from '_helpers';
import {
  paymentRequestRequest,
  paymentRequestSuccess,
  paymentRequestError,
  fetchReceiptInfoRequest,
  fetchReceiptInfoSuccess,
  fetchReceiptInfoError,
} from '_actions';

// 결제 요청
export function paymentRequest(params = {}, callback = (error, result) => {}) {
  return (dispatch) => {
    const url = requestUrl(`${process.env.REACT_APP_PAYLETTER_APIURL}/v1.0/payments/request`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'POST',
      headers: requestPayletterHeaders({
        'Content-Type': 'application/json',
        Authorization: `PLKEY ${process.env.REACT_APP_PAYLETTER_APIKEY_PAYMENT}`,
      }),
      body: requestBody(params),
    };
    dispatch(paymentRequestRequest());
    return fetch(url, requestOptions)
      .then((response) => handlePayletterResponse(response))
      .then((response) => {
        callback(null, response);
        dispatch(paymentRequestSuccess(response));
      })
      .catch((error) => {
        callback(error);
        dispatch(paymentRequestError(error));
      });
  };
}

// 거래내역확인서
export function fetchReceiptInfo(tid, params = {}, callback = (error, result) => {}) {
  return (dispatch) => {
    const url = requestUrl(`${process.env.REACT_APP_PAYLETTER_APIURL}/v1.0/receipt/info/${tid}/?${requestParam(params)}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestPayletterHeaders({
        'Content-Type': 'application/json',
        Authorization: `PLKEY ${process.env.REACT_APP_PAYLETTER_APIKEY_SEARCH}`,
      }),
    };
    dispatch(fetchReceiptInfoRequest());
    return fetch(url, requestOptions)
      .then((response) => handlePayletterResponse(response))
      .then((response) => {
        callback(null, response);
        dispatch(fetchReceiptInfoSuccess(response));
      })
      .catch((error) => {
        callback(error);
        dispatch(fetchReceiptInfoError(error));
      });
  };
}
