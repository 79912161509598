import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { TermsOfUseHtml, PrivacyPolishHtml } from '_containers';
export class TermsModal extends Component {
  render() {
    const { terms } = this.props;
    return (
      <Modal show={this.props.open} onHide={this.props.onClose} backdrop="static" dialogClassName="modal-dialog-centered modal-dialog-scrollable">
        <Modal.Header>
          <h5 className="modal-title">{terms === 'U' ? '이용약관' : '개인정보취급방침'}</h5>
          <button type="button" className="btn-close" onClick={this.props.onClose}></button>
        </Modal.Header>
        <Modal.Body>
          <div className="flex_fix terms">{terms === 'U' ? <TermsOfUseHtml /> : <PrivacyPolishHtml />}</div>
        </Modal.Body>
      </Modal>
    );
  }
}
