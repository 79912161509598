export const ALERT_OPEN = 'ALERT_OPEN';
export const ALERT_CLOSE = 'ALERT_CLOSE';

export function openAlertDialog(message, confirm, labelOk, labelNo, handleOk, handleNo) {
  return {
    type: ALERT_OPEN, //
    message,
    confirm,
    labelOk,
    labelNo,
    handleOk,
    handleNo,
  };
}

export function closeAlertDialog() {
  return { type: ALERT_CLOSE };
}
