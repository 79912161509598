export const PAYMENT_REQUEST_REQUEST = 'PAYMENT_REQUEST_REQUEST';
export const PAYMENT_REQUEST_SUCCESS = 'PAYMENT_REQUEST_SUCCESS';
export const PAYMENT_REQUEST_ERROR = 'PAYMENT_REQUEST_ERROR';
export const FETCH_RECEIPT_INFO_REQUEST = 'FETCH_RECEIPT_INFO_REQUEST';
export const FETCH_RECEIPT_INFO_SUCCESS = 'FETCH_RECEIPT_INFO_SUCCESS';
export const FETCH_RECEIPT_INFO_ERROR = 'FETCH_RECEIPT_INFO_ERROR';

export function paymentRequestRequest() {
  return { type: PAYMENT_REQUEST_REQUEST };
}
export function paymentRequestSuccess(data) {
  return { type: PAYMENT_REQUEST_SUCCESS, data };
}
export function paymentRequestError(error) {
  return { type: PAYMENT_REQUEST_ERROR, error };
}
export function fetchReceiptInfoRequest() {
  return { type: FETCH_RECEIPT_INFO_REQUEST };
}
export function fetchReceiptInfoSuccess(data) {
  return { type: FETCH_RECEIPT_INFO_SUCCESS, data };
}
export function fetchReceiptInfoError(error) {
  return { type: FETCH_RECEIPT_INFO_ERROR, error };
}
