export const SEND_SUPPORT_MAIL_REQUEST = 'SEND_SUPPORT_MAIL_REQUEST';
export const SEND_SUPPORT_MAIL_SUCCESS = 'SEND_SUPPORT_MAIL_SUCCESS';
export const SEND_SUPPORT_MAIL_ERROR = 'SEND_SUPPORT_MAIL_ERROR';

export function sendSupportMailRequest() {
  return { type: SEND_SUPPORT_MAIL_REQUEST };
}
export function sendSupportMailSuccess(data) {
  return { type: SEND_SUPPORT_MAIL_SUCCESS, data };
}
export function sendSupportMailError(error) {
  return { type: SEND_SUPPORT_MAIL_ERROR, error };
}
