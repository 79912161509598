import { requestUrl, requestHeaders, requestParam, handleResponse } from '_helpers';
import { loginRequest, loginSuccess, loginError } from '_actions';

// 로그인
export function login(params = {}, callback = (error, result) => {}) {
  return (dispatch) => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/common-apis/auth`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(loginRequest());
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        callback(null, response.data);
        dispatch(loginSuccess(response.data));
      })
      .catch((error) => {
        callback(error);
        dispatch(loginError(error));
      });
  };
}

export function logout() {
  localStorage.clear();
}

export function getLocalSession() {
  return JSON.parse(localStorage.getItem('session'));
}

export function setLocalSession(session) {
  localStorage.setItem('session', JSON.stringify(session));
}

export function goAdmin() {
  const session = getLocalSession();
  if (session) {
    const params = {
      token: session.idToken,
      tenantid: session.tenantid,
      tenanturl: session.tenanturl,
      email: session.email,
    };
    let url = `${process.env.REACT_APP_PORTALURL}/service-linkage/admin-portal?${requestParam(params)}`;
    window.open(url, '_blank');
  }
}
