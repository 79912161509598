import { requestUrl, requestHeaders, handleResponse, requestBody, requestFileBody } from '_helpers';
import { getLocalSession, setLocalSession } from '_services';
import {
  getCompanyInfoRequest,
  getCompanyInfoSuccess,
  getCompanyInfoError,
  setCompanyInfoRequest,
  setCompanyInfoSuccess,
  setCompanyInfoError,
  joinCompanyInfoRequest,
  joinCompanyInfoSuccess,
  joinCompanyInfoError,
  checkCompanyInfoComnmRequest,
  checkCompanyInfoComnmSuccess,
  checkCompanyInfoComnmError,
  checkCompanyInfoComidRequest,
  checkCompanyInfoComidSuccess,
  checkCompanyInfoComidError,
  uploadCompandyRegistrationFileRequest,
  uploadCompandyRegistrationFileSuccess,
  uploadCompandyRegistrationFileError,
} from '_actions';

// 회사 정보 조회
export function getCompanyInfo(params = {}, callback = (error, result) => {}) {
  return (dispatch) => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/admin-portal/tenants`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({
        'Content-Type': 'application/json',
      }),
    };
    dispatch(getCompanyInfoRequest());
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        let session = getLocalSession();
        session = Object.assign({}, session, {
          ...response.data,
        });
        setLocalSession(session);

        callback(null, response.data);
        dispatch(getCompanyInfoSuccess(response.data));
      })
      .catch((error) => {
        callback(error);
        dispatch(getCompanyInfoError(error));
      });
  };
}

// 회사 정보 수정
export function setCompanyInfo(params = {}, callback = (error, result) => {}) {
  return (dispatch) => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/admin-portal/tenants`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'PUT',
      headers: requestHeaders({
        'Content-Type': 'application/json',
      }),
      body: requestBody(params),
    };
    dispatch(setCompanyInfoRequest());
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        callback(null, response.data);
        dispatch(setCompanyInfoSuccess(params));
      })
      .catch((error) => {
        callback(error);
        dispatch(setCompanyInfoError(error));
      });
  };
}

// JOIN
export function joinCompanyInfo(params = {}, callback = (error, result) => {}) {
  return (dispatch) => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/common-apis/join`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'POST',
      headers: requestHeaders({
        'Content-Type': 'application/json',
      }),
      body: requestBody(params),
    };
    dispatch(joinCompanyInfoRequest());
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        callback(null, response.data);
        dispatch(joinCompanyInfoSuccess(params));
      })
      .catch((error) => {
        callback(error);
        dispatch(joinCompanyInfoError(error));
      });
  };
}

// 회사명 중복 체크
export function checkCompanyInfoComnm(params = {}, callback = (error, result) => {}) {
  return (dispatch) => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/common-apis/check-compnm?compnm=${params.compnm}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({
        'Content-Type': 'application/json',
      }),
    };
    dispatch(checkCompanyInfoComnmRequest());
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        callback(null, response.data);
        dispatch(checkCompanyInfoComnmSuccess(response.data));
      })
      .catch((error) => {
        callback(error);
        dispatch(checkCompanyInfoComnmError(error));
      });
  };
}

// 회사 ID 중복 체크
export function checkCompanyInfoComid(params = {}, callback = (error, result) => {}) {
  return (dispatch) => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/common-apis/check-compid?compid=${params.compid}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({
        'Content-Type': 'application/json',
      }),
    };
    dispatch(checkCompanyInfoComidRequest());
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        callback(null, response.data);
        dispatch(checkCompanyInfoComidSuccess(response.data));
      })
      .catch((error) => {
        callback(error);
        dispatch(checkCompanyInfoComidError(error));
      });
  };
}

// 회사 사업장 증록증 업로드
export function uploadCompandyRegistrationFile(params = {}, callback = (error, result) => {}) {
  return (dispatch) => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/admin-portal/tenants/brlicense`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'POST',
      headers: requestHeaders({}),
      body: requestFileBody(params),
    };
    dispatch(uploadCompandyRegistrationFileRequest());
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        callback(null, response.data);
        dispatch(uploadCompandyRegistrationFileSuccess(response.data));
      })
      .catch((error) => {
        callback(error);
        dispatch(uploadCompandyRegistrationFileError(error));
      });
  };
}
