/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { pathConstant, codeConstant } from '_constants';
import { fetchBulletinList, getLocalSession } from '_services';
import aibworksMainVideo from '_asset/images/aibworks_main_video.mp4';
import main01Img from '_asset/images/main_01.png';
import beaconImg from '_asset/images/beacon_visual.png';
import main02Img from '_asset/images/main_02.png';
import main03Img from '_asset/images/main_03.png';
import main04Img from '_asset/images/main_04.png';
import main05Img from '_asset/images/main_05.png';
import main06Img from '_asset/images/main_06.png';
import main07Img from '_asset/images/main_07.png';
import main06Img768 from '_asset/images/main_06-768.png';
import popupImg from '_asset/images/popup/popup_231214.jpg';

class Home extends Component {
  constructor(props) {
    super(props);

    /* event popup 표시 */
    const { cookies } = this.props;
    this.state = {
      hideEventPopup: cookies.get('hideEventPopupToday') === 'true' ? true : false,
      hideEventPopupToday: false,
    };

    /* event popup 제거 */
    this.state = {
      hideEventPopup: true,
      hideEventPopupToday: true,
    };

    this._handleSupportMail = this._handleSupportMail.bind(this);
    this._handleNotice = this._handleNotice.bind(this);
    this._handleNoticeDetail = this._handleNoticeDetail.bind(this);
    this._handleWindowOpen = this._handleWindowOpen.bind(this);
    this._handleDetail = this._handleDetail.bind(this);
    this._handleJoin = this._handleJoin.bind(this);
    this._handleHideEventPopup = this._handleHideEventPopup.bind(this);
    this._handleHideEventPopupToday = this._handleHideEventPopupToday.bind(this);
  }

  componentDidMount() {
    /* vendor 스크롤 애니메이션 */
    window.AOS.init({
      duration: 1500, // values from 0 to 3000, with step 50ms
    });

    /* vendor 공지사항 스와이퍼 */
    new window.Swiper('.notice_sect .swiper-container', {
      direction: 'vertical',
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      loop: true,
    });

    this._loadData();
  }

  _loadData() {
    const params = {
      blttype: codeConstant.CODE_BULLETIN_TYPE.NOTICE,
      bltcategory: '',
      include_contents: true,
      include_replies: true,
      keyword: '',
      pageno: 1,
      pagesize: 10,
    };
    this.props.fetchBulletinList(params);
  }

  _handleWindowOpen(url) {
    window.open(url);
  }

  _handleNotice(e) {
    e.preventDefault();
    this.props.history.push(pathConstant.PATH_CUSTOMERSERVICE_NOTICE);
  }

  _handleNoticeDetail(e, item) {
    e.preventDefault();
    this.props.history.push({ pathname: pathConstant.PATH_CUSTOMERSERVICE_NOTICE_DETAIL, state: { data: item } });
  }

  _handleSupportMail() {
    this.props.history.push(pathConstant.PATH_HOME_SUPPORTMAIL);
  }

  _handleDetail(e, tabIndex) {
    e.preventDefault();

    this.props.history.push({ pathname: pathConstant.PATH_GUIDE_MAINFUNCTION, state: { tabIndex } });
  }

  _handleJoin() {
    const session = getLocalSession();
    if (session) {
      if (session.status === '0') {
        this.props.history.push(pathConstant.PATH_AUTH_COMPANYINFOMGMT);
      } else if (session.status === '2') {
        this.props.history.push(pathConstant.PATH_AUTH_SERVICEAPPLICATION);
      } else {
        this.props.history.push(pathConstant.PATH_MYPAGE_MYPAGE);
      }
    } else {
      this.props.history.push(pathConstant.PATH_AUTH_SIGNUP);
    }
  }

  _handleHideEventPopup() {
    const { cookies } = this.props;
    const { hideEventPopupToday } = this.state;

    if (hideEventPopupToday) {
      const expires = moment().set('hour', 23).set('minute', 59).set('second', 59).toDate();
      cookies.set('hideEventPopupToday', 'true', { path: '/', expires: expires });
    } else {
      cookies.remove('hideEventPopupToday');
    }
    this.setState({
      ...this.state,
      hideEventPopup: !this.state.hideEventPopup,
    });
  }

  _handleHideEventPopupToday(e) {
    this.setState({
      hideEventPopupToday: !this.state.hideEventPopupToday,
    });
  }

  render() {
    const { bulletinListData } = this.props;
    const { hideEventPopup, hideEventPopupToday } = this.state;
    const dataList = bulletinListData ? bulletinListData.data : [];
    document.title = '홈 | 아이브웍스';

    return (
      <div className="content home_page">
        {hideEventPopup ? (
          ''
        ) : (
          <div id="popup" className="popup_area">
            <div className="pop_bd">
              <img src={popupImg} alt="홈 팝업 이미지" />
            </div>
            <div className="pop_ft">
              <div className="custom-control custom-checkbox">
                <p className="custom_control_text">오늘 하루 이 창 열지 않음</p>
                <input type="checkbox" id="nopopup" className="custom-control-input" checked={hideEventPopupToday} onChange={this._handleHideEventPopupToday} />
                <label htmlFor="nopopup" className="custom-control-label"></label>
              </div>
              <button type="button" onClick={this._handleHideEventPopup}>
                닫기
              </button>
            </div>
          </div>
        )}
        <section className="video_sect">
          <video muted autoPlay loop playsInline>
            <source src={aibworksMainVideo} type="video/mp4" />
          </video>
          <div className="inner" data-aos="fade-up">
            <p className="heading_img">52시간 근태관리가 필요하신가요?</p>
            <p className="heading_img">아이브웍스와 함께하세요!</p>
            <p>
              모바일앱에서 간편하게 근태체크와 휴가 신청! <br />
              <span>부서장은 구성원의 근태 상태 확인과</span> <span>승인 신청을 간편하게!</span>
            </p>
          </div>
        </section>
        <section className="ask_sect">
          <div className="inner">
            <div className="visual_area" data-aos="fade-in">
              <img src={main01Img} alt="랜딩 이미지" />
            </div>
            <div className="desc_area" data-aos="fade-in" data-aos-delay="300">
              <p className="title">
                <span className="underline">14일 무료체험</span>의 기회를!
              </p>
              <div className="desc">
                <p>
                  <span>지금 사용 인원수에 상관없이 </span>
                  <span>14일 무료체험을 하실 수 있습니다.</span>
                </p>
                <p className="small">14일 무료체험 종료 후 유료 서비스로 전환됩니다.</p>
              </div>
              <div className="btn_area">
                <button type="button" className="btn" onClick={this._handleSupportMail}>
                  도입문의
                </button>
                <button type="button" className="btn btn_cyan" onClick={this._handleJoin}>
                  무료체험 시작하기
                </button>
              </div>
            </div>
          </div>
        </section>
        <section className="commute_sect">
          <div className="inner">
            <div className="visual_area" data-aos="fade-in">
              <img src={main02Img} alt="랜딩 이미지" />
            </div>
            <div className="desc_area" data-aos="fade-up" data-aos-delay="300">
              <p className="cat">모바일앱</p>
              <p className="title">간편하게 출근/퇴근 체크</p>
              <div className="desc">
                <p>출근지에서 간편하게 출근과 퇴근 체크를 하고</p>
                <p>
                  <span>모바일 앱에서 휴가/출장/연장근무 등 </span>
                  <span>승인요청을 진행합니다.</span>
                </p>
              </div>
              <div className="btn_area">
                <button
                  type="button"
                  className="btn btn_black ios"
                  onClick={() => this._handleWindowOpen('https://apps.apple.com/kr/app/%EC%95%84%EC%9D%B4%EB%B8%8C%EC%9B%8D%EC%8A%A4/id1481026591')}
                >
                  <span>APP STORE</span>
                </button>
                <button
                  type="button"
                  className="btn btn_black aos"
                  onClick={() => this._handleWindowOpen('https://play.google.com/store/apps/details?id=com.aibworks.id&hl=ko')}
                >
                  <span>GOOGLE PLAY</span>
                </button>
              </div>
              <p className="more">
                <a href="" onClick={(e) => this._handleDetail(e, 1)}>
                  자세히 보기
                </a>
              </p>
            </div>
          </div>
        </section>
        <section className="beacon_sect">
          <div className="inner">
            <div className="visual_area" data-aos="fade-in">
              <img src={beaconImg} alt="랜딩 이미지" />
            </div>
            <div className="desc_area" data-aos="fade-up" data-aos-delay="300">
              <p className="cat">모바일앱</p>
              <p className="title new">비콘 출퇴근 기록</p>
              <div className="desc">
                <p>사업장에 설치된 비콘 신호발생기를 활용하여</p>
                <p>
                  <span>모바일 OS(iOS, Android) 기기에서</span>
                  <span>출퇴근 기록이 가능합니다.</span>
                </p>
              </div>
              <p className="more">
                <a href="" onClick={(e) => this._handleDetail(e, 1)}>
                  자세히 보기
                </a>
              </p>
            </div>
          </div>
        </section>
        <section className="member_sect">
          <div className="inner">
            <div className="visual_area" data-aos="fade-in">
              <img src={main03Img} alt="랜딩 이미지" />
            </div>
            <div className="desc_area" data-aos="fade-up" data-aos-delay="300">
              <p className="cat">모바일앱</p>
              <p className="title">구성원의 근무 현황 확인</p>
              <div className="desc">
                <p>
                  <span>부서장은 구성원들의 주간 근무 현황을 </span>
                  <span>한번에 볼 수 있고, </span>
                </p>
                <p>
                  <span>구성원들의 휴가/출장/연장근무 등 </span>
                  <span>승인요청건을 승인합니다.</span>
                </p>
              </div>
              <p className="more">
                <a href="" onClick={(e) => this._handleDetail(e, 1)}>
                  자세히 보기
                </a>
              </p>
            </div>
          </div>
        </section>
        <section className="work_type_sect">
          <div className="inner">
            <div className="visual_area" data-aos="fade-in">
              <img src={main04Img} alt="랜딩 이미지" />
            </div>
            <div className="desc_area" data-aos="fade-up" data-aos-delay="300">
              <p className="cat">웹브라우저</p>
              <p className="title">
                <span>다양한 </span>
                <span>근무제도/일정관리</span>
              </p>
              <div className="desc">
                <p>
                  <span>탄력근무제/유연근무제/시간선택제 등 </span>
                  <span>회사 정책과 부서 근무형태에 맞게 </span>
                </p>
                <p>
                  <span>다양한 근무제도를 적용하고, </span>
                  <span>PC환경에서 근로일정을 관리합니다.</span>
                </p>
              </div>
              <p className="more">
                <a href="" onClick={(e) => this._handleDetail(e, 2)}>
                  자세히 보기
                </a>
              </p>
            </div>
          </div>
        </section>
        <section className="personnel_sect">
          <div className="inner">
            <div className="visual_area" data-aos="fade-in">
              <img src={main05Img} alt="랜딩 이미지" />
            </div>
            <div className="desc_area" data-aos="fade-up" data-aos-delay="300">
              <p className="cat">웹브라우저</p>
              <p className="title">효율적인 인사관리</p>
              <div className="desc">
                <p>실시간 동기화/근로기록관리/엑셀 다운로드</p>
                <p>
                  <span>구성원들의 출퇴근 시간이 자동으로 등록되어 </span>
                  <span>근로시간을 모니터링 하고 </span>
                </p>
                <p>
                  <span>관리자는 출퇴근기록관리에서 근무통계를 확인하고 </span>
                  <span>엑셀파일로 다운로드 합니다.</span>
                </p>
              </div>
              <p className="more">
                <a href="" onClick={(e) => this._handleDetail(e, 2)}>
                  자세히 보기
                </a>
              </p>
            </div>
          </div>
        </section>
        <section className="payslip_sect">
          <div className="inner">
            <div className="visual_area" data-aos="fade-in">
              <img src={main07Img} alt="랜딩 이미지" />
            </div>
            <div className="desc_area" data-aos="fade-up" data-aos-delay="300">
              <p className="cat">웹브라우저</p>
              <p className="title">
                <span>간편한 급여명세서 </span>
                <span>발급 관리</span>
              </p>
              <div className="desc">
                <p>
                  <span>급여명세서 발급 의무화에 따른 </span>
                  <span>간편한 급여 정산 관리</span>
                </p>
                <p>
                  <span>아이브웍스의 근태 기록을 바탕으로 </span>
                  <span>중간 입/퇴사자 관리 등</span>
                </p>
                <p>
                  <span>각종 변동사항(초과/미달 근무)까지 반영해서 </span>
                  <span>스마트하게 지급 관리가 가능합니다.</span>
                </p>
              </div>
              <p className="more">
                <a href="" onClick={(e) => this._handleDetail(e, 2)}>
                  자세히 보기
                </a>
              </p>
            </div>
          </div>
        </section>
        <section className="gateway_sect">
          <div className="inner">
            <div className="visual_area" data-aos="fade-in">
              <img src={main06Img} alt="랜딩 이미지" />
              <img src={main06Img768} alt="랜딩 이미지" />
            </div>
            <div className="desc_area" data-aos="fade-up" data-aos-delay="300">
              <p className="cat">자동근태관리 (별도문의)</p>
              <p className="title">
                <span>자동 근태관리/</span>
                <span>IoT 비콘사원증</span>
              </p>
              <div className="desc">
                <p>출/퇴근시 비콘태그(사원증)를 소지하고</p>
                <p>
                  <span>게이트웨이에 신호를 자동으로 보내 </span>
                  <span>직원들의 출퇴근 정보를 기록합니다.</span>
                </p>
              </div>
              <p className="more">
                <a href="" onClick={(e) => this._handleDetail(e, 3)}>
                  자세히 보기
                </a>
              </p>
            </div>
          </div>
        </section>
        <section className="partner_sect">
          <div className="inner">
            <div className="desc_area">
              <p className="title">
                <span>아이브웍스 </span>주요 고객사 입니다.
              </p>
              <div className="desc">
                <p>품질 좋은 서비스를 위해 항상 노력하겠습니다.</p>
              </div>
            </div>
            <ul className="partner_list">
              <li>
                <p className="partner handysoft">핸디소프트</p>
              </li>
              <li>
                <p className="partner kyocera">교세라</p>
              </li>
              <li>
                <p className="partner dasan">다산네트웍스</p>
              </li>
              <li>
                <p className="partner ngle">엔글</p>
              </li>
              <li>
                <p className="partner handyics">핸디아이씨에스</p>
              </li>
              <li>
                <p className="partner hscastting">현대성우캐스팅</p>
              </li>
              <li>
                <p className="partner hycerg">하이써그</p>
              </li>
              <li>
                <p className="partner m2tech">엠투테크</p>
              </li>
            </ul>
          </div>
        </section>
        <section className="notice_sect">
          <div className="inner">
            <div className="sect_tit">공지사항</div>
            <div className="swiper-container">
              <div className="swiper-wrapper">
                {dataList.map((item, index) => (
                  <div key={index} className="swiper-slide">
                    <a href="" className="slide_link" onClick={(e) => this._handleNoticeDetail(e, item)}>
                      <span className="badge">{codeConstant.CODE_CATEGORY_NOTICE[item.bltcategory]}</span>
                      <span className="text">{item.title}</span>
                      <span className="date">{moment(item.createdtime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')}</span>
                    </a>
                  </div>
                ))}
              </div>
            </div>
            <div className="all_view">
              <a href="" onClick={this._handleNotice}>
                <span>공지사항 전체보기</span>
              </a>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { bulletin } = state;
  return {
    bulletinListData: bulletin.bulletinListData,
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchBulletinList,
      dispatch,
    },
    dispatch
  );

const connected = connect(mapStateToProps, mapDispatchToProps)(Home);

export { connected as Home };
