import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { openAlertDialog } from '_actions';
import { pathConstant } from '_constants';
import { getLocalSession, fetchSubscription, requestWithdrawal, logout } from '_services';
import { MyPageLnbView, ServiceLeaveBackupModal, ServiceLeaveCalcModal, ServiceLeaveByeModal } from '_containers';

class ServiceLeave extends Component {
  constructor(props) {
    super(props);

    this.state = {
      termsAgree: false,
      backupOpen: false,
      calcOpen: false,
      byeOpen: false,
    };

    this._toggleTermsAgree = this._toggleTermsAgree.bind(this);
    this._handleLeave = this._handleLeave.bind(this);
    this._handleBackupClose = this._handleBackupClose.bind(this);
    this._handleCalcClose = this._handleCalcClose.bind(this);
    this._handleByeClose = this._handleByeClose.bind(this);
  }
  componentDidMount() {
    const session = getLocalSession();
    if (session && session.status === '3') {
      this._loadData();
    }
  }
  _loadData() {
    this.props.fetchSubscription({});
  }
  _toggleTermsAgree() {
    this.setState({
      ...this.state,
      termsAgree: !this.state.termsAgree,
    });
  }
  _handleLeave() {
    this.props.dispatch(
      openAlertDialog(
        "<span class='highlight'>회원탈퇴</span> 하시겠습니까?<br/>탈퇴전 준비사항을 꼭 확인하시고 진행해 주세요.",
        true,
        '회원 탈퇴',
        '취소',
        () => {
          const session = getLocalSession();
          if (session && session.status === '3') {
            this.setState({
              ...this.state,
              backupOpen: true,
            });
          } else {
            this.props.requestWithdrawal({}, (error, data) => {
              if (!error) {
                logout();
                this.setState({
                  ...this.state,
                  byeOpen: true,
                });
              }
            });
          }
        }
      )
    );
  }
  _handleBackupClose(calcOpen) {
    this.setState({
      ...this.state,
      backupOpen: false,
      calcOpen,
    });
  }
  _handleCalcClose(byeOpen) {
    this.setState({
      ...this.state,
      calcOpen: false,
      byeOpen,
    });
  }
  _handleByeClose() {
    this.setState({
      ...this.state,
      byeOpen: false,
    });
    this.props.history.push(pathConstant.PATH_HOME);
  }
  render() {
    const { termsAgree, backupOpen, calcOpen, byeOpen } = this.state;
    const { subscriptionData } = this.props;
    const session = getLocalSession();
    return (
      <div className="content">
        <div className="inner">
          <MyPageLnbView {...this.props} />

          <div className="main_cont">
            <div className="cont_header">
              <h3 className="title">회원 탈퇴</h3>
            </div>
            <div className="cont_body">
              <section className="form_sect wide">
                <form className="form_area">
                  <p className="title">
                    <span className="main">회원 탈퇴 안내</span>
                    <span className="sub">※ 회원탈퇴를 신청하기 전에 안내 사항을 꼭 확인해주세요.</span>
                  </p>
                  <ul className="check_list">
                    <li>
                      <strong>
                        사용하고 계신 회사 ID (<span className="highlight">{session && session.compid}</span>)는 탈퇴할 경우 재사용 및 복구가 불가능합니다.
                      </strong>
                      <br />
                      <span className="highlight">탈퇴한 아이디는 본인과 타인 모두 재사용 및 복구가 불가</span>
                      하오니 신중하게 선택하시기 바랍니다.
                    </li>
                    <li>
                      <strong>탈퇴 후 회원정보 및 근태관리 서비스 이용기록은 모두 삭제됩니다.</strong>
                      <br />
                      회사의 <span className="highlight">조직도 정보</span>, <span className="highlight">근태관리 정보</span>를 백업(다운로드) 받으실 수
                      있습니다.
                      <br />
                      삭제되는 내용을 확인하시고 필요한 데이터는 미리 백업을 해주세요.
                    </li>
                    <li>
                      <strong>서비스 이용요금은 서비스 시작일 기준 일할계산되어 환불 또는 청구 됩니다.</strong>
                    </li>
                  </ul>
                  <div className="input-group">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" id="termsAgree" className="custom-control-input" checked={termsAgree} onChange={this._toggleTermsAgree} />
                      <label htmlFor="termsAgree" className="custom-control-label"></label>
                      <p className="custom_control_text">안내 사항을 모두 확인하였으며, 이에 동의합니다.</p>
                    </div>
                  </div>
                </form>
                <div className="btn_area">
                  <button className="btn" disabled={termsAgree ? false : true} onClick={this._handleLeave}>
                    {session && session.status === '3' ? '데이타백업 후 회원탈퇴' : '회원탈퇴'}
                  </button>
                </div>
              </section>
            </div>
          </div>
        </div>
        <ServiceLeaveBackupModal open={backupOpen} onClose={this._handleBackupClose} />
        <ServiceLeaveCalcModal open={calcOpen} data={subscriptionData} onClose={this._handleCalcClose} />
        <ServiceLeaveByeModal open={byeOpen} onClose={this._handleByeClose} />
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { subscription } = state;
  return {
    subscriptionData: subscription.subscriptionData,
  };
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSubscription,
      requestWithdrawal,
      dispatch,
    },
    dispatch
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(ServiceLeave);
export { connected as ServiceLeave };
