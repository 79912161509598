/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';
import { pathConstant } from '_constants';

export class FloatingBar extends Component {
  constructor(props) {
    super(props);

    this._handleSupportMail = this._handleSupportMail.bind(this);
    this._handleToTop = this._handleToTop.bind(this);
  }

  _handleSupportMail() {
    this.props.history.push(pathConstant.PATH_HOME_SUPPORTMAIL);
  }

  _handleToTop(e) {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  render() {
    return (
      <div className="floating_bar">
        <a href="/download/AIBWorks-AM_Guide.zip" className="user_guide">
          <span className="text">사용자 가이드</span>
          <span className="ico"></span>
        </a>
        <a href="" className="contact" onClick={this._handleSupportMail}>
          <span className="text">도입문의</span>
          <span className="ico"></span>
        </a>
        <a href="" className="top" onClick={this._handleToTop}>
          <span className="ico"></span>
        </a>
      </div>
    );
  }
}
