import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { CustomerServiceLnbView } from '_containers';
import { setBulletinRead } from '_services';

class FAQDetail extends Component {
  componentDidMount() {
    const { data } = this.props.location.state;
    const params = { bltId: data.bltid };
    this.props.setBulletinRead(params);
  }
  render() {
    const { data } = this.props.location.state;
    return (
      <div className="content">
        <div className="inner">
          <CustomerServiceLnbView {...this.props} />

          <div className="main_cont">
            <div className="cont_header">
              <h3 className="title">FAQ</h3>
            </div>
            <div className="cont_body">
              <section className="view_sect">
                <div className="view_header">
                  <h4 className="title">{data.title}</h4>
                </div>
                <div className="view_body">
                  <div className="question">
                    <div className="title">
                      <p>
                        <span className="date">{moment(data.createdtime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')}</span> | 작성자 :{' '}
                        <span className="name">{data.compnm}</span>
                      </p>
                    </div>
                    <div className="desc" dangerouslySetInnerHTML={{ __html: data.contents }}></div>
                  </div>
                </div>
                <div className="btn_area">
                  <button
                    type="button"
                    className="btn"
                    onClick={() => {
                      this.props.history.goBack();
                      //   this.props.history.replace(pathConstant.PATH_CUSTOMERSERVICE_NOTICE);
                    }}
                  >
                    목록
                  </button>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setBulletinRead,
      dispatch,
    },
    dispatch
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(FAQDetail);
export { connected as FAQDetail };
