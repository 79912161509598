/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { pathConstant } from '_constants';
import { firebaseSendEmailVerification, firebaseSignIn, firebaseGetIdToken, setLocalSession, getLocalSession, login } from '_services';
import { openAlertDialog } from '_actions';

class EmailVerification extends Component {
  constructor(props) {
    super(props);

    const { location } = this.props;

    this.state = {
      email: location.query ? location.query.email : '',
      password: location.query ? location.query.password : '',
      emailVerified: false,
    };

    this._handleSendEmail = this._handleSendEmail.bind(this);
    this._handleLogin = this._handleLogin.bind(this);
    this._handleNext = this._handleNext.bind(this);
  }
  _handleSendEmail() {
    firebaseSendEmailVerification()
      .then((user) => {
        this.props.dispatch(openAlertDialog('인증메일이 재발송되었습니다.'));
      })
      .catch((error) => {
        this.props.dispatch(openAlertDialog('관리자에게 문의하시기 바랍니다.'));
      });
  }
  _handleLogin() {
    const { email, password } = this.state;

    firebaseSignIn(email, password)
      .then((user) => {
        if (user.user.emailVerified) {
          this._getIdToken();
        } else {
          this.props.dispatch(openAlertDialog('메일 인증을 다시 시도하세요. 이메일 인증이 완료되지 않았습니다.'));
        }
      })
      .catch((error) => {
        this.props.dispatch(openAlertDialog('관리자에게 문의하시기 바랍니다.'));
      });
  }
  _getIdToken() {
    firebaseGetIdToken()
      .then((idToken) => {
        const session = { idToken };
        setLocalSession(session);

        this._login(idToken);
      })
      .catch((error) => {
        this.props.dispatch(openAlertDialog('관리자에게 문의하시기 바랍니다.'));
      });
  }
  _login(idToken) {
    this.props.login({}, (error, data) => {
      if (!error) {
        let session = getLocalSession();
        session = Object.assign({}, session, {
          ...data,
        });
        setLocalSession(session);

        this.setState({
          ...this.state,
          emailVerified: true,
        });
      } else {
        this.props.dispatch(openAlertDialog('관리자에게 문의하시기 바랍니다.'));
      }
    });
  }
  _handleNext() {
    this.props.history.replace(pathConstant.PATH_AUTH_COMPANYINFOMGMT);
  }
  render() {
    const { email, emailVerified } = this.state;
    document.title = '이메일 인증 | 아이브웍스';

    return (
      <div className="content">
        <div className="inner">
          <div className="main_cont">
            <div className="login_steps non_certified">
              <div className="title">이메일 인증</div>
              <div className="text_area">
                <div className="main_text email">인증메일이 발송되었습니다.</div>
                <div className="sub_text">
                  <p>
                    <span className="highlight">{email}</span> 으로
                    <br />
                    인증메일이 발송되었습니다.
                  </p>
                  <p>
                    인증메일에서 링크를 통해 인증을 완료하고,
                    <br />
                    인증 완료 버튼을 클릭해 주세요.
                  </p>
                </div>
              </div>
              {emailVerified && <div className="message">이메일 인증이 완료되었습니다.</div>}
              {!emailVerified && (
                <div className="btn_area">
                  <div className="btn_group">
                    <button type="button" className="btn" onClick={this._handleSendEmail}>
                      인증메일 재발송
                    </button>
                    <button type="button" className="btn btn_black" onClick={this._handleLogin}>
                      인증 완료
                    </button>
                  </div>
                  <p className="message">- 인증메일에서 링크를 통해 인증을 완료하고, 인증 완료 버튼을 클릭해 주세요.</p>
                </div>
              )}
              <div className="btn_area">
                <button
                  type="button"
                  className={'btn btn_wide btn_y_lg ' + (emailVerified ? 'btn_cyan' : 'btn_cream')}
                  disabled={!emailVerified}
                  onClick={this._handleNext}
                >
                  다음
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      login,
      dispatch,
    },
    dispatch
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(EmailVerification);
export { connected as EmailVerification };
