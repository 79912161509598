import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { firebaseSignIn, firebaseGetIdToken, login, getLocalSession, setLocalSession, logout } from '_services';
import { pathConstant } from '_constants';

class Login extends Component {
  constructor(props) {
    super(props);

    logout();

    this.state = {
      email: '',
      password: '',
      loginError: false,
    };

    this._handleChangeEmail = this._handleChangeEmail.bind(this);
    this._handleChangePassword = this._handleChangePassword.bind(this);
    this._handleLogin = this._handleLogin.bind(this);
  }
  _handleChangeEmail(e) {
    const { value } = e.target;
    this.setState({
      ...this.state,
      email: value,
    });
  }
  _handleChangePassword(e) {
    const { value } = e.target;
    this.setState({
      ...this.state,
      password: value,
    });
  }
  _handleLogin() {
    const { email, password } = this.state;

    firebaseSignIn(email, password)
      .then((user) => {
        if (user.user.emailVerified) {
          this._getIdToken();
        } else {
          this.props.history.replace({
            pathname: pathConstant.PATH_AUTH_EMAILVERIFICATION,
            query: {
              email,
              password,
            },
          });
        }
      })
      .catch((error) => {
        this.setState({
          ...this.state,
          loginError: true,
        });
      });
  }
  _getIdToken() {
    firebaseGetIdToken()
      .then((idToken) => {
        this.setState({
          ...this.state,
          loginError: false,
        });

        const session = { idToken };
        setLocalSession(session);

        this._login();
      })
      .catch((error) => {
        this.setState({
          ...this.state,
          loginError: true,
        });
      });
  }
  _login() {
    this.props.login({}, (error, data) => {
      if (!error) {
        let session = getLocalSession();
        session = Object.assign({}, session, {
          ...data,
        });
        setLocalSession(session);

        // status
        // 0: 회원가입 완료 = 회사정보 입력 대기
        // 2: 회사정보 입력 완료 = 서비스 신청 대기
        // 3: 결재 완료 = 서비스 신청 완료
        if (data.status === '0') {
          this.props.history.replace(pathConstant.PATH_AUTH_COMPANYINFOMGMT);
        } else if (data.status === '2') {
          this.props.history.replace(pathConstant.PATH_AUTH_SERVICEAPPLICATION);
        } else {
          this.props.history.replace(pathConstant.PATH_MYPAGE_MYPAGE);
        }
      }
    });
  }
  render() {
    const { email, password, loginError } = this.state;
    document.title = '로그인 | 아이브웍스';
    return (
      <div className="content">
        <div className="inner">
          <div className="main_cont">
            <div className="login_steps non_certified">
              <div className="title">로그인</div>
              <form className="form_area" action="/" method="POST">
                <div className="form-group">
                  <label htmlFor="">이메일 주소</label>
                  <input
                    type="text"
                    name="email"
                    value={email}
                    className="form-control"
                    placeholder="이메일 주소를 입력하세요."
                    onChange={this._handleChangeEmail}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="">비밀번호</label>
                  <input
                    type="password"
                    name="password"
                    value={password}
                    className="form-control"
                    placeholder="비밀번호를 입력하세요."
                    onChange={this._handleChangePassword}
                  />
                </div>
                {loginError && (
                  <div className="message_box">
                    <p className="message alert">
                      이메일 또는 비밀번호를 다시 확인하세요. <br />
                      등록되지 않은 이메일이거나, 이메일 또는 비밀번호를 잘못 입력하셨습니다.
                    </p>
                  </div>
                )}
              </form>
              <div className="btn_area">
                <button type="button" className="btn btn_wide btn_y_lg btn_cyan" onClick={this._handleLogin}>
                  로그인
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      login,
      dispatch,
    },
    dispatch
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(Login);
export { connected as Login };
