import React, { Component } from 'react';

export class TermsOfUseHtml extends Component {
  render() {
    return (
      <>
        <div className="desc">
          <div className="terms_item">
            <h3 className="item_title">제1조 (목적)</h3>
            <div className="item_desc">
              <p>
                본 약관은 아이브웍스 이용자(「아이브웍스」 서비스 약관에 동의한 사람으로서 법적인 용어로 개인위치정보주체를 말합니다. 이하 “이용자”라고
                합니다)가 (주)아이브랩이 제공하는 「아이브웍스」 근태관리 서비스(이하 “서비스”라 합니다)를 이용함에 있어 (주)아이브랩(이하 “회사”라 합니다)과
                이용자의 권리·의무 및 기타 필요한 사항을 규정함을 목적으로 합니다.
              </p>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제2조 (이용약관의 효력 및 변경)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>본 약관은 서비스를 신청한 이용자가 회원가입 절차에 따라 본 약관에 동의하고 서비스의 이용자로 등록함으로써 효력이 발생합니다.</li>
                <li>
                  이용자가 온라인에서 본 약관의 “동의함” 버튼을 선택하였을 경우, 본 약관의 내용을 모두 읽고 이를 충분히 이해하였으며, 그 적용에 동의한 것으로
                  봅니다.
                </li>
                <li>
                  회사는 위치기반서비스의 변경 사항을 반영하기 위하여 필요한 경우 등에는 위치정보의 보호 및 이용 등에 관한 법률, 콘텐츠산업 진흥법, 전자상거래
                  등에서의 소비자보호에 관한 법률, 소비자기본법, 약관의 규제에 관한 법률 등 관련 법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
                </li>
                <li>
                  회사는 약관을 개정할 경우에는 적용일자, 개정사유, 현행약관 및 개정약관의 내용과 개정약관 적용일까지 동의 또는 거부의 의사표시를 하지 아니하면
                  개정약관에 동의한 것으로 본다는 내용을 명시하여 다음과 같은 방법으로 게시 및 통지합니다.
                  <ol>
                    <li>① 서비스 메뉴의 약관에 게시 : 개정 전과 후의 약관을 서비스 기간 중 게시 </li>
                    <li>② 이용자에게 아이브웍스 알림으로 개정 사실 통지</li>
                  </ol>
                </li>
                <li>
                  회사의 전항에 따른 게시 및 통지 후에도 이용자가 개정약관 적용일까지 개정약관에 대해 동의 또는 거부의 의사표시를 하지 않을 때에는 이용자가 해당
                  개정약관에 동의한 것으로 봅니다.
                </li>
                <li>
                  이용자가 개정약관에 동의하지 않을 경우 (회사 또는) 이용자는 이용계약을 해지할 수 있습니다. 서비스를 계속 사용하는 경우에는 개정약관에 동의한
                  것으로 간주되며, 개정약관은 전항과 같은 방법으로 효력이 발생합니다.
                </li>
              </ol>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제3조 (관계법령의 적용)</h3>
            <div className="item_desc">
              본 약관은 신의성실의 원칙에 따라 공정하게 적용하며, 본 약관에 명시되지 아니한 사항에 대하여는 위치정보의 보호 및 이용 등에 관한 법률,
              개인정보보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 콘텐츠산업진흥법, 전자상거래 등에서의 소비자보호에 관한 법률, 저작권법,
              전기통신기본법, 전기통신사업법 등 관계법령과 아이브웍스의 이용약관 및 개인정보처리방침, 별도로 정한 지침 등에 의합니다.
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제 4조 (용어의 정의, 서비스의 내용 등)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>
                  <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
                  <ol>
                    <li>① 이용자 : 서비스에 접속하여 이 약관에 동의하고 가입하여 사용자 아이디를 보유하고, 회사가 제공하는 서비스를 받는 사람</li>
                    <li>② 위치정보 : 이동성이 있는 물건 또는 개인이 특정한 시간에 존재하거나 존재하였던 장소에 관한 정보 </li>
                    <li>③ 개인위치정보 : 특정 개인의 위치정보</li>
                    <li>④ 개인위치정보주체 : 개인위치정보에 의하여 식별되는 사람(이용자)</li>
                    <li>⑤ 이용해지 : 회사 또는 이용자가 서비스 이용 이후, 그 이용계약을 종료시키는 의사 표시</li>
                  </ol>
                </li>
                <li>
                  <p>회사는 위치정보사업자로부터 위치정보를 전달받아 아래와 같은 위치기반서비스를 제공합니다.</p>
                  <ol>
                    <li>
                      ① 아이브웍스 근태관리 서비스는 서비스 가입회사의 근로자 근태 데이터를 기반으로 사용자(근로자)의 근로 기록을 체계적으로 관리 및 운영하는
                      서비스를 제공합니다.
                    </li>
                    <li>
                      ② 근로자들의 출근과 퇴근 정보 수집을 위해 스마트폰 앱의 위치정보와 사업장의 위치정보를 비교하여 근무자가 근무하는 위치에 있는지를 확인하고
                      확인된 정보를 기반으로 출·퇴근 정보를 기록·관리합니다.
                    </li>
                    <li>
                      ③ 타사의 출입관리시스템과 연동시(고객이 요청시) 관련 시스템으로부터 출근 및 퇴근 상태와 시간 정보를 제공받아 출퇴근 정보를
                      기록·관리합니다.
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    회사는 「위치정보의 보호 및 이용 등에 관한 법률」 제16조제2항에 따라 위치정보 이용·제공사실 확인자료를 자동 기록·보존하며, 해당 자료는 6개월 이상 보관합니다.
                  </p>
                </li>
              </ol>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제 5조 (서비스 이용계약 및 요금)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>이용계약은 이용자의 약관내용에 대한 동의와 이용자의 이용신청에 대한 회사의 승낙으로 성립합니다.</li>
                <li>
                  <p>이용신청은 서비스의 이용자 로그인 화면에서 이용자가 회사에서 요구하는 약관에 동의를 통하여 신청합니다.</p>
                  <ol>
                    <li>① 서비스는 이용자에 대하여 약관에 정하는 바에 따라 이용자 아이디를 부여합니다.</li>
                    <li>
                      ② 이용자 아이디는 원칙적으로 변경이 불가하며 부득이한 사유로 인하여 변경하고자 하는 경우에는 해당 아이디를 해지하고 재가입해야 합니다.
                    </li>
                    <li>
                      ③ 서비스 이용자 아이디 및 비밀번호의 관리책임은 이용자에게 있습니다. 이를 소홀히 관리하여 발생하는 서비스 이용 상의 손해 또는 제3자에 의한
                      부정 이용 등에 대한 책임은 이용자에게 있으며 서비스는 그에 대한 책임을 일절 지지 않습니다.
                    </li>
                    <li>④ 기타 이용자 개인정보 관리 및 변경 등에 관한 사항은 서비스가 정하는 바에 의합니다.</li>
                  </ol>
                </li>
                <li>회사는 위 제5조 ②항에서 정한 사항을 확인하여, 이용신청을 하였을 경우 특별한 사정이 없는 한 서비스 이용신청을 승낙합니다.</li>
                <li>
                  <p>
                    회사는 다음 각 호에 해당하는 경우에 대하여는 그 신청에 대한 제한 사유가 해소될 때까지 승낙을 유보, 일부 서비스 이용, 또는 전체 서비스 이용을
                    제한할 수 있습니다.
                  </p>
                  <ol>
                    <li>① 내용이 허위(다른 이용자 사칭, 도용 등)인 것으로 판명되거나, 그러하다고 의심할만한 합리적인 사유가 발생하는 경우</li>
                    <li>② 기타 회사가 서비스 품질 관리를 위해 필요하다고 인정되는 경우</li>
                  </ol>
                </li>
                <li>
                  <p>
                    회사는 다음에 해당하는 경우에는 이용신청을 승낙하지 아니할 수 있습니다. 또한, 이용신청이 승인된 이용자라 할지라도 이용 정지 또는 이용 가입을
                    취소하여 아이디를 삭제할 수 있습니다.
                  </p>
                  <ol>
                    <li>① 허위 또는 타인의 정보로 서비스를 신청한 경우</li>
                    <li>② 신상정보의 내용을 허위로 기재하였을 때</li>
                    <li>③ 사회의 안녕 질서 또는 미풍양속을 저해할 목적으로 신청하였을 때</li>
                    <li>④ 기타 회사가 정한 이용신청 요건이 갖추어지지 않았을 때</li>
                  </ol>
                </li>
                <li>서비스 이용요금은 아이브웍스 홈페이지(https://www.aibworks.com)의 비용안내 페이지에 게재합니다.</li>
              </ol>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제 6조 (이용계약의 성립)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>이 약관은 서비스를 이용하고자 하는 이용자가 가입단계에서 동의 버튼을 누르면 약관에 동의하는 것으로 봅니다.</li>
                <li>
                  유료 서비스를 이용하는 회원은 서비스 이용 신청 전에 회사가 공지하는 해당 유료 서비스의 내용, 서비스 가격, 이용기간, 이용방법, 과금정책,
                  해지조건 및 그 방법, 환불에 관한 사항 및 유료 서비스의 변경 등과 관련한 사항을 사전에 숙지함을 원칙으로 합니다.
                </li>
              </ol>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제 7조 (이용신청의 승낙)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>
                  회사는 제6조에서 규정한 사항을 정확히 기재하고, 이메일 계정 또는 문자메시지 인증 등 회사가 정한 인증절차를 완료한 이용자에 대하여 서비스 이용
                  신청을 승낙합니다.
                </li>
                <li>
                  회사와 회원간 서비스 이용계약은 회사의 승낙의 의사표시가 회원에게 도달한 시점(유료 서비스의 경우, ‘구매/결제완료’등의 표시가 이용자에게 절차상
                  표시된 시점)에 성립합니다.
                </li>
              </ol>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제8조 (서비스 이용 및 추가·변경)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>회사는 이용자의 이용신청을 승낙한 때부터 서비스를 개시합니다. 단, 일부 서비스의 경우에는 지정된 일자부터 서비스를 개시할 수 있습니다.</li>
                <li>회사의 업무상 또는 기술상의 장애로 인하여 서비스를 개시하지 못하는 경우에는 알림으로 통지합니다.</li>
                <li>
                  서비스의 이용은 연중무휴 1일 24시간을 원칙으로 합니다. 다만, 회사의 업무상 또는 기술상의 이유로 서비스가 일시 중지될 수 있고, 또한 정기점검 등
                  운영상의 목적으로 회사가 정한 기간에 서비스가 일시 중지될 수 있습니다. 이러한 경우 회사는 사전 또는 사후에 이를 공지합니다.
                </li>
                <li>서비스에 가입한 후라도 일부 서비스 이용 시 회사의 요구에 따라 특정 이용자에게만 서비스를 제공할 수도 있습니다.</li>
                <li>회사는 서비스를 일정범위로 분할하여 각 범위별로 이용가능 시간을 별도로 정할 수 있습니다. 이 경우 그 내용을 사전에 공지합니다.</li>
                <li>
                  <p>이용자가 제공한 정보의 저장과 사용에 대해 다음과 같이 운영합니다.</p>
                  <ol>
                    <li>
                      ① 이용자는 본 서비스에 보관되거나 전송된 메시지 및 기타 통신 메시지 등의 내용이 국가의 비상사태, 정전, 회사의 관리범위 외의 서비스 설비
                      장애 및 기타 불가항력에 의하여 보관되지 못하였거나 삭제된 경우, 전송되지 못한 경우 및 기타 통신 데이터의 손실에 대해 회사는 책임을 지지
                      않음에 동의합니다.
                    </li>
                    <li>
                      ② 회사의 정상적인 서비스 제공의 어려움으로 인하여 일시적으로 서비스를 중지하여야 할 경우에는 서비스 중지 1주일 전에 고지 후 서비스를
                      중지할 수 있으며, 이 기간동안 이용자가 고지내용을 인지하지 못한 데 대하여 회사는 책임을 부담하지 아니합니다. 긴급 처리 등 상당한 이유가
                      있을 경우 위 사전 고지기간은 감축되거나 생략될 수 있습니다. 또한 위 서비스 중지에 의하여 본 서비스에 보관되거나 전송된 메시지 및 기타 통신
                      메시지 등의 내용이 보관되지 못하였거나 삭제된 경우, 전송되지 못한 경우 및 기타 통신 데이터의 손실이 있을 경우에 대하여도 회사는 책임을
                      부담하지 아니합니다.
                    </li>
                    <li>③ 회사의 사정으로 서비스를 영구적으로 중단하여야 할 경우, 제2항을 준용합니다. 다만, 이 경우 사전 고지기간은 1개월로 합니다.</li>
                    <li>
                      ④ 회사는 사전 고지 후 서비스를 일시적으로 수정, 변경 및 중단할 수 있으며, 이에 대하여 이용자 또는 제3자에게 어떠한 책임도 부담하지
                      아니합니다.
                    </li>
                    <li>
                      ⑤ 회사는 이용자가 이 약관의 내용에 위배되는 행동을 한 경우, 임의로 서비스 사용을 중지할 수 있습니다. 이 경우 회사는 이용자의 접속을 금지할
                      수 있으며, 이용자가 게시한 내용의 전부 또는 일부를 임의로 삭제할 수 있습니다.
                    </li>
                    <li>⑥ 장기간 휴면 이용자인 경우 안내 메일 또는 공지사항 발표 후 1주일 간의 통지 기간을 거쳐 서비스 사용을 중지할 수 있습니다.</li>
                  </ol>
                </li>
                <li>
                  <p>
                    서비스 이용신청 및 이용자 개인정보 변경 시 요구되는 정보는 사실에 근거하여 기입하여야 합니다. 또한 이미 제공된 귀하에 대한 정보가 정확한
                    정보가 되도록 유지, 갱신하여야 하며, 이용자는 자신의 아이디 및 비밀번호를 제3자에게 이용하게 해서는 안 됩니다. 만약 허위 또는 타인의 정보를
                    등록할 경우 일체의 권리를 주장할 수 없습니다. 이용자는 아이브웍스 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안 됩니다.
                  </p>
                  <ol>
                    <li>① 서비스 이용신청 또는 개인정보 변경 시 허위 내용을 기재하거나 다른 이용자의 비밀번호와 아이디를 도용하여 부정 사용하는 행위</li>
                    <li>② 저속, 음란, 모욕적, 위협적이거나 타인의 사생활을 침해할 수 있는 내용을 등록 또는 기타의 방법으로 전송하는 행위</li>
                    <li>③ 아이브웍스 운영진, 직원 또는 관계자를 사칭하는 행위</li>
                    <li>④ 서비스를 통하여 전송된 내용의 출처를 위장하는 행위</li>
                    <li>⑤ 법률, 계약에 의해 이용할 수 없는 내용을 등록 또는 기타의 방법으로 전송하는 행위</li>
                    <li>
                      ⑥ 아이브웍스로부터 특별한 권리를 부여받지 않고 아이브웍스의 앱 프로그램을 변경하거나, 서버 해킹 및 컴퓨터바이러스 유포, 앱 또는 게시된
                      정보의 일부분 또는 전체를 임의로 변경하는 행위
                    </li>
                    <li>⑦ 타인의 특허, 상표, 영업비밀, 저작권, 기타 지적재산권을 침해하는 내용을 등록 또는 기타의 방법으로 전송하는 행위</li>
                    <li>
                      ⑧ 아이브웍스의 승인을 받지 아니한 광고, 판촉물, 스팸메일, 행운의 편지, 피라미드 조직 기타 다른 형태의 권유를 등록 또는 기타의 방법으로
                      전송하는 행위
                    </li>
                    <li>⑨ 다른 이용자의 개인정보를 수집, 저장, 공개하는 행위</li>
                    <li>⑩ 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위</li>
                    <li>⑪ 선량한 풍속, 기타 사회질서를 해하는 행위</li>
                    <li>⑫ 타인의 명예를 훼손하거나 모욕하는 행위</li>
                    <li>⑬ 타인의 지적재산권 등의 권리를 침해하는 행위</li>
                    <li>⑭ 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로 전송하는 행위</li>
                    <li>⑮ 서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위</li>
                    <li>⑯ 아이브웍스에 게시된 정보의 변경</li>
                    <li>⑰ 본 약관을 포함하여 기타 아이브웍스가 정한 제반 규정 또는 이용 조건을 위반하는 행위</li>
                    <li>⑱ 기타 관계법령에 위배되는 행위</li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제9조 (서비스이용의 제한 및 중지)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>
                  <p>회사는 아래 각 호에 해당하는 사유가 발생한 경우 이용자에게 서비스의 일부 혹은 전부의 이용을 제한하거나 중지할 수 있습니다.</p>
                  <ol>
                    <li>⑨ 이용자 또는 다른 사람들의 개인정보, 위치정보 등 사생활 침해 여지가 있는 정보를 제보하는 행위</li>
                    <li>⑩ 거짓 정보를 제보하여 서비스의 신뢰도와 안정성을 훼손하는 행위</li>
                    <li>⑪ 지나친 서비스 접속을 통해 서비스 품질을 저해하는 행위</li>
                    <li>⑫ 기타 서비스 제공에 장애를 주는 행위</li>
                  </ol>
                </li>
                <li>
                  회사는 전항의 규정에 의하여 서비스의 이용을 제한하거나 중지한 때에는 그 사유 및 제한기간 등을 전자적 형태(전자우편, SMS, 서비스 알림 등)로
                  개별 통지하여야 합니다.
                </li>
                <li>회사는 전항에 따른 통지를 할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.</li>
                <li>
                  <p>
                    아이브웍스 서비스는 다음 각 호에 해당하는 등록물이나 자료를 사전통지 없이 삭제하거나 등록 거부를 할 수 있습니다. 이용자의 등록물이 타인에게
                    피해를 주는 민, 형사상의 책임은 전적으로 이용자가 부담하여야 합니다.
                  </p>
                  <ol>
                    <li>① 본서비스 약관에 위배되거나 상용 또는 불법, 음란, 저속하다고 판단되는 등록물을 등록한 경우</li>
                    <li>② 다른 이용자 또는 제3자에게 심한 모욕을 주거나 명예를 손상시키는 내용인 경우</li>
                    <li>③ 공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크시키는 경우</li>
                    <li>④ 불법복제 또는 해킹을 조장하는 내용인 경우</li>
                    <li>⑤ 영리를 목적으로 하는 광고일 경우</li>
                    <li>⑥ 범죄와 결부된다고 객관적으로 인정되는 내용일 경우</li>
                    <li>⑦ 다른 이용자 또는 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우</li>
                    <li>⑧ 기타 관계법령에 위배된다고 판단되는 경우</li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제10조 (개인위치정보의 이용 또는 제공)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>회사는 개인위치정보를 이용하여 서비스를 제공하고자 하는 경우에는 미리 이용약관에 명시한 후 이용자의 동의를 얻어야 합니다.</li>
                <li>
                  회사는 개인위치정보를 이용자가 지정하는 제3자에게 제공하는 경우에는 제공받는 자 및 제공목적을 사전에 이용자에게 고지하고 동의를 받습니다.
                </li>
                <li>
                  <p>
                    제2항에 따라 개인위치정보를 이용자가 지정하는 제3자에게 제공하는 경우에는 개인위치정보를 수집한 해당 통신 단말장치 또는 전자우편주소 등으로
                    매회 이용자에게 제공받는 자, 제공일시 및 제공목적(이하 “정보제공내역”이라 합니다)을 즉시 통보합니다.
                  </p>
                  <ol>
                    <li>① 통보 방법은 전자적 형태(전자우편, SMS, 서비스 알림, 세부 내역 조회 등)로 제공할 수 있습니다.</li>
                    <li>③ 통보 내용이 많은 경우에 총괄 내용을 즉시 통보하고, 전체 세부 내역을 조회하는 방식으로 제공할 수 있습니다.</li>
                  </ol>
                </li>
                <li>
                  제3항에도 불구하고 이용자는 위치정보법 시행령 제24조에 따라 정보제공내역을 30일마다 모아서 통보받는 방법을 선택할 수 있으며, 이용자가 회사의
                  절차에 따라 요청할 경우 전항에 따른 즉시 통보 방법으로 변경할 수 있습니다.
                </li>
                <li>
                  이용자는 제1항·제2항·제4항에 따른 동의를 하는 경우 이용·제공목적, 제공받는 자의 범위 및 위치기반서비스 이용약관의 내용 중 일부와 이용자의
                  개인위치정보에 대한 제3자 제공의 경우 통보방법에 대하여 동의를 유보할 수 있습니다.
                </li>
              </ol>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제11조 (개인위치정보의 이용 또는 제공의 제한)</h3>
            <div className="item_desc">
              <p>
                회사는 이용자의 동의가 있거나 다음 각 호의 어느 하나에 해당하는 경우를 제외하고는 개인위치정보 또는 위치정보 이용·제공사실 확인자료를 이용약관에
                명시 또는 고지한 범위를 넘어 이용하거나 제3자에게 제공할 수 없습니다.
              </p>
              <ol className="num_list">
                <li>위치기반서비스의 제공에 따른 요금정산을 위하여 위치정보 이용·제공사실 확인자료가 필요한 경우</li>
                <li>통계작성, 학술연구, 연구개발, 또는 시장조사를 위하여 특정 개인을 알아볼 수 없는 형태로 가공하여 제공하는 경우</li>
              </ol>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제12조 (개인위치정보주체(이용자)의 권리 및 행사방법)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>
                  이용자는 회사에 대하여 언제든지 개인위치정보를 이용한 위치기반서비스 제공 및 개인위치정보의 제3자 제공에 대한 동의의 전부 또는 일부를 철회할
                  수 있습니다. 이 경우 회사는 제공받은 개인위치정보 및 위치정보 이용, 제공사실 확인자료를 파기합니다. 단, 동의의 일부를 철회하는 경우에는
                  철회하는 부분의 개인위치정보 및 위치정보 이용·제공사실 확인자료에 한합니다.
                </li>
                <li>
                  이용자는 회사에 대하여 언제든지 개인위치정보의 이용 또는 제공의 일시적인 중지를 요구할 수 있고, 회사는 이를 거절할 수 없으며 이를 위한 기술적
                  수단을 갖추고 있습니다.
                </li>
                <li>
                  <p>
                    이용자는 회사에 대하여 아래 각 호의 자료에 대한 열람 또는 고지를 요구할 수 있고, 해당 자료에 오류가 있는 경우에는 그 정정을 요구할 수
                    있습니다. 이 경우 회사는 정당한 사유 없이 이용자의 요구를 거절할 수 없습니다.
                  </p>
                  <ol>
                    <li>① 본인에 대한 위치정보 이용, 제공사실 확인자료</li>
                    <li>② 본인의 개인위치정보가 위치정보의 보호 및 이용 등에 관한 법률 또는 다른 법률 규정에 의하여 제3자에게 제공된 이유 및 내용</li>
                  </ol>
                </li>
                <li>
                  이용자는 제1항 내지 제3항의 권리행사를 위해 부칙 2항에 규정하고 있는 위치정보관리자에게 연락하여 요구할 수 있습니다. 부칙 3항을 통해
                  권리행사의 세부 사항을 지원합니다.
                </li>
              </ol>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제13조 (법정대리인의 권리 및 행사방법)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>
                  <p>
                    회사는 14세 미만의 이용자의 경우, 개인위치정보를 이용한 위치기반서비스 제공 및 개인위치정보의 제3자 제공에 대하여 해당 이용자와 그
                    법정대리인 모두의 동의를 받아야 합니다. 이 경우 법정대리인은 제12조에 의한 이용자의 권리를 모두 가집니다.
                  </p>
                  <ol>
                    <li>
                      ① 14세 미만의 이용자가 서비스 이용신청을 할 때 법정대리인의 연락 전화번호를 제공하여야 합니다. 연락 전화번호를 제공하지 않을 때 회사는
                      이용신청을 승인하지 않습니다.{' '}
                    </li>
                    <li>② 회사는 14세 미만의 이용자가 서비스 가입 과정에 제공하는 법정대리인 정보에 대해 검증의 책임을 부담하지 않고 정당하다고 간주합니다.</li>
                  </ol>
                </li>
                <li>
                  <p>회사는 다음 각 호의 어느 하나에 해당하는 방법으로 법정대리인이 동의했는지를 확인합니다.</p>
                  <ol>
                    <li>
                      ① 동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를 표시하도록 하고 위치정보사업자 등이 그 동의 표시를 확인했음을 법정대리인의
                      휴대전화 문자메시지로 알리는 방법
                    </li>
                    <li>
                      ② 의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를 표시하도록 하고 법정대리인의 신용카드ㆍ직불카드 등의 카드정보를 제공받는 방법
                    </li>
                    <li>
                      ③ 동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를 표시하도록 하고 법정대리인의 휴대전화 본인인증 등을 통해 본인 여부를
                      확인하는 방법
                    </li>
                    <li>
                      ④ 동의 내용이 적힌 서면을 법정대리인에게 직접 발급하거나, 우편 또는 팩스를 통하여 전달하고 법정대리인이 동의 내용에 대하여 서명날인 후
                      제출하도록 하는 방법
                    </li>
                    <li>⑤ 동의 내용이 적힌 전자우편을 발송하여 법정대리인으로부터 동의의 의사표시가 적힌 전자우편을 전송받는 방법</li>
                    <li>
                      ⑥ 전화를 통하여 동의 내용을 법정대리인에게 알리고 동의를 얻거나, 인터넷주소 등 동의 내용을 확인할 수 있는 방법을 안내하고 재차 전화 통화를
                      통하여 동의를 얻는 방법
                    </li>
                    <li>
                      ⑦ 그 밖에 제1호부터 제6호까지의 규정에 따른 방법에 준하는 방법으로 법정대리인에게 동의 내용을 알리고 동의의 의사표시를 확인하는 방법
                    </li>
                    <li>⑧ 회사는 14세 미만의 이용자가 서비스 가입 과정에 제공하는 법정대리인 정보에 대해 검증의 책임을 부담하지 않고 정당하다고 간주합니다.</li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>         
          <div className="terms_item">
            <h3 className="item_title">제14조 (위치정보관리책임자의 지정)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>
                  회사는 위치정보를 적절히 관리·보호하고 이용자의 불만을 원활히 처리할 수 있도록 실질적인 책임을 질 수 있는 지위에 있는 자를
                  위치정보관리책임자로 지정해 운영합니다.{' '}
                </li>
                <li>위치정보관리책임자는 위치기반서비스를 제공하는 기관의 기관장으로서 구체적인 사항은 본 약관의 부칙 2항에 따릅니다.</li>
              </ol>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제15조 (손해배상)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>
                  회사가 위치정보의 보호 및 이용 등에 관한 법률 제15조 내지 제26조의 규정을 위반한 행위로 이용자에게 손해가 발생한 경우 이용자는 회사에 대하여
                  손해배상 청구를 할 수 있습니다. 이 경우 회사는 고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할 수 없습니다.
                </li>
                <li>
                  이용자 등록물의 저작권은 등록한 이용자에게 귀속됩니다. 또한 아이브웍스는 등록자의 동의 없이 등록물을 상업적으로 이용할 수 없습니다. 다만
                  비영리 목적인 경우는 그러하지 아니하며, 또한 서비스 내의 게재권을 갖습니다.
                </li>
                <li>이용자는 서비스를 이용하여 취득한 정보를 임의 가공, 판매하는 행위 등 서비스에 게재된 자료를 상업적으로 사용할 수 없습니다.</li>
                <li>
                  서비스는 이용자가 등록하거나 등록하는 서비스 내의 내용물, 등록 내용에 대해 제9조4항 각 호에 해당된다고 판단되는 경우 사전통지 없이 삭제하거나
                  이동 또는 등록 거부할 수 있습니다.
                </li>
              </ol>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제16조 (이용자 탈퇴 및 해지)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>
                  이용자 가입을 해지하고자 하는 때에는 이용자 본인이 아이브웍스 홈페이지(https://www.aibworks.com) 고객지원의 문의하기의 탈퇴 요청
                  의뢰(글쓰기)를 통해 탈퇴를 해야 합니다.
                </li>
                <li>회원탈퇴를 요청한 경우에는 수집된 개인정보는 개인정보의 수집목적 또는 제공받은 목적이 달성되면 파기 및 삭제하는 것을 원칙으로 합니다.</li>
              </ol>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제17조 (아이브웍스 저작권)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>
                  아이브웍스가 제공하는 서비스, 그에 필요한 소프트웨어, 이미지, 마크, 로고, 디자인, 서비스명칭, 정보 및 상표 등과 관련된 지적재산권 및 기타
                  권리는 아이브웍스에 소유권이 있습니다.
                </li>
                <li>
                  이용자는 아이브웍스가 명시적으로 승인한 경우를 제외하고는 아이브웍스에 소유권이 있는 소정의 각 재산에 대해 전부 혹은 일부 수정, 배포, 상업적
                  이용 행위 등을 할 수 없으며, 제3자로 하여금 이와 같은 행위를 하도록 허락할 수 없습니다.
                </li>
              </ol>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제18조 (양도금지)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>이용자가 서비스의 이용권한, 기타 이용계약 상, 지위를 타인에게 양도 및 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.</li>
              </ol>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제19조 (면책조항)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다.</li>
                <li>서비스는 통신사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.</li>
                <li>서비스는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다.</li>
                <li>
                  서비스는 이용자의 단말에 의해 손해가 발생한 경우, 또는 이용자가 개인정보 및 전자우편 주소를 부실하게 기재하여 손해가 발생한 경우 책임을 지지
                  않습니다.
                </li>
                <li>
                  서비스는 이용자나 제3자에 의해 표출된 어떠한 의견이나 정보에 대해 확신이나 대표할 의무가 없으며 의견을 승인하거나 반대하거나 수정하지
                  않습니다. 서비스는 어떠한 경우라도 이용자가 서비스에 담긴 정보에 의존해 얻은 이득이나 입은 손해에 대해 책임이 없습니다.
                </li>
                <li>
                  서비스는 이용자 간 또는 이용자와 제3자 간에 서비스를 매개로 하여 물품거래 혹은 금전적 거래 등과 관련하여 어떠한 책임도 부담하지 아니하고,
                  이용자가 서비스의 이용과 관련하여 기대하는 이익에 관하여 책임을 부담하지 않습니다.
                </li>
                <li>
                  서비스는 이용자 또는 기타 유관기관이 서비스에 게재한 정보에 대해 정확성, 신뢰도에 대하여 보장하지 않습니다. 또한, 서비스는 위험이 발생할
                  가능성이 있으니 조심하는 것이 더 안전할 것이라는 의미의 알림으로 제공하고 있습니다. 따라서 아이브웍스는 이용자가 위 내용을 이용함으로 인해
                  입게 된 모든 종류의 손실이나 손해에 대하여 책임을 부담하지 아니합니다.
                </li>
                <li>
                  서비스는 이용자가 서비스 이용과 관련하여 이용자자에게 발생한 손해 중 이용자의 고의, 과실에 의한 손해 및 타 이용자로 인해 입게 되는 정신적
                  피해에 대하여 보상할 책임을 지지 않습니다.
                </li>
              </ol>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제20조(개인위치정보의 보유목적 및 이용기간)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>회사는 위치기반서비스를 제공하기 위해 필요한 최소한의 기간 동안 개인위치정보를 보유 및 이용합니다.</li>
                <li>
                  회사는 「위치정보의 보호 및 이용 등에 관한 법률」제16조제2항에 따라 위치 정보 이용·제공사실 확인자료를 자동 기록·보존하며, 해당 자료는 6개월간
                  이상 보관합니다.
                </li>
                <li>
                  회사는 개인위치정보의 이용 또는 제공목적을 달성한 때에는 제3항의 위치 정보 이용·제공사실 확인자료를 제외한 개인위치정보를 즉시 파기합니다.
                  다만, 다른 법률에 따라 보유하여야 하거나 회원이 개인위치정보의 보유에 별도로 동의한 경우에는 회원이 동의한 때로부터 최대 1년간 이를 보유할 수
                  있습니다.
                </li>
              </ol>
            </div>
          </div>

          <div className="terms_item">
            <h3 className="item_title">제21조(적용 예외)</h3>
            <div className="item_desc">
              <p>
                만 14세 미만의 아동은 회원 가입대상이 아닙니다. 따라서, 만14세 미만 아동인 최종 이용자에 대한 위치기반서비스와 관련한 민원 발생 등에 대해서는
                책임을 지지 않습니다.
              </p>
            </div>
          </div>

          <div className="terms_item">
            <h3 className="item_title">제22조(피성년후견인 동의 보호의무자의 권리)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>
                  <p>
                    회사는 아래의 경우에 해당하는 자(이하 “피성년후견인 등”)의 위치정보의 보호 및 이용 등에 관한 법률 제26조2항에 해당하는 자(이하
                    “보호의무자”)가 피성년후견인 등의 생명 또는 신체보호를 위하여 개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의 동의가 있는 것으로
                    봅니다.
                  </p>
                  <ol>
                    <li>① 피성년후견인</li>
                    <li>
                      ② 장애인복지법 제2조제2항제2호에 따른 정신적 장애를 가진 사람으로서 장애인고용촉진 및 직업재활법 제2조제2호에 따른 중증장애인에 해당하는
                      사람(장애인복지법 제32조에 따라 장애인 등록을 한 사람만 해당한다)
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    피성년후견인 등의 생명 또는 신체의 보호를 위하여 개인위치정보의 이용 또는 제공에 동의를 하고자 하는 보호의무자는 서면동의서에 보호의무자임을
                    증명하는 서면을 첨부하여 회사에 제출하여야 합니다.
                  </p>
                </li>
                <li>
                  <p>보호의무자는 피성년후견인 등의 개인위치정보 이용 또는 제공에 동의하는 경우 본 약관 제7조에 의한 최종이용자의 권리를 모두 가집니다.</p>
                </li>
              </ol>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제23조(이용자의 개인정보보호)</h3>
            <div className="item_desc">
              <p>
                회사는 관련 법령이 정하는 바에 따라서 회원 등록정보를 포함한 회원의 개인정보를 보호하기 위하여 노력합니다. 회원의 개인정보보호에 관해서는 관련
                법령 및 회사가 정하는 「개인정보처리방침」에서 정한 바에 의합니다.
              </p>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제24조(개인정보의 위탁)</h3>
            <div className="item_desc">
              <p>
                회사는 수집된 개인정보의 처리 및 관리 업무 등(이하 “업무”)을 스스로 수행함을 원칙으로 하나, 필요한 경우 업무의 일부 또는 전부를 외부업체에
                위탁할 수 있습니다.
              </p>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제25조(기기의 임대 및 제공)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>
                  본 서비스를 이용자에게 제공하기 위해 회사는 사용자에게 특정한 기기 혹은 통신망을 유상 혹은 무상으로 임대 혹은 제공할 수 있습니다. 이때 회원의
                  책임있는 사유로 고장/훼손/분실된 경우에 회사는 회원에게 발생한 손해에 대한 보상을 요청할 권리가 있습니다.
                </li>
                <li>
                  본 서비스와 관련해 회원에게 특정한 기기 혹은 통신망을 유상 또는 무상으로 임대 또는 제공시 이에 대한 소유권은 회사에 있으며, 회원의
                  폐점/이전/업종변경 등 회원의 사정변경으로 인해 서비스 이용을 해지할시 회사에 이를 반환해야할 의무가 있습니다.
                </li>
                <li>
                  본 서비스와 관련해 회원에게 특정한 기기 혹은 통신망을 유상 또는 무상으로 임대 또는 제공시 기기 배터리의 완전 소모 또는 회원의 귀책사유 없이
                  품질보증 기간내 발생한 기기 고장의 경우, 이에 대한 유지보수 책임은 회사에 있습니다. 다만, 이용자는 기기 배터리의 완전 소모 또는 회원의
                  귀책사유 없이 발생한 기기 고장임을 회사에 입증할 의무가 있으며, 그 외의 사유로 발생한 기기 고장의 경우 회사는 그 손해에 대한 보상을 회원에게
                  요구할 권리가 있습니다.
                </li>
              </ol>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제26조(준거법 및 재판관할)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>본 약관은 대한민국법령에 의하여 규정되고 이행됩니다.</li>
                <li>
                  서비스와 이용자 간에 발생한 서비스 이용에 관한 분쟁에 대하여는 대한민국 법을 적용하며, 본 분쟁으로 인한 소는 대한민국의 법원에 제기합니다.
                </li>
                <li>이 약관에 명시되지 않은 사항은 전기통신사업법 등 관계법령과 상관습에 따릅니다.</li>
                <li>본 서비스 이용으로 발생한 분쟁에 대해 소송이 제기될 경우 아이브웍스 소재지를 관할하는 법원으로 합니다.</li>
              </ol>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제27조(분쟁의 조정 및 기타)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>
                  회사는 위치정보와 관련된 분쟁에 대해 당사자 간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 「위치정보의 보호 및 이용 등에 관한
                  법률」 제28조의 규정에 의해 방송통신위원회에 재정을 신청할 수 있습니다.
                </li>
                <li>
                  회사 또는 이용자는 위치정보와 관련된 분쟁에 대해 당사자 간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 「개인정보보호법」 제43조의
                  규정에 의한 개인정보분쟁조정위원회에 조정을 신청할 수 있습니다.
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div className="sub_desc">
          <h4 className="desc_title">부칙</h4>
          <div className="terms_item">
            <h3 className="item_title">제1조(시행일)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>이 약관은 2023년 9월 1일부터 서비스 종료일까지 시행한다.</li>
                <li>서비스 종료일은 회사의 서비스 운영 상황에 따라 사전 통지 후에 그 1개월 전에 종료할 수 있다.</li>
              </ol>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제2조(사업자 정보 및 위치정보관리책임자 등)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>
                  <p>회사의 상호 및 주소 등은 다음과 같습니다.</p>
                  <ol>
                    <li>① 상 호 : (주)아이브랩</li>
                    <li>② 대 표 자 : 서영주, 김용우</li>
                    <li>③ 주 소 : 경기도 성남시 수정구 창업로 43 판교 글로벌비즈센터 A동 701, 702호 (우)13449</li>
                    <li>④ 대표전화 : 070-8892-7160</li>
                  </ol>
                </li>
                <li>회사의 대표자(김용우)는 위치정보관리책임자를 겸하고 있습니다.</li>
                <li>
                  <p>
                    회사는 서비스 이용 과정에서 발생하는 민원사항 처리를 비롯하여 이용자 권리 보호를 위해 다음과 같이 위치정보관리책임자가 위치정보관리자를
                    지정하여 운영합니다.
                  </p>
                  <ol>
                    <li>① 위치정보관리자 : 심화진 이사</li>
                    <li>② 소속 : (주)아이브랩 융합기술연구소 기획팀</li>
                    <li>③ 연락메일 : aaron.shim@aiblab.co.kr</li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
          <div className="terms_item">
            <h3 className="item_title">제3조(서비스 운영팀)</h3>
            <div className="item_desc">
              <ol className="num_list">
                <li>
                  <p>서비스의 원활한 운영과 이용자들의 제반 민원사항 처리를 위해 서비스 운영 및 지원팀을 구성하여 이용자 서비스를 제공합니다.</p>
                  <ol>
                    <li>① 위치정보취급자 : 김윤호 (연구위원), 정명훈(수석연구위원)</li>
                    <li>② 소속 : (주)아이브랩 융합기술연구소 개발팀</li>
                    <li>③ 연락메일 : 김윤호 연구위원(solgen001@aiblab.co.kr), 정명훈 수석연구위원(mhjung@aiblab.co.kr)</li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </>
    );
  }
}
