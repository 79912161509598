/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

export class IntroduceGoAdminModal extends Component {
  constructor(props) {
    super(props);

    this._handleShow = this._handleShow.bind(this);
  }
  _handleShow() {}
  render() {
    return (
      <Modal onShow={this._handleShow} show={this.props.open} backdrop="static" dialogClassName="modal-dialog-centered message_modal">
        <Modal.Header>
          <h5 className="modal-title">안내</h5>
          <button type="button" className="btn-close" onClick={this.props.onClose}></button>
        </Modal.Header>
        <Modal.Body>
          <div className="message">
            회원가입이 완료 되었습니다.
            <br />
            이제 스마트한 <span className="highlight">근태관리를 설정</span>하시겠습니까?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn_area">
            <button type="button" className="btn" onClick={this.props.onGoAdmin}>
              서비스 바로가기
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
