import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLocalSession, paymentRequest, updateSubscriptionUserCount, getCompanyInfo } from '_services';
import { Modal } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { loadingStart, loadingStop, openAlertDialog } from '_actions';
import { commonUtils } from '_helpers';
import { UserCountRangeSlider } from '_components';
import { commonConstant } from '_constants';
class ServiceApplicationModal extends Component {
  constructor(props) {
    super(props);

    const { updateTarget, data } = this.props;
    this.state = {
      isShow: false,
      termsAgree: false,
      updateTarget,
      userCount: data ? data.usercount : 100,
      unitPrice: 1990,
      joinDate: moment().format('YYYY-MM-DD'),
    };

    this._handleShow = this._handleShow.bind(this);
    this._handleHide = this._handleHide.bind(this);
    this._toggleTermsAgree = this._toggleTermsAgree.bind(this);
    this._handlePayment = this._handlePayment.bind(this);
    this._handleRangeChange = this._handleRangeChange.bind(this);
    this._handleUpdateTarget = this._handleUpdateTarget.bind(this);
  }
  _handleShow() {
    const { updateTarget, data } = this.props;

    this.setState({
      ...this.state,
      isShow: true,
      termsAgree: false,
      updateTarget,
      userCount: data ? data.usercount : 100,
      joinDate: data ? moment(data.joindate, 'YYYY-MM-DD').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
    });
  }
  _handleHide() {
    this.setState({
      ...this.state,
      isShow: false,
    });
    this.props.onClose();
  }
  _toggleTermsAgree() {
    this.setState({
      ...this.state,
      termsAgree: !this.state.termsAgree,
    });
  }
  _handlePayment() {
    const { updateTarget } = this.state;
    if (updateTarget === 'P') {
      this._updateUserCount();
    } else {
      this._payletterAuthCard();
    }
  }
  _updateUserCount() {
    const { userCount, unitPrice } = this.state;
    const param = {
      usercount: userCount,
      usagefee: unitPrice * userCount,
    };
    this.props.updateSubscriptionUserCount(param, (error, data) => {
      if (!error) {
        this.props.dispatch(
          openAlertDialog('변경되었습니다.', false, null, null, () => {
            window.location.reload();
          })
        );
      }
    });
  }
  _payletterAuthCard() {
    const { userCount, unitPrice, joinDate } = this.state;
    const session = getLocalSession();
    const serviceName = 'AIBWorks';
    const productName = 'AIBWorks BASIC';

    const custom_parameter = {
      tenantid: session.tenantid,
      servicename: serviceName,
      usercount: userCount,
      usagefee: unitPrice * userCount,
      joindate: joinDate,
      trialenddate: moment(joinDate, 'YYYY-MM-DD').add(commonConstant.ADDITIONAL_TRIALDAYS, 'days').format('YYYY-MM-DD'),
      updateyn: 'Y',
      origin: document.location.origin,
    };

    const returnUrl = `${process.env.REACT_APP_PAYLETTER_RETURN_URL}/payment-card`;

    const param = {
      pgcode: 'creditcard',
      client_id: 'aiblab',
      service_name: serviceName,
      user_id: session.compid,
      user_name: session.compnm,
      order_no: uuidv4(),
      amount: 0,
      tax_amount: 0,
      product_name: productName,
      email_flag: 'Y',
      email_addr: 'admin@aiblab.co.kr',
      autopay_flag: 'Y',
      custom_parameter: JSON.stringify(custom_parameter),
      callback_url: returnUrl,
      return_url: returnUrl,
    };
    this.props.paymentRequest(param, (error, data) => {
      if (!error) {
        let receiveMessage = (event) => {
          if (event.data === 'payment-success') {
            this.props.getCompanyInfo({}, () => {
              window.location.reload();
            });
          } else if (event.data === 'payment-fail') {
            //this.props.dispatch(openAlertDialog("관리자에게 문의하시기 바랍니다."));
          }
        };
        window.addEventListener('message', receiveMessage, false);

        setTimeout(() => {
          window.fetch.dispatch(loadingStart());
        }, 150);
        let payletterPopup = window.open(data.online_url, '인증', 'width=400, height=580, menubar=no, status=no, toolbar=no, resizable=yes');
        let checkPopupClosed = setInterval(() => {
          if (payletterPopup.closed) {
            clearInterval(checkPopupClosed);
            window.fetch.dispatch(loadingStop());
          }
        }, 1000);
      }
    });
  }
  _handleRangeChange(data) {
    this.setState({
      ...this.state,
      userCount: data.from,
    });
  }
  _handleUpdateTarget(updateTarget) {
    const session = getLocalSession();
    if (session.payment_status !== 'S' && updateTarget === 'P') {
      this.props.dispatch(openAlertDialog('미결제 상태인 경우 사용인원 변경이 불가합니다.'));
      return;
    }

    this.setState({
      updateTarget,
    });
  }
  render() {
    const { isShow, termsAgree, updateTarget, userCount, unitPrice, joinDate } = this.state;
    const paymentDate = moment(joinDate, 'YYYY-MM-DD').add(90, 'days').endOf('month').format('YYYY년 M월 D일');
    const { data } = this.props;

    return (
      <Modal
        onShow={this._handleShow}
        show={this.props.open}
        onHide={this._handleHide}
        backdrop="static"
        dialogClassName="modal-dialog-centered modal-dialog-scrollable"
      >
        <Modal.Header>
          <h5 className="modal-title">{updateTarget === 'P' ? '사용인원 변경' : '자동결제카드 변경'}</h5>
          <button type="button" className="btn-close" onClick={this.props.onClose}></button>
        </Modal.Header>
        <Modal.Body>
          {updateTarget === 'P' && (
            <>
              <div className="service_change">
                <p className="tit">사용인원 선택</p>
                {isShow && <UserCountRangeSlider userCount={userCount} unitPrice={unitPrice} asterrisk={true} onChange={this._handleRangeChange} />}
              </div>
              <div className="service_summary">
                <div className="title">요약 정보</div>
                <ul className="dash_list">
                  <li>
                    사용인원 : <span className="people_num">{userCount}명</span>
                  </li>
                  <li>
                    결제금액 : 매월 <span className="monthly_price">{commonUtils.numberWithCommas(unitPrice * userCount)} 원</span> (부가세 별도)
                  </li>
                  <li>
                    결제방식 : 월 말일 자동결제 (결제 시작일 : <span className="start_date">{paymentDate}</span>)
                  </li>
                </ul>
              </div>
            </>
          )}

          {updateTarget === 'C' && (
            <>
              <div className="service_change">
                <p className="tit">등록된 자동결제 카드</p>
                <div className="card_info">
                  <p className="name">{data && data.payinfo}</p>
                  <p className="num">{data && data.cardinfo}</p>
                </div>
              </div>
              <div className="service_summary">
                <div className="title">요약 정보</div>
                <ul className="dash_list">
                  <li>
                    사용인원 : <span className="people_num">{userCount}명</span>
                  </li>
                  <li>
                    결제금액 : 매월 <span className="monthly_price">{commonUtils.numberWithCommas(unitPrice * userCount)} 원</span> (부가세 별도)
                  </li>
                  <li>
                    결제방식 : 월 말일 자동결제 (결제 시작일 : <span className="start_date">{paymentDate}</span>)
                  </li>
                </ul>
              </div>
              <div className="service_consent">
                <ol className="num_list">
                  <li>수집/이용 목적 : 서비스 자동결제</li>
                  <li>수집/이용 항목 : 신용카드 자동결제 / 신용카드 번호</li>
                  <li>보유/이용기간 : 5년 (전자상거래등에서의 소비자보호에 관한 법률: 대금결제 및 재화 등의 공급에 관한 기록)</li>
                  <li>위 개인정보 수집/이용에 동의하지 않으실 경우, 해당서비스를 이용하실 수 없습니다.</li>
                </ol>
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id="termsAgree" checked={termsAgree} onChange={this._toggleTermsAgree} />
                  <label htmlFor="termsAgree" className="custom-control-label">
                    개인 정보 수집, 이용 동의 (필수)
                  </label>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="btn_area">
            <button
              type="button"
              className="btn btn_black"
              onClick={this._handlePayment}
              disabled={updateTarget === 'P' ? (userCount > 0 ? false : true) : termsAgree ? false : true}
            >
              변경
            </button>
            <button type="button" className="btn" onClick={this._handleHide}>
              취소
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      paymentRequest,
      updateSubscriptionUserCount,
      getCompanyInfo,
      dispatch,
    },
    dispatch
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(ServiceApplicationModal);
export { connected as ServiceApplicationModal };
