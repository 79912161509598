import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { pathConstant } from '_constants';
import { MyPageLnbView, CompanyInfoView } from '_containers';
import {
  getLocalSession,
  getCompanyInfo,
  setCompanyInfo,
  joinCompanyInfo,
  checkCompanyInfoComnm,
  checkCompanyInfoComid,
  uploadCompandyRegistrationFile,
} from '_services';

class CompanyInfoMgmt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      postCodeOpen: false,
      // status
      // 0: 회원가입 완료 = 회사정보 입력 대기
      // 2: 회사정보 입력 완료 = 서비스 신청 대기
      // 3: 결재 완료 = 서비스 신청 완료
      joinStep: '0',
      // 0 : 사업자등록증 미등록
      // 1 : 사업자등록증 등록
      hasBrlicense: '0',
      tenantid: '',
      compnm: '',
      compid: '',
      brnumber: '',
      brlicense: '',
      deptcode: '',
      adminnm: '',
      phone: '',
      tel: '',
      zipcode: '',
      address: '',
      customid1: '',
      createdtime: '',
      mem_agreedtime: '',
      brlicense_download_link: '',
      checkCompanyNameSuccess: false,
      checkCompanyNameError: false,
      checkCompanyIdSuccess: false,
      checkCompanyIdError: false,
      checkChangeBrlicense: false,
      checkMandatoryInput: false,
    };

    this._handleJoinCompanyInfo = this._handleJoinCompanyInfo.bind(this);
    this._handleSetCompanyInfo = this._handleSetCompanyInfo.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._handleBrnumberChange = this._handleBrnumberChange.bind(this);
    this._handleBrlicenseFileChange = this._handleBrlicenseFileChange.bind(this);
    this._handleOnFocus = this._handleOnFocus.bind(this);
    this._handleCheckCompanyInfoComnm = this._handleCheckCompanyInfoComnm.bind(this);
    this._handleCheckCompanyInfoComid = this._handleCheckCompanyInfoComid.bind(this);
    this._handleUploadBrlicenseFile = this._handleUploadBrlicenseFile.bind(this);
    this._handlerDownloadBrlicense = this._handlerDownloadBrlicense.bind(this);
    this._handlePostCodeClose = this._handlePostCodeClose.bind(this);
    this._handlePostCodeopen = this._handlePostCodeopen.bind(this);
    this._handleChangeSubmitButton = this._handleChangeSubmitButton.bind(this);
  }

  _handlePostCodeClose(data) {
    this.setState({
      ...this.state,
      zipcode: data.zonecode,
      address: data.address,
      postCodeOpen: false,
    });
  }

  _handlePostCodeopen() {
    this.setState({
      ...this.state,
      postCodeOpen: true,
    });
  }

  _handleJoinCompanyInfo() {
    if (this.state.compnm.length === 0) {
      return alert('회사명을 입력해야 합니다.');
    }

    if (this.state.compid.length === 0) {
      return alert('회사ID를 입력해야 합니다.');
    }

    if (this.state.joinStep === '0' && !this.state.checkCompanyNameSuccess) {
      return alert('회사명을 중복 체크해야 합니다.');
    }

    if (this.state.joinStep === '0' && !this.state.checkCompanyIdSuccess) {
      return alert('회사ID를 중복 체크해야 합니다.');
    }

    if (this.state.joinStep === '0' && this.state.deptcode.length === 0) {
      return alert('최상위 부서코드를 입력해야 합니다.');
    }

    if (this.state.adminnm.length === 0) {
      return alert('관리자 이름을 입력해야 합니다.');
    }

    if (this.state.phone.length === 0) {
      return alert('핸드폰 번호를 입력해야 합니다.');
    }

    if (this.state.zipcode && this.state.zipcode.length === 0) {
      return alert('주소를 입력해야 합니다.');
    }

    const joinCompanyInfo = {
      compnm: this.state.compnm,
      compid: this.state.compid,
      brnumber: this.state.brnumber.replace(/-/g, ''),
      deptcode: this.state.deptcode,
      adminnm: this.state.adminnm,
      phone: this.state.phone,
      tel: this.state.tel,
      zipcode: this.state.zipcode,
      address: this.state.address,
      customid1: this.state.customid1,
    };

    this.props.joinCompanyInfo(joinCompanyInfo, (error, result) => {
      this.setState({
        ...this.state,
        checkCompanyNameSuccess: false,
        checkCompanyIdSuccess: false,
      });

      if (!error) {
        this.props.getCompanyInfo();
        this.props.history.push(pathConstant.PATH_AUTH_SERVICEAPPLICATION);
      }
    });
  }

  _handleSetCompanyInfo() {
    const updateCompanyInfo = {
      brnumber: this.state.brnumber.replace(/-/g, ''),
      adminnm: this.state.adminnm,
      phone: this.state.phone,
      tel: this.state.tel,
      zipcode: this.state.zipcode,
      address: this.state.address,
      customid1: this.state.customid1,
    };

    this.props.setCompanyInfo(updateCompanyInfo, (error, result) => {
      this.setState({
        ...this.state,
        checkCompanyNameSuccess: false,
        checkCompanyIdSuccess: false,
      });

      if (!error) {
        this.props.getCompanyInfo();
      }
    });
  }

  _handleUploadBrlicenseFile() {
    if (this.state.adminnm.length === 0) {
      return alert('관리자 이름을 입력해야 합니다.');
    }

    if (this.state.phone.length === 0) {
      return alert('핸드폰 번호를 입력해야 합니다.');
    }

    if (this.state.zipcode.length === 0) {
      return alert('주소를 입력해야 합니다.');
    }

    const brlicensefile = {
      fileInfo: this.state.brlicense,
    };

    if (typeof brlicensefile.fileInfo === 'object') {
      return this.props.uploadCompandyRegistrationFile(brlicensefile, (error, result) => {
        if (!error) {
          this._handleSetCompanyInfo();
        }
      });
    }

    this._handleSetCompanyInfo();
  }

  _handlerDownloadBrlicense() {
    const brlicensefile = {
      filename: this.state.brlicense,
    };

    if (this.state.joinStep === 0 || brlicensefile.filename.length === 0 || !this.state.brlicense_download_link) {
      return;
    }

    console.log(this.state.brlicense_download_link);
  }

  _handleCheckCompanyInfoComnm() {
    if (this.state.compnm.length === 0) {
      return alert('회사명을 입력해야 합니다.');
    }

    this.setState({
      ...this.state,
      checkCompanyNameError: false,
      checkCompanyNameSuccess: false,
    });

    const checkComnm = {
      compnm: this.state.compnm,
    };

    this.props.checkCompanyInfoComnm(checkComnm, (error, result) => {
      console.log('checkCompanyInfoComnm : ', error, result);
      if (result.isused) {
        this.setState({
          ...this.state,
          checkCompanyNameError: true,
        });
      } else {
        this.setState({
          ...this.state,
          checkCompanyNameSuccess: true,
        });
      }
    });
  }

  _handleCheckCompanyInfoComid() {
    if (this.state.compid.length === 0) {
      return alert('회사ID를 입력해야 합니다.');
    }

    this.setState({
      ...this.state,
      checkCompanyIdError: false,
      checkCompanyIdSuccess: false,
    });

    const checkComid = {
      compid: this.state.compid,
    };

    this.props.checkCompanyInfoComid(checkComid, (error, result) => {
      console.log('checkCompanyInfoComid : ', error, result);
      if (result.isused) {
        this.setState({
          ...this.state,
          checkCompanyIdError: true,
        });
      } else {
        this.setState({
          ...this.state,
          checkCompanyIdSuccess: true,
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.companyInfoData !== this.props.companyInfoData) {
      const company = this.props.companyInfoData;
      this.setState({
        ...this.state,
        joinStep: company.status,
        tenantid: company.tenantid,
        compnm: company.compnm ? company.compnm : '',
        compid: company.compid ? company.compid : '',
        brnumber: company.brnumber ? company.brnumber.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3') : '',
        adminnm: company.adminnm ? company.adminnm : '',
        phone: company.phone ? company.phone : '',
        tel: company.tel ? company.tel : '',
        zipcode: company.zipcode ? company.zipcode : '',
        address: company.address ? company.address : '',
        customid1: company.customid1 ? company.customid1 : '',
        createdtime: company.createdtime,
        mem_agreedtime: company.mem_agreedtime,
        brlicense_download_link: company.brlicense_download_link,
        hasBrlicense: company.brlicense_download_link ? '1' : '0',
      });
    }
  }

  _handleOnFocus(e) {
    const { name } = e.target;
    if (name === 'compnm') {
      this.setState({
        ...this.state,
        checkCompanyNameSuccess: false,
        checkCompanyNameError: false,
      });
    }

    if (name === 'compid') {
      this.setState({
        ...this.state,
        checkCompanyIdSuccess: false,
        checkCompanyIdError: false,
      });
    }
  }

  _handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  _handleBrnumberChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3'),
    });
  }

  _handleBrlicenseFileChange(e) {
    const { name } = e.target;
    this.setState({
      [name]: e.target.files[0],
      checkChangeBrlicense: true,
    });
  }

  _handleChangeSubmitButton() {
    var checkInputDone = true;

    if (!this.state.checkCompanyNameSuccess) {
      checkInputDone = false;
    }

    if (!this.state.checkCompanyIdSuccess) {
      checkInputDone = false;
    }

    if (this.state.deptcode.length === 0) {
      checkInputDone = false;
    }

    if (this.state.adminnm.length === 0) {
      checkInputDone = false;
    }

    if (this.state.phone.length === 0) {
      checkInputDone = false;
    }

    if (this.state.zipcode && this.state.zipcode.length === 0) {
      checkInputDone = false;
    }

    this.setState({
      checkMandatoryInput: checkInputDone,
    });
  }

  render() {
    const {
      joinStep,
      compnm,
      compid,
      brnumber,
      hasBrlicense,
      deptcode,
      adminnm,
      phone,
      tel,
      zipcode,
      address,
      customid1,
      createdtime,
      mem_agreedtime,
      checkMandatoryInput,
      postCodeOpen,
      checkCompanyNameSuccess,
      checkCompanyNameError,
      checkCompanyIdSuccess,
      checkCompanyIdError,
    } = this.state;
    const { pathname } = this.props.location;
    return (
      <div className="content">
        {pathname === pathConstant.PATH_MYPAGE_COMPANYINFOMGMT && (
          <div className="inner">
            <MyPageLnbView {...this.props} />

            <div className="main_cont">
              <div className="cont_header">
                <h3 className="title">회사정보관리</h3>
              </div>
              <div className="cont_body">
                <section className="form_sect">
                  <CompanyInfoView
                    joinStep={joinStep}
                    compnm={compnm}
                    compid={compid}
                    brnumber={brnumber}
                    hasBrlicense={hasBrlicense}
                    deptcode={deptcode}
                    adminnm={adminnm}
                    phone={phone}
                    tel={tel}
                    zipcode={zipcode}
                    address={address}
                    customid1={customid1}
                    createdtime={createdtime}
                    mem_agreedtime={mem_agreedtime}
                    postCodeOpen={postCodeOpen}
                    checkCompanyNameSuccess={checkCompanyNameSuccess}
                    checkCompanyNameError={checkCompanyNameError}
                    checkCompanyIdSuccess={checkCompanyIdSuccess}
                    checkCompanyIdError={checkCompanyIdError}
                    onChange={this._handleChange}
                    onFocus={this._handleOnFocus}
                    onBlur={this._handleChangeSubmitButton}
                    onCheckCompanyInfoComnm={this._handleCheckCompanyInfoComnm}
                    onCheckCompanyInfoComid={this._handleCheckCompanyInfoComid}
                    onBrnumberChange={this._handleBrnumberChange}
                    onBrlicenseFileChange={this._handleBrlicenseFileChange}
                    onDownloadBrlicense={this._handlerDownloadBrlicense}
                    onPostCodeopen={this._handlePostCodeopen}
                    onPostCodeClose={this._handlePostCodeClose}
                  />

                  <div className="btn_area">
                    <button className="btn btn_black" onClick={this._handleUploadBrlicenseFile} style={{ display: joinStep !== '0' ? 'block' : 'none' }}>
                      저장
                    </button>
                  </div>
                </section>
              </div>
            </div>
          </div>
        )}
        {pathname === pathConstant.PATH_AUTH_COMPANYINFOMGMT && (
          <div className="inner">
            <div className="main_cont">
              <div className="login_steps">
                <div className="title">회사 기본 정보 입력</div>
                <CompanyInfoView
                  joinStep={joinStep}
                  compnm={compnm}
                  compid={compid}
                  brnumber={brnumber}
                  hasBrlicense={hasBrlicense}
                  deptcode={deptcode}
                  adminnm={adminnm}
                  phone={phone}
                  tel={tel}
                  zipcode={zipcode}
                  address={address}
                  customid1={customid1}
                  createdtime={createdtime}
                  mem_agreedtime={mem_agreedtime}
                  postCodeOpen={postCodeOpen}
                  checkCompanyNameSuccess={checkCompanyNameSuccess}
                  checkCompanyNameError={checkCompanyNameError}
                  checkCompanyIdSuccess={checkCompanyIdSuccess}
                  checkCompanyIdError={checkCompanyIdError}
                  onChange={this._handleChange}
                  onFocus={this._handleOnFocus}
                  onBlur={this._handleChangeSubmitButton}
                  onCheckCompanyInfoComnm={this._handleCheckCompanyInfoComnm}
                  onCheckCompanyInfoComid={this._handleCheckCompanyInfoComid}
                  onBrnumberChange={this._handleBrnumberChange}
                  onBrlicenseFileChange={this._handleBrlicenseFileChange}
                  onDownloadBrlicense={this._handlerDownloadBrlicense}
                  onPostCodeopen={this._handlePostCodeopen}
                  onPostCodeClose={this._handlePostCodeClose}
                />
                <div className="btn_area">
                  <button
                    type="button"
                    className={'btn btn_wide btn_y_lg ' + (checkMandatoryInput ? 'btn_cyan' : 'btn_cream')}
                    disabled={!checkMandatoryInput}
                    onClick={this._handleJoinCompanyInfo}
                  >
                    다음
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { companyInfo } = state;
  return {
    companyInfoData: companyInfo.data,
  };
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getLocalSession,
      getCompanyInfo,
      setCompanyInfo,
      joinCompanyInfo,
      checkCompanyInfoComnm,
      checkCompanyInfoComid,
      uploadCompandyRegistrationFile,
    },
    dispatch
  );

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(CompanyInfoMgmt);
export { connectedPage as CompanyInfoMgmt };
