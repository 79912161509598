import {
  FETCH_SUBSCRIPTION_REQUEST,
  FETCH_SUBSCRIPTION_SUCCESS,
  FETCH_SUBSCRIPTION_ERROR,
  FETCH_SUBSCRIPTION_LOG_LIST_REQUEST,
  FETCH_SUBSCRIPTION_LOG_LIST_SUCCESS,
  FETCH_SUBSCRIPTION_LOG_LIST_ERROR,
  FETCH_PAYMENT_LOG_LIST_REQUEST,
  FETCH_PAYMENT_LOG_LIST_SUCCESS,
  FETCH_PAYMENT_LOG_LIST_ERROR,
  FETCH_PRESETTLEMENT_REQUEST,
  FETCH_PRESETTLEMENT_SUCCESS,
  FETCH_PRESETTLEMENT_ERROR,
} from '_actions';

const initialState = {
  pending: false,
  subscriptionData: null,
  subscriptionLogListData: null,
  paymentLogListData: null,
  preSettlementData: null,
  error: null,
};

// 서비스 신청
export function subscription(state = initialState, action) {
  switch (action.type) {
    case FETCH_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        pending: false,
        subscriptionData: action.data,
      };
    case FETCH_SUBSCRIPTION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case FETCH_SUBSCRIPTION_LOG_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_SUBSCRIPTION_LOG_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        subscriptionLogListData: action.data,
      };
    case FETCH_SUBSCRIPTION_LOG_LIST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case FETCH_PAYMENT_LOG_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_PAYMENT_LOG_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        paymentLogListData: action.data,
      };
    case FETCH_PAYMENT_LOG_LIST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case FETCH_PRESETTLEMENT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_PRESETTLEMENT_SUCCESS:
      return {
        ...state,
        pending: false,
        preSettlementData: action.data,
      };
    case FETCH_PRESETTLEMENT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}
