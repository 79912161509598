import { openAlertDialog } from '_actions';
import { getLocalSession, firebaseRefreshToken } from '_services';

export function requestUrl(url) {
  // console.log("Request url : " + url);
  return url;
}
export function requestHeaders(headers) {
  const session = getLocalSession();
  return Object.assign({}, headers, {
    Authorization: session ? session.idToken : '',
  });
}
export function requestPayletterHeaders(headers) {
  return headers;
}
export function requestBody(body) {
  //console.log("Request body : " + JSON.stringify(body));
  return JSON.stringify(body);
}
export function requestParam(params) {
  // console.log("Request param : " + JSON.stringify(params));
  const urlParams = new URLSearchParams(params);
  return urlParams;
}

export function requestFileBody(body) {
  const formData = new FormData();
  formData.append('brlicense', body.fileInfo);
  return formData;
}

export function handleResponse(response) {
  return response.text().then((text) => {
    if (!response.ok) {
      const error = `HTTP Error : [${response.status}] ${response.statusText}`;
      console.log(error);
      console.log('Response error : ' + text);

      if (response.status === 401) {
        firebaseRefreshToken();
      } else if (response.status === 500) {
        const error = text && JSON.parse(text);
        if (error.error === 'ERROR_USER_COUNT_EXCEEDED') {
          window.fetch.dispatch(openAlertDialog('사용인원이 조직도에 등록된 사용자보다 적습니다.<br/>조직도를 먼저 수정하세요'));
        } else {
          window.fetch.dispatch(openAlertDialog('관리자에게 문의하시기 바랍니다.'));
        }
        return Promise.reject(true);
      } else {
        try {
          // text = escapeSpecialChars(text);
          // const data = text && JSON.parse(text);
          window.fetch.dispatch(openAlertDialog('관리자에게 문의하시기 바랍니다.'));
          return Promise.reject(true);
        } catch (e) {
          // server shutdown, proxy 등의 오류
          window.fetch.dispatch(openAlertDialog('관리자에게 문의하시기 바랍니다.'));
          return Promise.reject(true);
        }
      }
    } else {
      // console.log("Response result : " + text);
      text = escapeSpecialChars(text);
      const data = text && JSON.parse(text);
      if (data) {
        return data;
      } else {
        return '';
      }
    }
  });
}

export function handlePayletterResponse(response) {
  return response.text().then((text) => {
    if (!response.ok) {
      const error = `HTTP Error : [${response.status}] ${response.statusText}`;
      console.log(error);
      console.log('Response error : ' + text);

      try {
        window.fetch.dispatch(openAlertDialog('관리자에게 문의하시기 바랍니다.'));
        return Promise.reject(text);
      } catch (e) {
        // server shutdown, proxy 등의 오류
        window.fetch.dispatch(openAlertDialog('관리자에게 문의하시기 바랍니다.'));
        return Promise.reject(text);
      }
    } else {
      console.log('Response result : ' + text);
      text = escapeSpecialChars(text);
      const data = text && JSON.parse(text);
      return data;
    }
  });
}

export function handleDownloadResponse(response) {
  return response.blob().then((blob) => {
    if (!response.ok) {
      const error = `HTTP Error : [${response.status}] ${response.statusText}`;
      console.log(error);

      try {
        window.fetch.dispatch(openAlertDialog('관리자에게 문의하시기 바랍니다.'));
        return Promise.reject('download error');
      } catch (e) {
        // server shutdown, proxy 등의 오류
        window.fetch.dispatch(openAlertDialog('관리자에게 문의하시기 바랍니다.'));
        return Promise.reject('download error');
      }
    } else {
      console.log('Response result is blob');
      return blob;
    }
  });
}

var escapeSpecialChars = function (value) {
  return value.replace(/\\n/g, '<br/>'); //
  //.replace(/\"/g, '\\"')
  //.replace(/\&/g, "\\&")
  //.replace(/\r/g, "\\r")
  //.replace(/\t/g, "\\t")
  //.replace(/\b/g, "\\b")
  //.replace(/\f/g, "\\f");
};
