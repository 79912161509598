import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { routes } from '_helpers';
import { pathConstant } from '_constants';
import { Header, Footer, FloatingBar, ScrollToTop } from '_components';

export class AppLayout extends Component {
  render() {
    const { pathname } = this.props.location;
    const showFloatingBar =
      pathname === pathConstant.PATH_HOME ||
      pathname === pathConstant.PATH_CUSTOMERSERVICE_NOTICE ||
      pathname === pathConstant.PATH_CUSTOMERSERVICE_QNA ||
      pathname === pathConstant.PATH_CUSTOMERSERVICE_FAQ ||
      pathname === pathConstant.PATH_GUIDE_MAINFUNCTION ||
      pathname === pathConstant.PATH_GUIDE_COSTGUIDE ||
      pathname === pathConstant.PATH_HOME_TERMSOFUSE ||
      pathname === pathConstant.PATH_HOME_PRIVACYPOLISH
        ? true
        : false;

    const params = new URLSearchParams(window.location.search);
    const isViewer = params.get('type') === 'viewer';

    return (
      <>
        <ScrollToTop {...this.props} />
        {isViewer ? '' : <Header {...this.props} />}
        <Switch>
          {routes.map((route, idx) => {
            return route.component ? (
              <Route key={idx} path={route.path} exact={route.exact} render={(props) => <route.component {...props} cookies={this.props.cookies} />} />
            ) : null;
          })}
        </Switch>
        {isViewer ? '' : <Footer {...this.props} />}
        {isViewer ? '' : showFloatingBar && <FloatingBar {...this.props} />}
      </>
    );
  }
}
