export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export function loginRequest() {
  return { type: LOGIN_REQUEST };
}
export function loginSuccess(data) {
  return { type: LOGIN_SUCCESS, data };
}
export function loginError(error) {
  return { type: LOGIN_ERROR, error };
}
