import React, { Component } from 'react';
import { pathConstant } from '_constants';
import { getLocalSession } from '_services';

export class GuideFooterView extends Component {
  constructor(props) {
    super(props);

    this._handleSupportMail = this._handleSupportMail.bind(this);
    this._handleJoin = this._handleJoin.bind(this);
  }
  _handleSupportMail() {
    this.props.history.push(pathConstant.PATH_HOME_SUPPORTMAIL);
  }
  _handleJoin() {
    const session = getLocalSession();
    if (session) {
      if (session.status === '0') {
        this.props.history.push(pathConstant.PATH_AUTH_COMPANYINFOMGMT);
      } else if (session.status === '2') {
        this.props.history.push(pathConstant.PATH_AUTH_SERVICEAPPLICATION);
      } else {
        this.props.history.push(pathConstant.PATH_MYPAGE_MYPAGE);
      }
    } else {
      this.props.history.push(pathConstant.PATH_AUTH_SIGNUP);
    }
  }
  render() {
    return (
      <section className="bottom_sect">
        <div className="inner">
          <div className="desc_area">
            <p className="title">
              <span>52시간 근태관리, </span>
              <span>아이브웍스와 함께하세요!</span>
            </p>
            <div className="btn_area">
              <button type="button" className="btn btn_reverse" onClick={this._handleSupportMail}>
                도입문의
              </button>
              <button type="button" className="btn btn_reverse" onClick={this._handleJoin}>
                무료체험 시작하기
              </button>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
