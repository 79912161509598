import React, { Component } from 'react';
import { PostCodeModal } from '_containers';

export class CompanyInfoView extends Component {
  render() {
    const {
      joinStep,
      compnm,
      compid,
      brnumber,
      hasBrlicense,
      deptcode,
      adminnm,
      phone,
      tel,
      zipcode,
      address,
      customid1,
      createdtime,
      mem_agreedtime,
      postCodeOpen,
      checkCompanyNameSuccess,
      checkCompanyNameError,
      checkCompanyIdSuccess,
      checkCompanyIdError,
      onChange,
      onFocus,
      onBlur,
      onCheckCompanyInfoComnm,
      onCheckCompanyInfoComid,
      onBrnumberChange,
      onBrlicenseFileChange,
      onDownloadBrlicense,
      onPostCodeopen,
      onPostCodeClose,
    } = this.props;
    return (
      <form className="form_area" action="/" method="POST">
        <div className="form-group">
          <label htmlFor="" className="asterisk">
            회사명
          </label>
          <div className="input-group">
            {joinStep === '0' && (
              <input
                type="text"
                id="compnm"
                name="compnm"
                className="form-control"
                placeholder="회사명을 입력해 주세요."
                value={compnm}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
              />
            )}
            {joinStep !== '0' && (
              <input type="text" id="compnm" name="compnm" className="form-control" placeholder="회사명을 입력해 주세요." defaultValue={compnm} readOnly />
            )}
            {joinStep === '0' && (
              <span className="input-group-btn">
                <button type="button" className="btn" onClick={onCheckCompanyInfoComnm}>
                  중복확인
                </button>
              </span>
            )}
          </div>
          {checkCompanyNameError && (
            <div className="message_box">
              <p className="message alert">입력한 회사명은 이미 사용 중입니다.</p>
            </div>
          )}
          {checkCompanyNameSuccess && (
            <div className="message_box">
              <p className="message">- 입력한 회사명은 사용이 가능합니다.</p>
            </div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="" className="asterisk">
            회사ID
          </label>
          <div className="input-group">
            {joinStep === '0' && (
              <input
                type="text"
                id="compid"
                name="compid"
                className="form-control"
                placeholder="회사 ID를 입력해 주세요."
                value={compid}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
              />
            )}
            {joinStep !== '0' && (
              <input type="text" id="compid" name="compid" className="form-control" placeholder="회사 ID를 입력해 주세요." defaultValue={compid} readOnly />
            )}
            {joinStep === '0' && (
              <span className="input-group-btn">
                <button type="button" className="btn" onClick={onCheckCompanyInfoComid}>
                  중복확인
                </button>
              </span>
            )}
          </div>
          <p className="message">※ 영문/숫자로만 입력해 주시기 바랍니다.</p>
          {checkCompanyIdError && (
            <div className="message_box">
              <p className="message alert">입력한 회사ID는 이미 사용 중입니다.</p>
            </div>
          )}
          {checkCompanyIdSuccess && (
            <div className="message_box">
              <p className="message">- 입력한 회사ID는 사용이 가능합니다.</p>
            </div>
          )}
        </div>
        <div className="form-group" style={{ display: joinStep !== '0' ? 'block' : 'none' }}>
          <label htmlFor="">사업자등록번호</label>
          <div className="input-group corp_regi_num">
            <input
              type="text"
              id="brnumber"
              name="brnumber"
              className="form-control"
              maxLength="10"
              placeholder="000-00-00000"
              value={brnumber}
              onChange={onBrnumberChange}
            />
          </div>
        </div>
        <div className="form-group" style={{ display: joinStep !== '0' ? 'block' : 'none' }}>
          <label htmlFor="">증빙서류</label>
          <div className="text_area">
            <div className="main_text">신청 정보를 증명할 수 있는 사업자등록증 사본을 제출해 주세요</div>
            <div className="sub_text">
              일반과세자(개인사업자)의 경우, 사업자등록증에 주민등록번호가 포함되어 있으면 뒷자리 7자리를 음영처리(masking)하여 제출 바랍니다. 첨부파일은
              육안으로 식별 가능해야 하며, 서류 검토 후 문제가 있을 경우 서비스 이용에 제한을 받을 수 있습니다.
            </div>
          </div>
          <div className="custom_file">
            <label>
              <input type="file" className="file_input" id="brlicense" name="brlicense" accept="image/*,.tiff,.tif,.pdf" onChange={onBrlicenseFileChange} />
              <span className="btn file_btn">파일첨부</span>
            </label>
            {hasBrlicense === '0' && <span className="file_name">파일을 선택해주세요.</span>}
            {hasBrlicense === '1' && (
              <span className="file_name" onClick={onDownloadBrlicense}>
                사업자등록증.pdf
              </span>
            )}
          </div>
          {hasBrlicense === '0' && (
            <p className="message">※ 5MB 이내의 허용된 확장자 파일만 첨부할 수 있습니다. (jpg, png, jpeg, bmp, tiff ,tif, pdf만 가능)</p>
          )}
        </div>
        <div className="form-group">
          {joinStep === '0' && (
            <label htmlFor="" className="asterisk">
              최상위 부서코드
            </label>
          )}
          {joinStep === '0' && (
            <input
              type="text"
              id="deptcode"
              name="deptcode"
              className="form-control"
              placeholder="부서코드를 입력해 주세요."
              value={deptcode}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
          {joinStep === '0' && <p className="message">※ 영문/숫자로만 입력해 주시기 바랍니다.</p>}
        </div>
        <div className="form-group">
          <label htmlFor="" className="asterisk">
            관리자 이름
          </label>
          <input
            type="text"
            id="adminnm"
            name="adminnm"
            className="form-control"
            placeholder="이름을 입력해 주세요."
            value={adminnm}
            onChange={onChange}
            onBlur={onBlur}
          />
        </div>
        <div className="form-group">
          <label htmlFor="" className="asterisk">
            핸드폰 번호
          </label>
          <input
            type="number"
            id="phone"
            name="phone"
            className="form-control"
            placeholder="핸드폰 번호를 입력해 주세요."
            value={phone}
            onChange={onChange}
            onBlur={onBlur}
          />
        </div>
        <div className="form-group">
          <label htmlFor="">전화번호</label>
          <input type="number" id="tel" name="tel" className="form-control" placeholder="전화번호를 입력해 주세요." value={tel} onChange={onChange} />
        </div>
        <div className="form-group">
          <label htmlFor="" className="asterisk">
            주소
          </label>
          <div className="input-group">
            <input
              type="number"
              name="zipcode"
              className="form-control zip_code"
              placeholder="우편번호"
              value={zipcode}
              onClick={onPostCodeopen}
              onChange={onChange}
              onBlur={onBlur}
            />
            <input type="text" name="address" className="form-control" placeholder="상세 주소를 입력해 주세요." value={address} onChange={onChange} />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="">상세주소</label>
          <input
            type="text"
            id="customid1"
            name="customid1"
            className="form-control"
            placeholder="상세 주소를 입력해 주세요."
            value={customid1}
            onChange={onChange}
          />
        </div>
        <div className="flex_area">
          <div className="form-group" style={{ display: joinStep !== '0' ? 'block' : 'none' }}>
            <label htmlFor="">회원 가입 일시</label>
            <input type="text" id="createdtime" name="createdtime" className="form-control" defaultValue={createdtime} readOnly />
          </div>
          <div className="form-group" style={{ display: joinStep !== '0' ? 'block' : 'none' }}>
            <label htmlFor="">사용 약관 동의 일시</label>
            <input type="text" id="mem_agreedtime" name="mem_agreedtime" className="form-control" defaultValue={mem_agreedtime} readOnly />
          </div>
        </div>
        <PostCodeModal open={postCodeOpen} onClose={onPostCodeClose} />
      </form>
    );
  }
}
