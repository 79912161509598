/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { MyPageLnbView, ServiceApplicationModal, UsingServiceView, ServiceHistoryView, IntroduceGoAdminModal } from '_containers';
import { getLocalSession, fetchSubscription, fetchSubscriptionLogList, paymentRequest, getCompanyInfo, goAdmin } from '_services';
import { loadingStart, loadingStop } from '_actions';
import { commonConstant } from '_constants';

class MyPage extends Component {
  constructor(props) {
    super(props);
    const session = getLocalSession();
    this.state = {
      // status
      // 0: 회원가입 완료 = 회사정보 입력 대기
      // 2: 회사정보 입력 완료 = 서비스 신청 대기
      // 3: 결재 완료 = 서비스 신청 완료
      joinStep: session.status,
      tabIndex: 0,
      serviceApplicationOpen: false,
      introduceGoAdminOpen: false,
    };

    this._handleServiceApplicationOpen = this._handleServiceApplicationOpen.bind(this);
    this._handleServiceApplicationClose = this._handleServiceApplicationClose.bind(this);
    this._handleIntroduceGoAdminClose = this._handleIntroduceGoAdminClose.bind(this);
  }
  componentDidMount() {
    const { joinStep } = this.state;
    const { showGoAdmin } = this.props.location.query ? this.props.location.query : false;

    if (joinStep === '3') {
      this._loadData();
    }

    if (showGoAdmin) {
      this.setState({
        ...this.state,
        introduceGoAdminOpen: true,
      });
    }
  }
  _loadData() {
    this.props.fetchSubscription({}, (error, data) => {
      if (!error) {
        this.props.fetchSubscriptionLogList();
      }
    });
  }
  _handleServiceApplicationOpen(updateTarget) {
    const { subscriptionData } = this.props;
    if (updateTarget === 'C' && subscriptionData.billkey === '') {
      this._payletterAuthCard();
    } else {
      this.setState({
        ...this.state,
        serviceApplicationOpen: true,
        updateTarget,
      });
    }
  }
  _payletterAuthCard() {
    const { userCount, unitPrice, joinDate } = this.state;
    const session = getLocalSession();
    const serviceName = 'AIBWorks';
    const productName = 'AIBWorks BASIC';

    const custom_parameter = {
      tenantid: session.tenantid,
      servicename: serviceName,
      usercount: userCount,
      usagefee: unitPrice * userCount,
      joindate: joinDate,
      trialenddate: moment(joinDate, 'YYYY-MM-DD').add(commonConstant.ADDITIONAL_TRIALDAYS, 'days').format('YYYY-MM-DD'),
      updateyn: 'N',
      origin: document.location.origin,
    };

    const returnUrl = `${process.env.REACT_APP_PAYLETTER_RETURN_URL}/payment-card`;

    const param = {
      pgcode: 'creditcard',
      client_id: 'aiblab',
      service_name: serviceName,
      user_id: session.compid,
      user_name: session.compnm,
      order_no: uuidv4(),
      amount: 0,
      tax_amount: 0,
      product_name: productName,
      email_flag: 'Y',
      email_addr: 'admin@aiblab.co.kr',
      autopay_flag: 'Y',
      custom_parameter: JSON.stringify(custom_parameter),
      callback_url: returnUrl,
      return_url: returnUrl,
    };
    this.props.paymentRequest(param, (error, data) => {
      if (!error) {
        let receiveMessage = (event) => {
          if (event.data === 'payment-success') {
            this.props.getCompanyInfo({}, () => {
              window.location.reload();
            });
          } else if (event.data === 'payment-fail') {
            //this.props.dispatch(openAlertDialog("관리자에게 문의하시기 바랍니다."));
          }
        };
        window.addEventListener('message', receiveMessage, false);

        setTimeout(() => {
          window.fetch.dispatch(loadingStart());
        }, 150);
        let payletterPopup = window.open(data.online_url, '인증', 'width=400, height=580, menubar=no, status=no, toolbar=no, resizable=yes');
        let checkPopupClosed = setInterval(() => {
          if (payletterPopup.closed) {
            clearInterval(checkPopupClosed);
            window.fetch.dispatch(loadingStop());
          }
        }, 1000);
      }
    });
  }
  _handleServiceApplicationClose() {
    this.setState({
      ...this.state,
      serviceApplicationOpen: false,
    });
  }
  _handleIntroduceGoAdminClose(go) {
    this.setState({
      ...this.state,
      introduceGoAdminOpen: false,
    });
    if (go) {
      goAdmin();
    }
  }
  render() {
    const { joinStep, tabIndex, serviceApplicationOpen, updateTarget, introduceGoAdminOpen } = this.state;
    const { subscriptionData, subscriptionLogListData } = this.props;
    const session = getLocalSession();
    return (
      <div className="content">
        <div className="inner">
          <MyPageLnbView {...this.props} />

          <div className="main_cont my_page">
            <div className="cont_header">
              <h3 className="title">마이페이지</h3>
              <p className="desc">
                아이브웍스 서비스의 현황을 마이페이지에서 한번에 확인 할 수 있습니다. <br />
                회원가입 후 기업인증이 완료되면 서비스 신청을 진행해 주세요.
              </p>
            </div>
            <div className="summary_box">
              <div className="summary">
                <p className="corp_info">
                  <span className="name">{session && session.compnm}</span>
                  {(joinStep === '0' || joinStep === '1') && <span className="value uncertify">미인증</span>}
                  {(joinStep === '2' || joinStep === '3') && <span className="value">인증완료</span>}
                </p>
                <p className="admin_info">
                  <span className="name">관리자</span>
                  <span className="value">{session && session.adminnm}</span>
                </p>
                <p className="service_info">
                  <span className="name">{subscriptionData ? subscriptionData.productname : ''}</span>
                  <span className="value">무료체험중</span>
                </p>
              </div>
            </div>
            <div className="cont_body">
              {(joinStep === '0' || joinStep === '1') && (
                <section className="steps_sect">
                  <div className="text_area">
                    <div className="main_text uncertify">증빙서류를 검토중입니다.</div>
                    <div className="sub_text">
                      <p>
                        기업인증이 완료되면 서비스 신청을 할 수 있습니다. <br />
                        <span className="highlight">기업인증 완료 후 관리자 이메일</span>로 안내드립니다.
                      </p>
                    </div>
                  </div>
                </section>
              )}
              {joinStep === '2' && (
                <section className="steps_sect">
                  <div className="text_area">
                    <div className="main_text certify">서비스를 신청하세요.</div>
                    <div className="sub_text">
                      <p>
                        <span className="highlight">서비스 신청</span>
                        을 진행하시면 <br />
                        아이브웍스 서비스를 사용하실 수 있습니다.
                      </p>
                    </div>
                  </div>
                  <div className="btn_area">
                    <button type="button" className="btn btn_y_lg btn_cyan" onClick={() => this._handleServiceApplicationOpen(false)}>
                      서비스 신청
                    </button>
                  </div>
                </section>
              )}
              {joinStep === '3' && (
                <section className="tab_sect">
                  <ul className="nav nav-pills">
                    <li className="nav-item">
                      <button
                        type="button"
                        className={'nav-link ' + (tabIndex === 0 ? 'active' : '')}
                        id="usingServiceTab"
                        data-bs-toggle="pill"
                        data-bs-target="#usingService"
                        onClick={() => {
                          this.setState({
                            ...this.state,
                            tabIndex: 0,
                          });
                        }}
                      >
                        이용중인 서비스
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        type="button"
                        className={'nav-link ' + (tabIndex === 1 ? 'active' : '')}
                        id="serviceHistoryTab"
                        data-bs-toggle="pill"
                        data-bs-target="#serviceHistory"
                        onClick={() => {
                          this.setState({
                            ...this.state,
                            tabIndex: 1,
                          });
                        }}
                      >
                        서비스 이력
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div className={'tab-pane fade ' + (tabIndex === 0 ? 'show active' : '')} id="usingService" aria-labelledby="usingServiceTab">
                      <UsingServiceView data={subscriptionData} />
                      <div className="btn_area">
                        <button type="button" className="btn btn_y_md" onClick={() => this._handleServiceApplicationOpen('P')}>
                          사용인원 변경
                        </button>
                        <button type="button" className="btn btn_y_md" onClick={() => this._handleServiceApplicationOpen('C')}>
                          자동 결제카드 등록/변경
                        </button>
                      </div>
                    </div>

                    <div className={'tab-pane fade ' + (tabIndex === 1 ? 'show active' : '')} id="serviceHistory" aria-labelledby="serviceHistoryTab">
                      <ServiceHistoryView data={subscriptionLogListData} serviceName={subscriptionData ? subscriptionData.servicename : ''} />
                    </div>
                  </div>
                </section>
              )}
            </div>
          </div>
        </div>
        <ServiceApplicationModal
          open={serviceApplicationOpen}
          onClose={this._handleServiceApplicationClose}
          updateTarget={updateTarget}
          data={subscriptionData}
        />
        <IntroduceGoAdminModal
          open={introduceGoAdminOpen}
          onClose={() => {
            this._handleIntroduceGoAdminClose(false);
          }}
          onGoAdmin={() => {
            this._handleIntroduceGoAdminClose(true);
          }}
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { subscription } = state;
  return {
    subscriptionData: subscription.subscriptionData,
    subscriptionLogListData: subscription.subscriptionLogListData,
  };
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSubscription,
      fetchSubscriptionLogList,
      paymentRequest,
      getCompanyInfo,
      dispatch,
    },
    dispatch
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(MyPage);
export { connected as MyPage };
