import {
  Home,
  SupportMail,
  Terms,
  Login,
  SignUp,
  EmailVerification,
  ServiceApplication,
  MyPage,
  PaymentHistory,
  CompanyInfoMgmt,
  ServiceLeave,
  Notice,
  NoticeDetail,
  QnA,
  QnADetail,
  MainFunction,
  CostGuide,
  FAQ,
  FAQDetail,
} from '_containers';
import { pathConstant } from '_constants';

export const routes = [
  {
    path: pathConstant.PATH_HOME,
    name: 'Home',
    component: Home,
    exact: true,
  },
  {
    path: pathConstant.PATH_HOME_SUPPORTMAIL,
    name: 'SupportMail',
    component: SupportMail,
    exact: true,
  },
  {
    path: pathConstant.PATH_HOME_TERMSOFUSE,
    name: 'Terms',
    component: Terms,
    exact: true,
  },
  {
    path: pathConstant.PATH_HOME_PRIVACYPOLISH,
    name: 'Terms',
    component: Terms,
    exact: true,
  },
  {
    path: pathConstant.PATH_AUTH_LOGIN,
    name: 'Login',
    component: Login,
    exact: true,
  },
  {
    path: pathConstant.PATH_AUTH_SIGNUP,
    name: 'SignUp',
    component: SignUp,
    exact: true,
  },
  {
    path: pathConstant.PATH_AUTH_COMPANYINFOMGMT,
    name: 'CompanyInfoMgmt',
    component: CompanyInfoMgmt,
    exact: true,
  },
  {
    path: pathConstant.PATH_AUTH_EMAILVERIFICATION,
    name: 'EmailVerification',
    component: EmailVerification,
    exact: true,
  },
  {
    path: pathConstant.PATH_AUTH_SERVICEAPPLICATION,
    name: 'ServiceApplication',
    component: ServiceApplication,
    exact: true,
  },
  {
    path: pathConstant.PATH_MYPAGE_MYPAGE,
    name: 'MyPage',
    component: MyPage,
    exact: true,
  },
  {
    path: pathConstant.PATH_MYPAGE_PAYMENTHISTORY,
    name: 'PaymentHistory',
    component: PaymentHistory,
    exact: true,
  },
  {
    path: pathConstant.PATH_MYPAGE_COMPANYINFOMGMT,
    name: 'CompanyInfoMgmt',
    component: CompanyInfoMgmt,
    exact: true,
  },
  {
    path: pathConstant.PATH_MYPAGE_SERVICELEAVE,
    name: 'ServiceLeave',
    component: ServiceLeave,
    exact: true,
  },
  {
    path: pathConstant.PATH_CUSTOMERSERVICE_NOTICE,
    name: 'Notice',
    component: Notice,
    exact: true,
  },
  {
    path: pathConstant.PATH_CUSTOMERSERVICE_NOTICE_DETAIL,
    name: 'NoticeDetail',
    component: NoticeDetail,
    exact: true,
  },
  {
    path: pathConstant.PATH_CUSTOMERSERVICE_QNA,
    name: 'QnA',
    component: QnA,
    exact: true,
  },
  {
    path: pathConstant.PATH_CUSTOMERSERVICE_QNA_DETAIL,
    name: 'QnADetail',
    component: QnADetail,
    exact: true,
  },
  {
    path: pathConstant.PATH_CUSTOMERSERVICE_FAQ,
    name: 'FAQ',
    component: FAQ,
    exact: true,
  },
  {
    path: pathConstant.PATH_CUSTOMERSERVICE_FAQ_DETAIL,
    name: 'FAQDetail',
    component: FAQDetail,
    exact: true,
  },
  {
    path: pathConstant.PATH_GUIDE_MAINFUNCTION,
    name: 'MainFunction',
    component: MainFunction,
    exact: true,
  },
  {
    path: pathConstant.PATH_GUIDE_COSTGUIDE,
    name: 'CostGuide',
    component: CostGuide,
    exact: true,
  },
];
