export const GET_COMPANYINFO_REQUEST = 'GET_COMPANYINFO_REQUEST';
export const GET_COMPANYINFO_SUCCESS = 'GET_COMPANYINFO_SUCCESS';
export const GET_COMPANYINFO_ERROR = 'GET_COMPANYINFO_ERROR';

export const SET_COMPANYINFO_REQUEST = 'SET_COMPANYINFO_REQUEST';
export const SET_COMPANYINFO_SUCCESS = 'SET_COMPANYINFO_SUCCESS';
export const SET_COMPANYINFO_ERROR = 'SET_COMPANYINFO_ERROR';

export const JOIN_COMPANYINFO_REQUEST = 'JOIN_COMPANYINFO_REQUEST';
export const JOIN_COMPANYINFO_SUCCESS = 'JOIN_COMPANYINFO_SUCCESS';
export const JOIN_COMPANYINFO_ERROR = 'JOIN_COMPANYINFO_ERROR';

export const CHECK_COMPANYINFO_COMNM_REQUEST = 'CHECK_COMPANYINFO_COMNM_REQUEST';
export const CHECK_COMPANYINFO_COMNM_SUCCESS = 'CHECK_COMPANYINFO_COMNM_SUCCESS';
export const CHECK_COMPANYINFO_COMNM_ERROR = 'CHECK_COMPANYINFO_COMNM_ERROR';

export const UPLOAD_COMPANYINFO_REGFILE_REQUEST = 'UPLOAD_COMPANYINFO_REGFILE_REQUEST';
export const UPLOAD_COMPANYINFO_REGFILE_SUCCESS = 'UPLOAD_COMPANYINFO_REGFILE_SUCCESS';
export const UPLOAD_COMPANYINFO_REGFILE_ERROR = 'UPLOAD_COMPANYINFO_REGFILE_ERROR';

export function getCompanyInfoRequest() {
  return { type: GET_COMPANYINFO_REQUEST };
}
export function getCompanyInfoSuccess(data) {
  return { type: GET_COMPANYINFO_SUCCESS, data };
}
export function getCompanyInfoError(error) {
  return { type: GET_COMPANYINFO_ERROR, error };
}

export function setCompanyInfoRequest() {
  return { type: SET_COMPANYINFO_REQUEST };
}
export function setCompanyInfoSuccess(data) {
  return { type: SET_COMPANYINFO_SUCCESS, data };
}
export function setCompanyInfoError(error) {
  return { type: SET_COMPANYINFO_ERROR, error };
}

export function joinCompanyInfoRequest() {
  return { type: JOIN_COMPANYINFO_REQUEST };
}

export function joinCompanyInfoSuccess(data) {
  return { type: JOIN_COMPANYINFO_SUCCESS, data };
}

export function joinCompanyInfoError(error) {
  return { type: JOIN_COMPANYINFO_ERROR, error };
}

export function checkCompanyInfoComnmRequest() {
  return { type: CHECK_COMPANYINFO_COMNM_REQUEST };
}
export function checkCompanyInfoComnmSuccess(data) {
  return { type: CHECK_COMPANYINFO_COMNM_SUCCESS, data };
}
export function checkCompanyInfoComnmError(error) {
  return { type: CHECK_COMPANYINFO_COMNM_ERROR, error };
}

export function checkCompanyInfoComidRequest() {
  return { type: CHECK_COMPANYINFO_COMNM_REQUEST };
}
export function checkCompanyInfoComidSuccess(data) {
  return { type: CHECK_COMPANYINFO_COMNM_SUCCESS, data };
}
export function checkCompanyInfoComidError(error) {
  return { type: CHECK_COMPANYINFO_COMNM_ERROR, error };
}

export function uploadCompandyRegistrationFileRequest() {
  return { type: UPLOAD_COMPANYINFO_REGFILE_REQUEST };
}
export function uploadCompandyRegistrationFileSuccess(data) {
  return { type: UPLOAD_COMPANYINFO_REGFILE_SUCCESS, data };
}
export function uploadCompandyRegistrationFileError(error) {
  return { type: UPLOAD_COMPANYINFO_REGFILE_ERROR, error };
}
