import React, { Component } from 'react';
import { commonUtils } from '_helpers';
import IonRangeSlider from 'react-ion-slider';

export class UserCountRangeSlider extends Component {
  render() {
    const { userCount, unitPrice, onChange } = this.props;
    return (
      <div className="form-group">
        <div className="range_group">
          <div className="range_input">
            <IonRangeSlider ref={(r) => (this.ionSlider = r)} grid={true} grid_num={12} min={0} max={300} step={1} from={userCount} onChange={onChange} />
          </div>
          <span className="range_text">{userCount}명</span>
        </div>
        <div className="payment">
          <p className="total">
            월 이용요금 <span className="monthly_price">{commonUtils.numberWithCommas(unitPrice * userCount)} 원</span>
          </p>
          <p className="calculation">
            {commonUtils.numberWithCommas(unitPrice)}원/월 x <span className="people_num">{userCount}명</span> (부가세 별도)
          </p>
        </div>
      </div>
    );
  }
}
