import { requestUrl, requestHeaders, requestParam, handleDownloadResponse, handleResponse } from '_helpers';
import {
  exportOrganizationRequest,
  exportOrganizationSuccess,
  exportOrganizationError,
  exportAttendancesRequest,
  exportAttendancesSuccess,
  exportAttendancesError,
  requestWithdrawalRequest,
  requestWithdrawalSuccess,
  requestWithdrawalError,
} from '_actions';
import { getLocalSession } from '_services';

// 조직도 데이터 백업
export function exportOrganization(params = {}, callback = (error, result) => {}) {
  return (dispatch) => {
    const url = requestUrl(`${process.env.REACT_APP_BACKUP_APIURL}/tenant-exports/organization?${requestParam(params)}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(exportOrganizationRequest());
    return fetch(url, requestOptions)
      .then((response) => handleDownloadResponse(response))
      .then((blob) => {
        const session = getLocalSession();
        const filename = (session ? session.compid : 'unknown') + '_organiztion.zip';
        download(blob, filename);
        callback();
        dispatch(exportOrganizationSuccess());
      })
      .catch((error) => {
        callback(error);
        dispatch(exportOrganizationError(error));
      });
  };
}

// 근태정보 데이터 백업
export function exportAttendances(params = {}, callback = (error, result) => {}) {
  return (dispatch) => {
    const url = requestUrl(`${process.env.REACT_APP_BACKUP_APIURL}/tenant-exports/attendances?${requestParam(params)}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(exportAttendancesRequest());
    return fetch(url, requestOptions)
      .then((response) => handleDownloadResponse(response))
      .then((blob) => {
        const session = getLocalSession();
        const filename = (session ? session.compid : 'unknown') + '_attendances.zip';
        download(blob, filename);
        callback();
        dispatch(exportAttendancesSuccess());
      })
      .catch((error) => {
        callback(error);
        dispatch(exportAttendancesError(error));
      });
  };
}

// 회원탈퇴
export function requestWithdrawal(params = {}, callback = (error, result) => {}) {
  return (dispatch) => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/admin-portal/tenants/request-withdrawal`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'POST',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(requestWithdrawalRequest());
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        callback(null, response.data);
        dispatch(requestWithdrawalSuccess(response.data));
      })
      .catch((error) => {
        callback(error);
        dispatch(requestWithdrawalError(error));
      });
  };
}

function download(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    var file = window.URL.createObjectURL(blob);
    var elem = window.document.createElement('a');
    elem.href = file;
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
}
