import { requestUrl, requestHeaders, handleResponse, requestParam, requestBody } from '_helpers';
import { checkEmailRequest, checkEmailSuccess, checkEmailError, preJoinRequest, preJoinSuccess, preJoinError } from '_actions';

// 이메일 중복체크
export function checkEmail(params = {}, callback = (error, result) => {}) {
  return (dispatch) => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/common-apis/check-email?${requestParam(params)}`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(checkEmailRequest());
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        callback(null, response.data);
        dispatch(checkEmailSuccess(response.data));
      })
      .catch((error) => {
        callback(error);
        dispatch(checkEmailError(error));
      });
  };
}

// 사전가입
export function preJoin(params = {}, callback = (error, result) => {}) {
  return (dispatch) => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/common-apis/pre-join`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'POST',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(preJoinRequest());
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        callback(null, response.data);
        dispatch(preJoinSuccess(response.data));
      })
      .catch((error) => {
        callback(error);
        dispatch(preJoinError(error));
      });
  };
}
