/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sendSupportMail } from '_services';
import { openAlertDialog } from '_actions';
import { TermsModal } from '_containers';

class SupportMail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companyName: '',
      chargerName: '',
      chargerContact: '',
      chargerEmail: '',
      companySize: '',
      companyType: '',
      requireContent: '',
      termsAgree: false,
      termsOpen: false,
      terms: '',
    };

    this._handleChange = this._handleChange.bind(this);
    this._toggleTermsAgree = this._toggleTermsAgree.bind(this);
    this._handleTermsOpen = this._handleTermsOpen.bind(this);
    this._handleTermsClose = this._handleTermsClose.bind(this);
    this._handleSave = this._handleSave.bind(this);
  }

  _handleChange(e) {
    const { id, value } = e.target;
    this.setState({ ...this.state, [id]: value });
  }

  _toggleTermsAgree() {
    this.setState({
      ...this.state,
      termsAgree: !this.state.termsAgree,
    });
  }

  _handleTermsOpen(e, terms) {
    e.preventDefault();

    this.setState({
      ...this.state,
      termsOpen: true,
      terms,
    });
  }

  _handleTermsClose() {
    this.setState({
      ...this.state,
      termsOpen: false,
    });
  }

  _handleSave() {
    const { companyName, chargerName, chargerContact, chargerEmail, companySize, companyType, requireContent } = this.state;
    const params = {
      companyName,
      chargerName,
      chargerContact,
      chargerEmail,
      companySize,
      companyType,
      requireContent,
    };
    this.props.sendSupportMail(params, (error, data) => {
      if (!error) {
        this.props.dispatch(
          openAlertDialog('발송되었습니다.', false, null, null, () => {
            this.props.history.goBack();
          })
        );
      }
    });
  }

  render() {
    const { companyName, chargerName, chargerContact, chargerEmail, companySize, companyType, requireContent, termsAgree, termsOpen, terms } = this.state;
    document.title = '도입문의 | 아이브웍스';

    return (
      <div className="content">
        <div className="inner">
          <div className="main_cont">
            <div className="flex_fix">
              <div className="title">
                <span className="main">도입문의</span>
                <span className="desc">아래 양식을 입력해 제출해주시면 연락처로 담당자가 안내를 드립니다.</span>
              </div>
              <form className="form_area" action="/" method="POST">
                <div className="flex_area">
                  <div className="form-group">
                    <label className="asterisk">이름</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="이름을 입력하세요."
                      id="chargerName"
                      value={chargerName}
                      onChange={this._handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label className="asterisk">회사명</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="회사명을 입력하세요."
                      id="companyName"
                      value={companyName}
                      onChange={this._handleChange}
                    />
                  </div>
                </div>
                <div className="flex_area">
                  <div className="form-group">
                    <label className="asterisk">연락처</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="01012345678"
                      id="chargerContact"
                      value={chargerContact}
                      onChange={this._handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label className="asterisk">이메일</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Sample@email.com"
                      id="chargerEmail"
                      value={chargerEmail}
                      onChange={this._handleChange}
                    />
                  </div>
                </div>
                <div className="flex_area">
                  <div className="form-group">
                    <label className="asterisk">사업장 규모</label>
                    <select className="form-select" id="companySize" onChange={this._handleChange} value={companySize}>
                      <option value="">선택해주세요</option>
                      <option value="대기업(1000명 이상)">대기업(1000명 이상)</option>
                      <option value="중견기업(300 ~ 999명)">중견기업(300 ~ 999명)</option>
                      <option value="중기업(50 ~ 299명)">중기업(50 ~ 299명)</option>
                      <option value="소기업(10 ~ 49명)">소기업(10 ~ 49명)</option>
                      <option value="소호/자영업(1 ~ 9명)">소호/자영업(1 ~ 9명)</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label className="asterisk">사업자 유형</label>
                    <select className="form-select" value={companyType} id="companyType" onChange={this._handleChange}>
                      <option value="">선택해주세요.</option>
                      <option value="법인사업자">법인사업자</option>
                      <option value="개인사업자"> 개인사업자</option>
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="asterisk">문의사항</label>
                  <textarea
                    className="form-control"
                    placeholder="문의할 내용을 입력해 주세요."
                    id="requireContent"
                    value={requireContent}
                    onChange={this._handleChange}
                  ></textarea>
                </div>
                <div className="input-group">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="termsAgree" checked={termsAgree} onChange={this._toggleTermsAgree} />
                    <label htmlFor="termsAgree" className="custom-control-label"></label>
                    <p className="custom_control_text">
                      <a href="" className="privacy_policy" onClick={(e) => this._handleTermsOpen(e, 'P')}>
                        개인정보취급방침
                      </a>
                      에 동의합니다
                    </p>
                  </div>
                </div>
              </form>
              <div className="btn_area">
                <button
                  type="button"
                  className="btn btn_cyan"
                  onClick={this._handleSave}
                  disabled={
                    companyName.trim() === '' ||
                    chargerName.trim() === '' ||
                    chargerContact.trim() === '' ||
                    chargerEmail.trim() === '' ||
                    companySize.trim() === '' ||
                    companyType.trim() === '' ||
                    requireContent.trim() === '' ||
                    !termsAgree
                  }
                >
                  문의하기
                </button>
              </div>
            </div>
          </div>
        </div>
        <TermsModal open={termsOpen} terms={terms} onClose={this._handleTermsClose} />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      sendSupportMail,
      dispatch,
    },
    dispatch
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(SupportMail);
export { connected as SupportMail };
