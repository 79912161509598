/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import logoBlackImg from '_asset/images/logo_black.svg';

export class ServiceLeaveByeModal extends Component {
  constructor(props) {
    super(props);

    this._handleShow = this._handleShow.bind(this);
  }
  _handleShow() {}
  render() {
    return (
      <Modal onShow={this._handleShow} show={this.props.open} backdrop="static" dialogClassName="modal-dialog-centered">
        <Modal.Header>
          <h5 className="modal-title">회원 탈퇴</h5>
          <button type="button" className="btn-close" onClick={this.props.onClose}></button>
        </Modal.Header>
        <Modal.Body>
          <div className="withdrawal">
            <div className="flex_area">
              <img src={logoBlackImg} alt="AIBworks" className="logo" />
              <p>
                <strong>회원 탈퇴가 정상적으로 처리 되었습니다.</strong>
                <span>이용해 주셔서 감사합니다.</span>
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn_area">
            <button type="button" className="btn" onClick={this.props.onClose}>
              확인
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
