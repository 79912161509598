import React, { Component } from 'react';
import { GuideFooterView } from '_containers';
import { UserCountRangeSlider } from '_components';
import { pathConstant } from '_constants';
import { getLocalSession } from '_services';

import payment01Img from '_asset/images/payment01.png';
import payment02Img from '_asset/images/payment02.png';

export class CostGuide extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userCount: 100,
      unitPrice: 1990,
    };

    this._handleJoin = this._handleJoin.bind(this);
    this._handleRangeChange = this._handleRangeChange.bind(this);
    this._handleSupportMail = this._handleSupportMail.bind(this);
  }
  _handleJoin() {
    const session = getLocalSession();
    if (session) {
      if (session.status === '0') {
        this.props.history.push(pathConstant.PATH_AUTH_COMPANYINFOMGMT);
      } else if (session.status === '2') {
        this.props.history.push(pathConstant.PATH_AUTH_SERVICEAPPLICATION);
      } else {
        this.props.history.push(pathConstant.PATH_MYPAGE_MYPAGE);
      }
    } else {
      this.props.history.push(pathConstant.PATH_AUTH_SIGNUP);
    }
  }
  _handleRangeChange(data) {
    this.setState({
      ...this.state,
      userCount: data.from,
    });
  }
  _handleSupportMail() {
    this.props.history.push(pathConstant.PATH_HOME_SUPPORTMAIL);
  }
  render() {
    const { userCount, unitPrice } = this.state;
    document.title = '비용안내 | 아이브웍스';
    return (
      <div className="content cost_page">
        <section className="summary_sect">
          <div className="inner">
            <div className="desc_area">
              <p className="title">14일 무료체험 시작!</p>
              <div className="desc">
                <p>
                  <span>14일간 무료체험으로</span>
                  <span>근태관리 Basic의 모든 기능을 이용할 수 있습니다.</span>
                </p>
                <p>
                  <span>근태관리의 시작은 아이브웍스로! </span>
                  <span>지금 바로 시작하세요!</span>
                </p>
                <p className="use_steps">
                  <i className="num">1</i>
                  <span className="text">회원가입</span>
                  <i className="num">2</i>
                  <span className="text">서비스 신청</span>
                  <i className="num">3</i>
                  <span className="text">무료체험 시작</span>
                </p>
              </div>
              <div className="btn_area">
                <button type="button" className="btn btn_cyan" onClick={this._handleJoin}>
                  무료체험 시작하기
                </button>
                <button type="button" className="btn" onClick={this._handleSupportMail}>
                  도입문의
                </button>
              </div>
              <div className="desc">
                <p>14일 무료체험 종료 후 유료 서비스로 전환됩니다.</p>
              </div>
            </div>
            <div className="visual_area">
              <img src={payment01Img} alt="사무실 이미지" />
            </div>
          </div>
        </section>
        <section className="main_sect">
          <div className="inner">
            <div className="desc_area">
              <p className="service_title">근태관리 Basic</p>
              <div className="service_desc">
                <p>서비스 가격</p>
                <p>
                  <strong>1,990원</strong>
                  <small> / 월별, 1인당</small>
                </p>
              </div>
              <div className="features">
                <div className="feature_title">주요 기능</div>
                <ul className="check_list">
                  <li>모바일 앱 (Android, iOS)</li>
                  <li>웹브라우저 지원</li>
                  <li>알림 서비스</li>
                  <li>근태기준 관리</li>
                  <li>조직 관리</li>
                  <li>관리자 기능</li>
                  <li>승인 관리</li>
                </ul>
              </div>
            </div>
            <div className="visual_area">
              <UserCountRangeSlider userCount={userCount} unitPrice={unitPrice} onChange={this._handleRangeChange} />
              <img src={payment02Img} alt="근로 템플릿 설정 스크린샷" />
            </div>
          </div>
        </section>
        <section className="sub_sect">
          <div className="inner">
            <div className="desc_area">
              <p className="service_title">자동근태관리</p>
              <div className="service_desc">
                <p>서비스 가격</p>
                <p>
                  <strong>별도문의</strong>
                </p>
              </div>
              <div className="features">
                <div className="feature_title">주요 기능</div>
                <ul className="check_list">
                  <li>비콘 태그/게이트웨이를 활용한 자동 출퇴근 관리 서비스</li>
                  <li>비콘 게이트웨이 (근태관리 장소에 설치)</li>
                  <li>비콘태그 (스마트 사원증으로 개별 지급)</li>
                </ul>
              </div>
            </div>
            <div className="desc_area">
              <p className="service_title">연동서비스</p>
              <div className="service_desc">
                <p>서비스 가격</p>
                <p>
                  <strong>별도문의</strong>
                </p>
              </div>
              <div className="features">
                <div className="feature_title">주요 기능</div>
                <ul className="check_list">
                  <li>현관출입문 지문인식기(KT, S1)</li>
                  <li>키오스크 디바이스 연동</li>
                  <li>ERP 또는 그룹웨어 결재 및 인사정보 연동</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <GuideFooterView {...this.props} />
      </div>
    );
  }
}
