import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { loading } from './loading.reducer';
import { alert } from './alert.reducer';
import { subscription } from './mypage/subscription.reducer';
import { companyInfo } from './mypage/company-info.reducer';
import { bulletin } from './customerservice/bulletin.reducer';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    loading,
    alert,
    subscription,
    companyInfo,
    bulletin,
  });

export default createRootReducer;
