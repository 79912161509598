export const CHECK_EMAIL_REQUEST = 'CHECK_EMAIL_REQUEST';
export const CHECK_EMAIL_SUCCESS = 'CHECK_EMAIL_SUCCESS';
export const CHECK_EMAIL_ERROR = 'CHECK_EMAIL_ERROR';
export const PRE_JOIN_REQUEST = 'PRE_JOIN_REQUEST';
export const PRE_JOIN_SUCCESS = 'PRE_JOIN_SUCCESS';
export const PRE_JOIN_ERROR = 'PRE_JOIN_ERROR';

export function checkEmailRequest() {
  return { type: CHECK_EMAIL_REQUEST };
}
export function checkEmailSuccess(data) {
  return { type: CHECK_EMAIL_SUCCESS, data };
}
export function checkEmailError(error) {
  return { type: CHECK_EMAIL_ERROR, error };
}
export function preJoinRequest() {
  return { type: PRE_JOIN_REQUEST };
}
export function preJoinSuccess(data) {
  return { type: PRE_JOIN_SUCCESS, data };
}
export function preJoinError(error) {
  return { type: PRE_JOIN_ERROR, error };
}
