import { requestUrl, requestHeaders, requestBody, handleResponse } from '_helpers';
import {
  fetchSubscriptionRequest,
  fetchSubscriptionSuccess,
  fetchSubscriptionError,
  addSubscriptionRequest,
  addSubscriptionSuccess,
  addSubscriptionError,
  updateSubscriptionUserCountRequest,
  updateSubscriptionUserCountSuccess,
  updateSubscriptionUserCountError,
  fetchSubscriptionLogListRequest,
  fetchSubscriptionLogListSuccess,
  fetchSubscriptionLogListError,
  fetchPaymentLogListRequest,
  fetchPaymentLogListSuccess,
  fetchPaymentLogListError,
  fetchPreSettlementRequest,
  fetchPreSettlementSuccess,
  fetchPreSettlementError,
} from '_actions';

// 서비스 정보 조회
export function fetchSubscription(params = {}, callback = (error, result) => {}) {
  return (dispatch) => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/admin-portal/subscriptions`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchSubscriptionRequest());
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        callback(null, response.data);
        dispatch(fetchSubscriptionSuccess(response.data));
      })
      .catch((error) => {
        callback(error);
        dispatch(fetchSubscriptionError(error));
      });
  };
}

// 서비스 신청
export function addSubscription(params = {}, callback = (error, result) => {}) {
  return (dispatch) => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/admin-portal/subscriptions`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'POST',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(addSubscriptionRequest());
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        callback(null, response.data);
        dispatch(addSubscriptionSuccess(response.data));
      })
      .catch((error) => {
        callback(error);
        dispatch(addSubscriptionError(error));
      });
  };
}

// 서비스 변경 - 사용자수
export function updateSubscriptionUserCount(params = {}, callback = (error, result) => {}) {
  return (dispatch) => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/admin-portal/subscriptions/user-count`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'PUT',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
      body: requestBody(params),
    };
    dispatch(updateSubscriptionUserCountRequest());
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        callback(null, response.data);
        dispatch(updateSubscriptionUserCountSuccess(response.data));
      })
      .catch((error) => {
        callback(error);
        dispatch(updateSubscriptionUserCountError(error));
      });
  };
}

// 서비스 이력 조회
export function fetchSubscriptionLogList(params = {}, callback = (error, result) => {}) {
  return (dispatch) => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/admin-portal/subscriptions/logs`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchSubscriptionLogListRequest());
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        callback(null, response.data);
        dispatch(fetchSubscriptionLogListSuccess(response.data));
      })
      .catch((error) => {
        callback(error);
        dispatch(fetchSubscriptionLogListError(error));
      });
  };
}
// 결제 이력 조회
export function fetchPaymentLogList(params = {}, callback = (error, result) => {}) {
  return (dispatch) => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/admin-portal/subscriptions/payments/logs`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchPaymentLogListRequest());
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        callback(null, response.data);
        dispatch(fetchPaymentLogListSuccess(response.data));
      })
      .catch((error) => {
        callback(error);
        dispatch(fetchPaymentLogListError(error));
      });
  };
}

//  정산 조회
export function fetchPreSettlement(params = {}, callback = (error, result) => {}) {
  return (dispatch) => {
    const url = requestUrl(`${process.env.REACT_APP_APIURL}/admin-portal/subscriptions/pre-settlement`);
    const requestOptions = {
      credentials: 'same-origin',
      method: 'GET',
      headers: requestHeaders({ 'Content-Type': 'application/json' }),
    };
    dispatch(fetchPreSettlementRequest());
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        callback(null, response.data);
        dispatch(fetchPreSettlementSuccess(response.data));
      })
      .catch((error) => {
        callback(error);
        dispatch(fetchPreSettlementError(error));
      });
  };
}
