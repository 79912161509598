/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { commonUtils } from '_helpers';
import { Pagination } from '_components';
import { MyPageLnbView } from '_containers';
import { fetchPaymentLogList, fetchReceiptInfo } from '_services';
import { codeConstant } from '_constants';

class PaymentHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rowCount: 10,
      page: 1,
    };
    this._handleReceipt = this._handleReceipt.bind(this);
    this._handlePageMove = this._handlePageMove.bind(this);
  }
  componentDidMount() {
    this._loadData();
  }
  _loadData() {
    this.props.fetchPaymentLogList();
  }
  _handlePageMove(page) {
    this.setState({
      ...this.state,
      page,
    });
  }
  _handleReceipt(e, item) {
    e.preventDefault();

    const tid = item.result.tid;
    const params = {
      client_id: 'aiblab',
      amount: String(item.amount),
      transaction_date: moment(item.result.transaction_date).format('YYYYMMDD'),
    };
    this.props.fetchReceiptInfo(tid, params, (error, data) => {
      if (!error) {
        window.open(data.receipt_url, '영수증', 'width=400, height=580, menubar=no, status=no, toolbar=no, resizable=yes');
      }
    });
  }
  render() {
    const { paymentLogListData } = this.props;
    const { rowCount, page } = this.state;

    let pageDataList = [];
    if (paymentLogListData) {
      let maxRow = page * rowCount;
      const minRow = maxRow - (rowCount - 1);
      if (maxRow > paymentLogListData.length) {
        maxRow = paymentLogListData.length;
      }
      for (let i = minRow; i <= maxRow; i++) {
        pageDataList.push(paymentLogListData[i - 1]);
      }
    }

    return (
      <div className="content">
        <div className="inner">
          <MyPageLnbView {...this.props} />

          <div className="main_cont">
            <div className="cont_header">
              <h3 className="title">결제 내역</h3>
            </div>
            <div className="cont_body">
              <section>
                <div className="table_area">
                  <div className="table_noti">
                    <p>※ 결제취소는 결제 후 영업일 기준 5일이내 가능합니다.</p>
                    <p>※ 기타 궁금한 사항은 고객센터를 이용해 주세요. 070-8892-7160 | sales@aiblab.co.kr</p>
                  </div>
                  <div className="table payment">
                    <div className="table_row heading">
                      <div className="row_item date">결제일</div>
                      <div className="row_item name">서비스 명</div>
                      <div className="row_item user_num">사용인원</div>
                      <div className="row_item amount">결제 금액 (부가세 포함)</div>
                      <div className="row_item method">결제 수단</div>
                      <div className="row_item etc">비고</div>
                    </div>
                    {!pageDataList.length === 0 && (
                      <div className="table_row">
                        <div className="row_item none">결제 내역이 없습니다.</div>
                      </div>
                    )}
                    {pageDataList.map((item, index) => (
                      <div className="table_row" key={index}>
                        <a href="" className="row_link" onClick={(e) => this._handleReceipt(e, item)}>
                          <div className="row_item date"> {moment(item.createdtime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')}</div>
                          <div className="row_item name">{item.servicename}</div>
                          <div className="row_item user_num">{item.usercount}명</div>
                          <div className="row_item amount">{commonUtils.numberWithCommas(item.amount)}원</div>
                          <div className="row_item method">{codeConstant.CODE_PAYMENT_TYPE[item.paymenttype]}</div>
                          <div className="row_item etc">영수증</div>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
                <Pagination totalCount={paymentLogListData ? paymentLogListData.length : 0} rowCount={rowCount} page={page} onPageMove={this._handlePageMove} />
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { subscription } = state;
  return {
    paymentLogListData: subscription.paymentLogListData,
  };
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchPaymentLogList,
      fetchReceiptInfo,
      dispatch,
    },
    dispatch
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(PaymentHistory);
export { connected as PaymentHistory };
