/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { openAlertDialog } from '_actions';
import { fetchPreSettlement, requestWithdrawal, logout } from '_services';
import { commonUtils } from '_helpers';

class ServiceLeaveCalcModal extends Component {
  constructor(props) {
    super(props);

    this._handleShow = this._handleShow.bind(this);
    this._handleLeave = this._handleLeave.bind(this);
  }
  _handleShow() {
    this.props.fetchPreSettlement();
  }
  _handleLeave() {
    this.props.dispatch(
      openAlertDialog(
        "정말로 회원탈퇴 하시겠습니까?<br/><span class='highlight'>‘회원탈퇴’</span> 버튼을 클릭하시면 <span class='highlight'>정산 및 탈퇴</span>가 완료됩니다.",
        true,
        '회원 탈퇴',
        '취소',
        () => {
          this.props.requestWithdrawal({}, (error, data) => {
            if (!error) {
              logout();
              this.props.onClose(true);
            }
          });
        }
      )
    );
  }
  render() {
    const { data, preSettlementData } = this.props;
    return (
      <Modal onShow={this._handleShow} show={this.props.open} backdrop="static" dialogClassName="modal-dialog-centered">
        <Modal.Header>
          <h5 className="modal-title">회원 탈퇴 - 이용 요금 정산</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              this.props.onClose(false);
            }}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <div className="withdrawal">
            <div className="service_change">
              <p className="tit">카드 정보</p>
              <div className="card_info">
                <p className="name">{data && data.payinfo}</p>
                <p className="num">{data && data.cardinfo}</p>
              </div>
            </div>
            <div className="service_summary">
              <div className="title">정산 내역</div>
              <ul className="dash_list">
                <li>
                  사용기간 : {preSettlementData && moment(preSettlementData.service_startdate, 'YYYY-MM-DD').format('YYYY년 M월 D일')} {' ~ '}
                  {preSettlementData && moment(preSettlementData.service_enddate, 'YYYY-MM-DD').format('YYYY년 M월 D일')}
                </li>
                <li>사용인원 : {preSettlementData && preSettlementData.usercount}명</li>
                <li>
                  결제금액 : {preSettlementData && commonUtils.numberWithCommas(preSettlementData.settlement_amount)}
                  원(부가세 포함)
                </li>
                <li>회원탈퇴일 : {preSettlementData && moment(preSettlementData.service_enddate, 'YYYY-MM-DD').format('YYYY년 M월 D일')}</li>
              </ul>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn_area">
            <button type="button" className="btn" onClick={this._handleLeave}>
              회원 탈퇴 완료
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { subscription } = state;
  return {
    preSettlementData: subscription.preSettlementData,
  };
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchPreSettlement,
      requestWithdrawal,
      dispatch,
    },
    dispatch
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(ServiceLeaveCalcModal);
export { connected as ServiceLeaveCalcModal };
