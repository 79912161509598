export const FETCH_SUBSCRIPTION_REQUEST = 'FETCH_SUBSCRIPTION_REQUEST';
export const FETCH_SUBSCRIPTION_SUCCESS = 'FETCH_SUBSCRIPTION_SUCCESS';
export const FETCH_SUBSCRIPTION_ERROR = 'FETCH_SUBSCRIPTION_ERROR';
export const ADD_SUBSCRIPTION_REQUEST = 'ADD_SUBSCRIPTION_REQUEST';
export const ADD_SUBSCRIPTION_SUCCESS = 'ADD_SUBSCRIPTION_SUCCESS';
export const ADD_SUBSCRIPTION_ERROR = 'ADD_SUBSCRIPTION_ERROR';
export const UPDATE_SUBSCRIPTION_USERCOUNT_REQUEST = 'UPDATE_SUBSCRIPTION_USERCOUNT_REQUEST';
export const UPDATE_SUBSCRIPTION_USERCOUNT_SUCCESS = 'UPDATE_SUBSCRIPTION_USERCOUNT_SUCCESS';
export const UPDATE_SUBSCRIPTION_USERCOUNT_ERROR = 'UPDATE_SUBSCRIPTION_USERCOUNT_ERROR';
export const FETCH_SUBSCRIPTION_LOG_LIST_REQUEST = 'FETCH_SUBSCRIPTION_LOG_LIST_REQUEST';
export const FETCH_SUBSCRIPTION_LOG_LIST_SUCCESS = 'FETCH_SUBSCRIPTION_LOG_LIST_SUCCESS';
export const FETCH_SUBSCRIPTION_LOG_LIST_ERROR = 'FETCH_SUBSCRIPTION_LOG_LIST_ERROR';
export const FETCH_PAYMENT_LOG_LIST_REQUEST = 'FETCH_PAYMENT_LOG_LIST_REQUEST';
export const FETCH_PAYMENT_LOG_LIST_SUCCESS = 'FETCH_PAYMENT_LOG_LIST_SUCCESS';
export const FETCH_PAYMENT_LOG_LIST_ERROR = 'FETCH_PAYMENT_LOG_LIST_ERROR';
export const FETCH_PRESETTLEMENT_REQUEST = 'FETCH_PRESETTLEMENT_REQUEST';
export const FETCH_PRESETTLEMENT_SUCCESS = 'FETCH_PRESETTLEMENT_SUCCESS';
export const FETCH_PRESETTLEMENT_ERROR = 'FETCH_PRESETTLEMENT_ERROR';

export function fetchSubscriptionRequest() {
  return { type: FETCH_SUBSCRIPTION_REQUEST };
}
export function fetchSubscriptionSuccess(data) {
  return { type: FETCH_SUBSCRIPTION_SUCCESS, data };
}
export function fetchSubscriptionError(error) {
  return { type: FETCH_SUBSCRIPTION_ERROR, error };
}
export function addSubscriptionRequest() {
  return { type: ADD_SUBSCRIPTION_REQUEST };
}
export function addSubscriptionSuccess(data) {
  return { type: ADD_SUBSCRIPTION_SUCCESS, data };
}
export function addSubscriptionError(error) {
  return { type: ADD_SUBSCRIPTION_ERROR, error };
}
export function updateSubscriptionUserCountRequest() {
  return { type: UPDATE_SUBSCRIPTION_USERCOUNT_REQUEST };
}
export function updateSubscriptionUserCountSuccess(data) {
  return { type: UPDATE_SUBSCRIPTION_USERCOUNT_SUCCESS, data };
}
export function updateSubscriptionUserCountError(error) {
  return { type: UPDATE_SUBSCRIPTION_USERCOUNT_ERROR, error };
}
export function fetchSubscriptionLogListRequest() {
  return { type: FETCH_SUBSCRIPTION_LOG_LIST_REQUEST };
}
export function fetchSubscriptionLogListSuccess(data) {
  return { type: FETCH_SUBSCRIPTION_LOG_LIST_SUCCESS, data };
}
export function fetchSubscriptionLogListError(error) {
  return { type: FETCH_SUBSCRIPTION_LOG_LIST_ERROR, error };
}
export function fetchPaymentLogListRequest() {
  return { type: FETCH_PAYMENT_LOG_LIST_REQUEST };
}
export function fetchPaymentLogListSuccess(data) {
  return { type: FETCH_PAYMENT_LOG_LIST_SUCCESS, data };
}
export function fetchPaymentLogListError(error) {
  return { type: FETCH_PAYMENT_LOG_LIST_ERROR, error };
}
export function fetchPreSettlementRequest() {
  return { type: FETCH_PRESETTLEMENT_REQUEST };
}
export function fetchPreSettlementSuccess(data) {
  return { type: FETCH_PRESETTLEMENT_SUCCESS, data };
}
export function fetchPreSettlementError(error) {
  return { type: FETCH_PRESETTLEMENT_ERROR, error };
}
