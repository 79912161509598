/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { pathConstant } from '_constants';

import logoGrayImg from '_asset/images/logo_gray.svg';

export class Footer extends Component {
  constructor(props) {
    super(props);

    this._handleTermsView = this._handleTermsView.bind(this);
    this._handleSupportMail = this._handleSupportMail.bind(this);
    this._handleWindowOpen = this._handleWindowOpen.bind(this);
  }
  _handleTermsView(e, terms) {
    e.preventDefault();
    const pathname = terms === 'U' ? pathConstant.PATH_HOME_TERMSOFUSE : pathConstant.PATH_HOME_PRIVACYPOLISH;
    this.props.history.push(pathname);
  }
  _handleSupportMail(e) {
    e.preventDefault();
    this.props.history.push(pathConstant.PATH_HOME_SUPPORTMAIL);
  }
  _handleWindowOpen(e, url) {
    e.preventDefault();
    window.open(url);
  }
  render() {
    return (
      <footer className="footer">
        <div className="inner">
          <div className="site_info">
            <ul className="fnb">
              <li>
                <a href="" onClick={(e) => this._handleTermsView(e, 'U')}>
                  이용약관
                </a>
              </li>
              <li>
                <a href="" onClick={(e) => this._handleTermsView(e, 'P')}>
                  개인정보처리방침
                </a>
              </li>
              <li>
                <a href="" onClick={this._handleSupportMail} target="_blank">
                  도입문의
                </a>
              </li>
              <li>
                <a href="/download/AIBWorks-AM_Guide.zip">사용자 가이드</a>
              </li>
            </ul>
            <div className="media">
              <div className="app">
                <a
                  href=""
                  className="ios"
                  onClick={(e) => this._handleWindowOpen(e, 'https://apps.apple.com/kr/app/%EC%95%84%EC%9D%B4%EB%B8%8C%EC%9B%8D%EC%8A%A4/id1481026591')}
                >
                  <span>APP STORE</span>
                </a>
                <a href="" className="aos" onClick={(e) => this._handleWindowOpen(e, 'https://play.google.com/store/apps/details?id=com.aibworks.id&hl=ko')}>
                  <span>GOOGLE PLAY</span>
                </a>
              </div>
              <div className="sns">
                <a href="" className="blog" onClick={(e) => this._handleWindowOpen(e, 'https://blog.naver.com/aiblab')}></a>
                <a href="" className="facebook" onClick={(e) => this._handleWindowOpen(e, 'https://facebook.com/AIIoTBigdata/')}></a>
                <a href="" className="instagram" onClick={(e) => this._handleWindowOpen(e, 'https://www.instagram.com/aiblabkorea/')}></a>
                <a href="" className="utube" onClick={(e) => this._handleWindowOpen(e, 'https://www.youtube.com/channel/UCOKzgkFZB7EawbMCxYXBK-g')}></a>
              </div>
            </div>
          </div>
          <div className="corp_info">
            <div className="logo">
              <img src={logoGrayImg} alt="아이브웍스 로고" />
            </div>
            <div className="info_list">
              <ul>
                <li>(주) 아이브랩</li>
                <li>대표 서영주 김용우</li>
                <li>13449 경기도 성남시 수정구 창업로 43 판교글로벌비즈센터 A701호</li>
                <li>사업자등록번호 545-81-01078</li>
                <li>통신판매업신고 제 2019-성남수정-0502 호</li>
                <li>sales@aiblab.co.kr</li>
                <li>070-8892-7160</li>
              </ul>
              <p className="copyright">Copyright © AIBLab Inc. All rights reserved.</p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
