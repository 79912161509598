/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { pathConstant } from '_constants';
import {
  checkEmail,
  firebaseSignUp,
  preJoin,
  firebaseSendEmailVerification,
  firebaseSignIn,
  firebaseGetIdToken,
  setLocalSession,
  getLocalSession,
  login,
} from '_services';
import { commonUtils } from '_helpers';
import { openAlertDialog } from '_actions';
import { TermsModal } from '_containers';

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      passwordConfirm: '',
      termsAgree: false,
      termsOpen: false,
      terms: '',
      validFormatEmail: false,
      checkedEmail: false,
      validEmail: false,
      validFormatPassword: false,
      validPasswordConfirm: false,
      sendEmailVerification: false,
      emailVerified: false,
    };

    this._handleChangeEmail = this._handleChangeEmail.bind(this);
    this._handleChangePassword = this._handleChangePassword.bind(this);
    this._handleChangePasswordConfirm = this._handleChangePasswordConfirm.bind(this);
    this._toggleTermsAgree = this._toggleTermsAgree.bind(this);
    this._handleTermsOpen = this._handleTermsOpen.bind(this);
    this._handleTermsClose = this._handleTermsClose.bind(this);
    this._handleCheckEmail = this._handleCheckEmail.bind(this);
    this._handleSignUp = this._handleSignUp.bind(this);
    this._handleSendEmail = this._handleSendEmail.bind(this);
    this._handleLogin = this._handleLogin.bind(this);
    this._handleNext = this._handleNext.bind(this);
  }
  _handleChangeEmail(e) {
    const { value } = e.target;
    if (!commonUtils.isValidEmail(value)) {
      this.setState({
        ...this.state,
        email: value,
        validFormatEmail: false,
        checkedEmail: false,
        validEmail: false,
      });
    } else {
      this.setState({
        ...this.state,
        email: value,
        validFormatEmail: true,
        checkedEmail: false,
        validEmail: false,
      });
    }
  }
  _handleChangePassword(e) {
    const { value } = e.target;
    if (commonUtils.hasWhiteSpace(value) || value.length < 8) {
      this.setState({ ...this.state, password: value, passwordConfirm: '', validForamtPassword: false });
    } else {
      this.setState({ ...this.state, password: value, passwordConfirm: '', validForamtPassword: true });
    }
  }
  _handleChangePasswordConfirm(e) {
    const { value } = e.target;
    const { password } = this.state;

    if (password === value) {
      this.setState({ ...this.state, passwordConfirm: value, validPasswordConfirm: true });
    } else {
      this.setState({ ...this.state, passwordConfirm: value, validPasswordConfirm: false });
    }
  }
  _toggleTermsAgree() {
    this.setState({
      ...this.state,
      termsAgree: !this.state.termsAgree,
    });
  }
  _handleTermsOpen(e, terms) {
    e.preventDefault();

    this.setState({
      ...this.state,
      termsOpen: true,
      terms,
    });
  }
  _handleTermsClose() {
    this.setState({
      ...this.state,
      termsOpen: false,
    });
  }
  _handleCheckEmail() {
    const { email } = this.state;

    this.props.checkEmail({ email }, (error, data) => {
      if (!error) {
        if (data.isused) {
          this.setState({
            ...this.state,
            checkedEmail: true,
            validEmail: false,
          });
        } else {
          this.setState({
            ...this.state,
            checkedEmail: true,
            validEmail: true,
          });
        }
      }
    });
  }
  _handleSignUp() {
    const { email, password } = this.state;

    firebaseSignUp(email, password)
      .then((user) => {
        this._preJoin(email);
      })
      .catch((error) => {
        if (error.code === 'auth/email-already-in-use') {
          this.props.dispatch(openAlertDialog('해당 이메일 주소는 이미 다른 계정에서 사용 중입니다.'));
        } else {
          this.props.dispatch(openAlertDialog('관리자에게 문의하시기 바랍니다.'));
        }
      });
  }
  _preJoin(email) {
    this.props.preJoin({ email }, (error, data) => {
      if (!error) {
        this._sendEmailVerification(email);
      }
    });
  }
  _sendEmailVerification(email) {
    firebaseSendEmailVerification()
      .then((user) => {
        this.setState({
          ...this.state,
          sendEmailVerification: true,
        });
      })
      .catch((error) => {
        this.props.dispatch(openAlertDialog('관리자에게 문의하시기 바랍니다.'));
      });
  }
  _handleSendEmail() {
    firebaseSendEmailVerification()
      .then((user) => {
        this.props.dispatch(openAlertDialog('인증메일이 재발송되었습니다.'));
      })
      .catch((error) => {
        this.props.dispatch(openAlertDialog('관리자에게 문의하시기 바랍니다.'));
      });
  }
  _handleLogin() {
    const { email, password } = this.state;

    firebaseSignIn(email, password)
      .then((user) => {
        if (user.user.emailVerified) {
          this._getIdToken();
        } else {
          this.props.dispatch(openAlertDialog('메일 인증을 다시 시도하세요. 이메일 인증이 완료되지 않았습니다.'));
        }
      })
      .catch((error) => {
        this.props.dispatch(openAlertDialog('관리자에게 문의하시기 바랍니다.'));
      });
  }
  _getIdToken() {
    firebaseGetIdToken()
      .then((idToken) => {
        const session = { idToken };
        setLocalSession(session);

        this._login(idToken);
      })
      .catch((error) => {
        this.props.dispatch(openAlertDialog('관리자에게 문의하시기 바랍니다.'));
      });
  }
  _login(idToken) {
    this.props.login({}, (error, data) => {
      if (!error) {
        let session = getLocalSession();
        session = Object.assign({}, session, {
          ...data,
        });
        setLocalSession(session);

        this.setState({
          ...this.state,
          emailVerified: true,
        });
      } else {
        this.props.dispatch(openAlertDialog('관리자에게 문의하시기 바랍니다.'));
      }
    });
  }
  _handleNext() {
    this.props.history.replace(pathConstant.PATH_AUTH_COMPANYINFOMGMT);
  }
  render() {
    const {
      email,
      password,
      passwordConfirm,
      termsAgree,
      termsOpen,
      terms,
      validFormatEmail,
      checkedEmail,
      validEmail,
      validForamtPassword,
      validPasswordConfirm,
      sendEmailVerification,
      emailVerified,
    } = this.state;

    document.title = '회원가입 | 아이브웍스';

    return (
      <div className="content">
        <div className="inner">
          <div className="main_cont">
            <div className="login_steps non_certified">
              <div className="title">회원가입</div>

              <form className="form_area" action="/" method="POST">
                <div className="form-group">
                  <label htmlFor="" className="asterisk">
                    이메일 주소
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="email"
                      value={email}
                      className="form-control"
                      placeholder="이메일 주소를 입력하세요."
                      onChange={this._handleChangeEmail}
                      disabled={sendEmailVerification}
                    />
                    <span className={'input-group-btn ' + (sendEmailVerification ? 'hide' : '')}>
                      <button type="button" className="btn" onClick={this._handleCheckEmail} disabled={email === '' || !validFormatEmail}>
                        중복확인
                      </button>
                    </span>
                  </div>
                  {!checkedEmail && (email.length === 0 || validFormatEmail) && <p className="message">- 입력한 메일주소로 로그인할 수 있습니다.</p>}
                  {email.length > 0 && !validFormatEmail && <p className="message alert">※ 이메일 주소 형식이 올바르지 않습니다.</p>}
                  {checkedEmail && !validEmail && <p className="message alert">※ 해당 이메일 주소는 이미 다른 계정에서 사용 중입니다.</p>}
                  {!sendEmailVerification && validEmail && <p className="message">- 사용가능한 이메일 주소입니다.</p>}
                </div>
                <div className="form-group">
                  <label htmlFor="" className="asterisk">
                    비밀번호
                  </label>
                  <input
                    type="password"
                    name="password"
                    value={password}
                    className="form-control"
                    placeholder="비밀번호를 입력하세요."
                    onChange={this._handleChangePassword}
                    disabled={sendEmailVerification}
                  />
                  {password.length === 0 && <p className="message">- 비밀번호는 공백없이 8이상 사용 가능합니다.</p>}
                  {password.length > 0 && !validForamtPassword && <p className="message alert">※ 비밀번호 형식이 올바르지 않습니다.</p>}
                </div>
                <div className="form-group">
                  <label htmlFor="" className="asterisk">
                    비밀번호 확인
                  </label>
                  <input
                    type="password"
                    name="passwordConfirm"
                    value={passwordConfirm}
                    className="form-control"
                    placeholder="비밀번호를 다시 입력하세요."
                    onChange={this._handleChangePasswordConfirm}
                    disabled={sendEmailVerification}
                  />
                  {passwordConfirm.length > 0 && !validPasswordConfirm && <p className="message alert">※ 비밀번호가 일치하지 않습니다.</p>}
                </div>
                <div className="input-group">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="termsAgree"
                      checked={termsAgree}
                      onChange={this._toggleTermsAgree}
                      disabled={sendEmailVerification}
                    />
                    <label htmlFor="termsAgree" className="custom-control-label"></label>
                    <p className="custom_control_text">
                      <a href="" className="clause" onClick={(e) => this._handleTermsOpen(e, 'U')}>
                        이용약관
                      </a>
                      과{' '}
                      <a href="" className="privacy_policy" onClick={(e) => this._handleTermsOpen(e, 'P')}>
                        개인정보취급방침
                      </a>
                      에 동의합니다
                    </p>
                  </div>
                </div>
              </form>

              {emailVerified && <div className="message">이메일 인증이 완료되었습니다.</div>}
              {!emailVerified && sendEmailVerification && (
                <div className="btn_area">
                  <div className="btn_group">
                    <button type="button" className="btn" onClick={this._handleSendEmail}>
                      인증메일 재발송
                    </button>
                    <button type="button" className="btn btn_black" onClick={this._handleLogin}>
                      인증 완료
                    </button>
                  </div>
                  <p className="message">- 인증메일에서 링크를 통해 인증을 완료하고, 인증 완료 버튼을 클릭해 주세요.</p>
                </div>
              )}
              <div className="btn_area">
                {!sendEmailVerification && (
                  <button
                    type="button"
                    className="btn btn_wide btn_black"
                    disabled={!termsAgree || !validEmail || !validPasswordConfirm}
                    onClick={this._handleSignUp}
                  >
                    인증메일 발송
                  </button>
                )}
                <button
                  type="button"
                  className={'btn btn_wide btn_y_lg ' + (emailVerified ? 'btn_cyan' : 'btn_cream')}
                  disabled={!emailVerified}
                  onClick={this._handleNext}
                >
                  다음
                </button>
              </div>
            </div>
          </div>
        </div>
        <TermsModal open={termsOpen} terms={terms} onClose={this._handleTermsClose} />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      checkEmail,
      preJoin,
      login,
      dispatch,
    },
    dispatch
  );
const connected = connect(mapStateToProps, mapDispatchToProps)(SignUp);
export { connected as SignUp };
