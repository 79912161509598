/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import moment from 'moment';
import { commonUtils } from '_helpers';

export class UsingServiceView extends Component {
  render() {
    const { data } = this.props;
    return (
      <div className="table_area">
        <table className="table th_row">
          <tbody>
            <tr>
              <th scope="row">서비스 명</th>
              <td>{data && data.servicename}</td>
            </tr>
            <tr>
              <th scope="row">서비스 신청일</th>
              <td>{data && moment(data.createdtime, 'YYYY-MM-DD HH:mm:dd').format('YYYY년 M월 D일 HH:mm')}</td>
            </tr>
            <tr>
              <th scope="row">무료체험 기간</th>
              <td>{data && moment(data.trialenddate).diff(moment(data.joindate), 'days') + 1 + '일, ' + data.joindate + ' ~ ' + data.trialenddate}</td>
            </tr>
            <tr>
              <th scope="row">사용인원</th>
              <td>{data && data.usercount}명</td>
            </tr>
            <tr>
              <th scope="row">월 이용요금</th>
              <td>{data && commonUtils.numberWithCommas(data.usagefee) + '원 (부가세 별도), (1,990원/월 x ' + data.usercount + '명)'}</td>
            </tr>
            <tr>
              <th scope="row">결제방식</th>
              <td>
                {data &&
                  '매월 마지막일 자동결제 (결제 시작일 : ' +
                    moment(data.trialenddate, 'YYYY-MM-DD').add(1, 'days').endOf('month').format('YYYY년 M월 D일') +
                    ')'}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
